/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)

export const FULL_DATE_FORMAT = 'MMMM DD, YYYY'

export const formatDate = (
  date: string | Date,
  format: string = FULL_DATE_FORMAT,
) => dayjs(date).tz('Asia/Manila').format(format)

export const formatCurrency = (fee: number) =>
  fee.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
