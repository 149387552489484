/* eslint-disable react/react-in-jsx-scope */
import TextWithIcon from '../../elements/TextWithIcon'
import FormTextInput from '../elements/FormTextInput'

const UploadResultFormContent = ({ values, formDisabled }) => [
  {
    id: 'upload-result-form-details',
    name: 'Upload Participant Results',
    inputs: [
      {
        id: 'eventName',
        size: 6,
        field: <TextWithIcon title="Event" text={values.eventName} />,
      },
      {
        id: 'categoryName',
        size: 6,
        field: <TextWithIcon title="Category" text={values.categoryName} />,
      },
      {
        id: 'firstName',
        size: 6,
        field: <TextWithIcon title="First Name" text={values.firstName} />,
      },
      {
        id: 'lastName',
        size: 6,
        field: <TextWithIcon title="Last Name" text={values.lastName} />,
      },
      {
        id: 'virtualProgress',
        size: 12,
        field: (
          <FormTextInput
            type="number"
            name="virtualProgress"
            label="Distance covered (in km)"
            required
            autoComplete="new-virtualProgress"
            disabled={formDisabled}
            InputProps={{
              inputProps: { min: 0, step: 0.01, type: 'number' },
            }}
          />
        ),
      },
    ],
  },
]

export default UploadResultFormContent
