import { useTheme } from '@aws-amplify/ui-react'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

const PaginationSpinner: React.FC = () => {
  const { tokens } = useTheme()
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress sx={{ margin: tokens.space.small.value }} />
      </div>
    </div>
  )
}

export default PaginationSpinner
