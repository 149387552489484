/* eslint-disable @typescript-eslint/no-explicit-any */
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { AlertColor } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CardMedia from '@mui/material/CardMedia'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useRollbar } from '@rollbar/react'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { FieldArray, Formik } from 'formik'
import { pick } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Carousel from 'react-material-ui-carousel'

import { FormParticipant } from '../../API'
import { createFormParticipant } from '../../api/mutations'
import { SHIRT_COLOR } from '../../constants/employees'
import {
  FAMILY_TSHIRT_SIZES,
  ONSITE_PAYMENT_MODES,
} from '../../constants/models'
import { CartContext } from '../../contexts/CartContext'
import useFormParticipantDetails from '../../hooks/useFormParticipantDetails'
import { doMutation } from '../../utils/ApiUtils'
import { getName } from '../../utils/ObjectUtil'
import { default as CustomButton } from '../elements/Button'
import Dialog from '../elements/Dialog'
import Page from '../elements/Page'
import Form from '../forms/elements/Form'
import FormButton from '../forms/elements/FormButton'
import FormButtonContainer from '../forms/elements/FormButtonContainer'
import FormCheckbox from '../forms/elements/FormCheckbox'
import FormSelectInput from '../forms/elements/FormSelectInput'
import FormTextInput from '../forms/elements/FormTextInput'
import formParticipantFormSchema from '../forms/schemas/FormParticipants/ParticipantFormSchema'

const SuccessCircleIconContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.success.main,
  marginBottom: theme.spacing(theme['container'].padding),
  '& svg': {
    fontSize: '40px',
  },
  '& h4': {
    color: theme.palette.success.main,
    marginLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
}))
const FailCircleIconContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.error.main,
  marginBottom: theme.spacing(theme['container'].padding),
  '& svg': {
    fontSize: '40px',
  },
  '& h4': {
    color: theme.palette.error.main,
    marginLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
}))

const RecordAlreadyExist = () => (
  <div
    style={{
      textAlign: 'center',
      padding: '19px',
      marginBottom: '20px',
      border: '2px solid red',
      color: 'black',
    }}>
    <Typography variant="body1" textAlign="center">
      You already have an existing record.
      <br />
      <br /> If you click <b>SUBMIT</b> with the selections below, it will{' '}
      <i>UPDATE</i> your previously submitted data.
      <br />
      Click <b>CANCEL</b> to preserve existing data.
    </Typography>
  </div>
)

interface PresignedUrlProps {
  [key: string]: string
}

interface Props {
  show: boolean
  user: any
  event: any
  employee: any
  editing: any
  // onCancel?: React.MouseEventHandler<HTMLButtonElement>
  onCancel?: any
  setLoading?: (value: boolean) => void
  displayError?: (values: any) => void
}

const Participant: React.FC<Props> = ({
  event,
  employee,
  editing,
  show,
  user,
  onCancel,
  setLoading,
}) => {
  const theme = useTheme()
  const rollbar = useRollbar()

  const { cartFPUser } = useContext(CartContext)

  const participantId = useMemo(() => {
    if (!employee) employee = cartFPUser

    return employee && employee.participantId
  }, [employee])

  const { participant: existingParticipant, apiLoading: loading } =
    useFormParticipantDetails({
      eventId: event.id,
      participantId,
    })

  const initParticipant = useMemo(() => {
    return {
      ...user,
      ...employee,
      ...pick(existingParticipant, ['tshirtCount']),
    }
  }, [loading, participantId, existingParticipant])

  const [participant, setParticipant] = useState(initParticipant)

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  useEffect(() => {
    const fetchData = async (
      key: string,
      contentType: string,
      // imageType = 'formEntitlement',
    ) => {
      const url = await Storage.get(key, {
        contentType,
      })
      setEntitlementsPresignedUrl((p) => ({ ...p, [key]: url }))
    }

    if (!!event?.entitlements && event.entitlements.items) {
      event.entitlements.items.forEach((item) => {
        fetchData(item.photo.key, item.photo.contentType)
      })
    }
  }, [event])

  useEffect(() => {
    setParticipant({
      ...user,
      ...employee,
      ...pick(existingParticipant, ['tshirtCount']),
    })
  }, [loading, participantId, existingParticipant])

  const [showSize, setShowSize] = useState(false)
  const [dialogContent, setDialogContent] = useState<string>()
  const [entitlementsPresignedUrl, setEntitlementsPresignedUrl] =
    useState<PresignedUrlProps>()

  const [open, setOpen] = useState(false)
  const [newId, setNewId] = useState('')
  const [dialogStatus, setDialogStatus] = useState<AlertColor>()

  const onClose = () => {
    setNewId('')
    setDialogStatus('success')
    setOpen(false)
    onCancel()
  }

  const renderContent = () => {
    return (
      <>
        <div
          style={{
            textAlign: 'center',
          }}>
          <Button
            color="primary"
            onClick={() => {
              setDialogContent('sizeChart')
              setShowSize(true)
            }}>
            View T-Shirt Size Chart
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setDialogContent('kidsSizeChart')
              setShowSize(true)
            }}>
            View KIDS T-Shirt Size Chart
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setDialogContent('tshirt')
              setShowSize(true)
            }}>
            View T-Shirt
          </Button>
        </div>
        <Formik
          initialValues={initParticipant}
          initialTouched={{
            ...(editing && {
              'tshirtCount.0.quantity': true,
              tshirtCount: [{ quantity: true }],
            }),
          }}
          validateOnMount={editing}
          enableReinitialize
          validationSchema={formParticipantFormSchema}
          onSubmit={async (values, actions) => {
            setLoading(true)
            const fee = values.tshirtCount
              .filter((a) => a.size)
              .reduce((acc, cur) => {
                let fees = event.tshirtFees[`${cur.size}`]
                if (cur.color !== 'WHITE') fees += 5

                return acc + parseInt(fees) * cur.quantity
              }, 0)
            const { firstName, lastName } = getName(participant.name)
            try {
              const input = {
                participantId: participant.participantId,
                firstName,
                lastName,
                // civilStatus: values.civilStatus.trim(),
                status: existingParticipant ? 'UPDATE' : 'NEW',
                paymentMode: values.paymentMode.trim(),
                tshirtCount: JSON.stringify(JSON.stringify(values.tshirtCount)),
                formEventId: event.id,
                fee,
              }
              rollbar.info(input)
              const { data, errors } = (await doMutation(
                'createFormParticipant',
                createFormParticipant,
                {
                  input,
                },
              )) as unknown as { data: FormParticipant; errors: any }
              if (errors) {
                setDialogStatus('error')
              } else {
                actions.resetForm()
                setParticipant(values)
                setNewId(data.id)
                setDialogStatus('success')
              }
              setOpen(true)
            } catch (e) {
              console.log(e)
            }
            setLoading(false)
          }}>
          {(form) => {
            const updateField = (key, value) => {
              form.setTouched({
                ...form.touched,
                [key]: participant[key] !== value,
              })
              form.setFieldValue(key, value)
            }

            const calculateRowTotal = (size, quantity, color) => {
              if (!size || !quantity) return 0

              let fees = event.tshirtFees[size]
              if (color !== 'WHITE') fees += 5
              return parseInt(fees) * quantity
            }

            const getTotal = () => {
              const total = form.values.tshirtCount
                .filter((a) => a.size && a.quantity)
                .reduce((acc, cur) => {
                  let fees = event.tshirtFees[`${cur.size}`]
                  if (cur.color !== 'WHITE') fees += 5

                  return acc + parseInt(fees) * cur.quantity
                }, 0)
              return `PHP ${total.toFixed(2)}`
            }

            const isMaxQuantity = () => {
              const total = form.values.tshirtCount
                .filter((a) => a.size && a.quantity)
                .reduce((acc, cur) => acc + parseInt(cur.quantity), 0)
              return total >= form.values.max
            }

            return (
              <Form>
                <>
                  {form?.values?.workSite !== '' && (
                    <Grid
                      container
                      style={{ width: `calc(100% + ${theme.spacing(6)}px)` }}>
                      <Grid container item>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: theme.spacing(0, 3),
                            position: 'relative',
                            marginTop: theme.spacing(3),
                          }}></Grid>
                        <Grid container item xs={12}>
                          <FieldArray name="tshirtCount">
                            {({ remove, push }) => (
                              <Grid container item key={`tshirtCount-section`}>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: 'center' }}>
                                  {existingParticipant && (
                                    <RecordAlreadyExist />
                                  )}
                                  <Typography
                                    variant="subtitle2"
                                    textAlign="center">
                                    (Max of {participant.max} tshirt quantity)
                                  </Typography>
                                  {!isMaxQuantity() && (
                                    <CustomButton
                                      secondary
                                      contained
                                      sx={{
                                        margin: 'auto',
                                      }}
                                      text
                                      onClick={() =>
                                        push({ size: '', count: '' })
                                      }>
                                      +Add T-Shirt
                                    </CustomButton>
                                  )}
                                </Grid>

                                {form.values.tshirtCount.length > 0 &&
                                  form.values.tshirtCount.map(
                                    (tshirtCnt, index) => (
                                      <Grid
                                        container
                                        item
                                        key={`tshirtCount-size-row-${index}`}>
                                        <Grid
                                          item
                                          key={`tshirtCount-size-${index}`}
                                          xs={12}
                                          sm={3}
                                          sx={[
                                            {
                                              padding: theme.spacing(1, 3),
                                            },
                                          ]}>
                                          <FormSelectInput
                                            name={`tshirtCount.${index}.size`}
                                            label="T-Shirt Size"
                                            required
                                            autoComplete={`new-tshirtCount.${index}.size`}
                                            disabled={form.isSubmitting}
                                            value={tshirtCnt.size}
                                            onModified={(e) => {
                                              updateField(
                                                `tshirtCount.${index}.size`,
                                                e.target.value,
                                              )
                                            }}
                                            options={Object.entries(
                                              FAMILY_TSHIRT_SIZES,
                                            ).map(([key, value]) => ({
                                              value: key,
                                              text: value,
                                            }))}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={3}
                                          key={`tshirtCount-quantity-${index}`}
                                          sx={[
                                            {
                                              padding: theme.spacing(1, 3),
                                            },
                                          ]}>
                                          <FormTextInput
                                            name={`tshirtCount.${index}.quantity`}
                                            label="Quantity"
                                            required
                                            type="number"
                                            value={parseInt(tshirtCnt.quantity)}
                                            onModified={(e) => {
                                              updateField(
                                                `tshirtCount.${index}.quantity`,
                                                e.target.value,
                                              )
                                            }}
                                            autoComplete={`new-tshirtCount.${index}.quantity`}
                                            disabled={form.isSubmitting}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={3}
                                          key={`tshirtCount-color-${index}`}
                                          sx={[
                                            {
                                              padding: theme.spacing(1, 3),
                                            },
                                          ]}>
                                          <FormSelectInput
                                            name={`tshirtCount.${index}.color`}
                                            label="T-Shirt Color"
                                            required
                                            value={tshirtCnt.color}
                                            onModified={(e) => {
                                              updateField(
                                                `tshirtCount.${index}.color`,
                                                e.target.value,
                                              )
                                            }}
                                            autoComplete={`new-tshirtCount.${index}.size`}
                                            disabled={form.isSubmitting}
                                            options={SHIRT_COLOR.map(
                                              (value) => ({
                                                value,
                                                text: value,
                                              }),
                                            )}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={9}
                                          sm={2}
                                          key={`tshirtCount-total-${index}`}
                                          sx={[
                                            {
                                              padding: theme.spacing(3),
                                            },
                                          ]}>
                                          <Typography>
                                            {form.values.tshirtCount[index]
                                              .color &&
                                              form.values.tshirtCount[index]
                                                .size &&
                                              `PHP ${calculateRowTotal(
                                                form.values.tshirtCount[index]
                                                  .size,
                                                form.values.tshirtCount[index]
                                                  .quantity,
                                                form.values.tshirtCount[index]
                                                  .color,
                                              ).toFixed(2)}`}
                                          </Typography>{' '}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={3}
                                          sm={1}
                                          key={`tshirtCount-remove-${index}`}
                                          sx={[
                                            {
                                              padding: theme.spacing(1, 3),
                                            },
                                          ]}>
                                          <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => remove(index)}>
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    ),
                                  )}
                              </Grid>
                            )}
                          </FieldArray>
                          <Grid
                            container
                            style={{
                              width: `calc(100% + ${theme.spacing(6)}px)`,
                            }}>
                            <Grid
                              item
                              xs={12}
                              sx={(theme) => ({
                                padding: theme.spacing(2, 3),
                                [theme.breakpoints.up('sm')]: {
                                  padding: theme.spacing(2, 10),
                                },
                                position: 'relative',
                              })}>
                              <Typography
                                variant="h5"
                                style={{
                                  width: '100%',
                                  display: 'inline-block',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  textAlign: 'end',
                                }}>
                                TOTAL: {getTotal()}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            style={{
                              width: `calc(100% + ${theme.spacing(6)}px)`,
                            }}>
                            <Grid
                              item
                              xs={12}
                              style={{
                                padding: theme.spacing(0, 3),
                                position: 'relative',
                              }}>
                              <Typography
                                variant="overline"
                                style={{
                                  width: '100%',
                                  display: 'inline-block',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}>
                                Payment Mode
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              style={{
                                padding: theme.spacing(0, 3),
                                position: 'relative',
                              }}>
                              <RadioGroup
                                row
                                name="paymentMode"
                                value={form.values.paymentMode}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                  value: string,
                                ) => {
                                  updateField('paymentMode', value)
                                }}>
                                {Object.keys(ONSITE_PAYMENT_MODES).map(
                                  (mode: string) => {
                                    return (
                                      <FormControlLabel
                                        key={`paymentMode-${mode}`}
                                        value={mode}
                                        control={<Radio />}
                                        label={
                                          <div style={{ display: 'flex' }}>
                                            <Typography
                                              sx={{
                                                lineHeight: '40px',
                                                marginLeft: '10px',
                                              }}>
                                              {ONSITE_PAYMENT_MODES[mode]}
                                            </Typography>
                                          </div>
                                        }
                                      />
                                    )
                                  },
                                )}
                              </RadioGroup>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              position: 'relative',
                              padding: '0 12px',
                            }}>
                            {form.values.paymentMode ===
                            ONSITE_PAYMENT_MODES.ATD ? (
                              <FormCheckbox
                                name="atdCb"
                                onModified={(e, checked) => {
                                  updateField('atdCb', checked)
                                }}
                                label={
                                  <Box style={{ marginTop: '10px' }}>
                                    <Typography variant="subtitle2">
                                      Authority to deduct form for payment
                                      through salary deduction:
                                    </Typography>
                                    <Typography variant="body2">
                                      I, the undersigned employee hereby
                                      authorize the employer paymaster to make
                                      deductions from my salary as payment for
                                      the t-shirt that I will use for PTY Family
                                      Day{' '}
                                      {dayjs().tz('Asia/Manila').format('YYYY')}
                                      . Total amount of payment shall be
                                      deductible in 4 quincenas starting from
                                      May 15,{' '}
                                      {dayjs().tz('Asia/Manila').format('YYYY')}
                                      .
                                    </Typography>
                                  </Box>
                                }
                              />
                            ) : (
                              <Box
                                style={{
                                  marginTop: '10px',
                                  marginLeft: '14px',
                                }}>
                                <Typography variant="subtitle2">
                                  Prepare payment on shirt distribution
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
                <FormButtonContainer
                  wrap={false}
                  style={{ margin: theme.spacing(2, 0) }}>
                  <FormButton
                    secondary
                    outlined
                    onClick={onCancel}
                    disabled={form.isSubmitting}>
                    Cancel
                  </FormButton>
                  <FormButton
                    submit
                    primary
                    outlined
                    disabled={
                      !form.isValid ||
                      form.isSubmitting ||
                      (form.values.paymentMode === ONSITE_PAYMENT_MODES.ATD &&
                        !form.values.atdCb)
                    }>
                    {'Submit'}
                  </FormButton>
                </FormButtonContainer>
              </Form>
            )
          }}
        </Formik>
        <Dialog
          open={showSize}
          maxWidth={'lg'}
          onClose={() => {
            setShowSize(false)
            setDialogContent('')
          }}
          title={`${
            ['kidsSizeChart', 'sizeChart'].includes(dialogContent)
              ? 'Size Chart'
              : 'T-Shirt'
          }`}
          content={
            <Grid
              item
              lg={12}
              sx={(theme) => ({
                height: '100vh',
                overflow: 'hidden',
                [theme.breakpoints.up('md')]: {
                  width: 'calc(100vw/2)',
                  height: 'calc(100vh/2)',
                },
              })}>
              <Box
                sx={(theme) => ({
                  height: '100%',
                  overflow: 'scroll',
                  [theme.breakpoints.up('md')]: {
                    overflow: 'unset',
                  },
                })}>
                {['kidsSizeChart', 'sizeChart'].includes(dialogContent) && (
                  <CardMedia
                    sx={(theme) => ({
                      width: '1000px',
                      height: '650px',
                      backgroundSize: 'cover',
                      [theme.breakpoints.up('md')]: {
                        width: '100%',
                        height: '100%',
                        backgroundSize: 'contain',
                      },
                    })}
                    image={`${process.env.PUBLIC_URL}/images/${
                      dialogContent === 'kidsSizeChart'
                        ? 'kids_size_chart_v2.jpeg'
                        : 'size_chart_v2.jpg'
                    }`}
                  />
                )}
                {dialogContent === 'tshirt' && (
                  <Carousel
                    autoPlay={false}
                    swipe={false}
                    sx={{
                      height: '100%',
                      width: '1000px',
                      overflow: 'scroll',
                      [theme.breakpoints.up('md')]: {
                        // height: '650px',
                        width: 'calc(100vw/2)',
                      },
                    }}>
                    {entitlementsPresignedUrl &&
                      Object.values(entitlementsPresignedUrl).map((item, i) => (
                        <CardMedia
                          key={`formEntitlement-${i}`}
                          sx={(theme) => ({
                            width: '1000px',
                            height: '650px',
                            backgroundSize: 'cover',
                            [theme.breakpoints.up('md')]: {
                              // width: '100%',
                              // height: '100%',
                              backgroundSize: 'contain',
                            },
                          })}
                          image={
                            item
                              ? `${item}`
                              : `${process.env.PUBLIC_URL}/images/megatechph_icon.png`
                          }
                        />
                      ))}
                  </Carousel>
                )}
              </Box>
            </Grid>
          }
          buttons={
            <>
              <Button
                variant="text"
                onClick={() => {
                  setShowSize(false)
                  setDialogContent('')
                }}>
                Close
              </Button>
            </>
          }
        />
      </>
    )
  }

  return (
    <Container
      sx={{
        marginBottom: theme.spacing(10),
      }}>
      {!loading && employee && show && renderContent()}

      <Dialog
        open={open}
        maxWidth={'lg'}
        onClose={onClose}
        content={
          <Page centered>
            {dialogStatus === 'success' ? (
              <>
                <SuccessCircleIconContainer>
                  <CheckCircleIcon />
                  <Typography variant="h4">Success!</Typography>
                </SuccessCircleIconContainer>
                <Typography variant="body1">
                  Thank you for registering. Your Transaction ID is {newId}.
                </Typography>

                <Typography variant="caption">
                  <strong>NOTE: </strong>Save this transaction id for your
                  reference.{' '}
                </Typography>
              </>
            ) : (
              <>
                <FailCircleIconContainer>
                  <ErrorIcon />
                  <Typography variant="h4">Failed..</Typography>
                </FailCircleIconContainer>
                <Typography variant="body1">
                  We are unable to capture your data. Please ensure that all
                  required information is provided.
                </Typography>

                <Typography variant="caption">
                  <strong>NOTE: </strong>
                  You may want to contact admin@megatechph.com to be safe on
                  your side.
                </Typography>
              </>
            )}
          </Page>
        }
        buttons={
          <Button variant="text" onClick={onClose}>
            Close
          </Button>
        }
      />
    </Container>
  )
}
export default Participant
