import { Authenticator } from '@aws-amplify/ui-react'
import { ThemeProvider } from '@mui/material/styles'
import { Auth, Amplify } from 'aws-amplify'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import './index.css'
import App from './App'
import awsconfig from './aws-exports'
import DownloadParticipants from './components/DownloadParticipants'
import FormEventRegistration from './components/FormEventRegistration/index'
import ParticipantMoreInfo from './components/ParticipantMoreInfo'
import PaymongoResult from './components/PaymongoResult'
import SubmitJersey from './components/SubmitJersey'
import UploadResults from './components/UploadResults'
import theme from './constants/theme'
import AboutUs from './pages/AboutUs'
import EventDetails from './pages/EventDetails'
import Events from './pages/Events'
import Home from './pages/Home'
import Login from './pages/Login'
import OnsiteRegistration from './pages/OnsiteRegistration'
import PastEvents from './pages/PastEvents'
import PrivateRoute from './pages/PrivateRoute'
import ShoppingCartStepper from './pages/ShoppingCartStepper'
import Waiver from './pages/Waiver'
import reportWebVitals from './reportWebVitals'
// import FAQ from './pages/FAQ'
// import Maintenance from './components/Maintenance'

Auth.configure(awsconfig)
Amplify.configure(awsconfig)

// const isMaintenance = false

const router = createBrowserRouter([
  {
    path: '/',
    // element: !isMaintenance ? <App /> : <Maintenance />,
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      // {
      //   path: 'faq',
      //   element: <FAQ />,
      // },
      {
        path: 'aboutus',
        element: <AboutUs />,
      },
      {
        path: 'waiver',
        element: <Waiver />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'events',
        element: <Events />,
      },
      {
        path: 'pastEvents',
        element: <PastEvents />,
      },
      {
        path: 'events/:slug',
        element: <EventDetails />,
      },
      {
        path: 'formEvents/:slug',
        element: <FormEventRegistration />,
      },
      {
        path: 'jersey/:participantId',
        element: <SubmitJersey />,
      },
      {
        path: 'paymentResults',
        element: <PaymongoResult />,
      },
      {
        path: 'moreInfo/:participantId',
        element: <ParticipantMoreInfo />,
      },
      {
        path: 'register/:slug',
        element: <ShoppingCartStepper />,
      },
      {
        path: 'register/:slug/:categoryId',
        element: <ShoppingCartStepper />,
      },
      {
        path: 'uploadResults/:participantId',
        element: <UploadResults />,
      },
      {
        path: 'admin',
        element: <PrivateRoute />,
        children: [
          {
            path: 'downloadParticipants',
            element: <DownloadParticipants />,
          },
        ],
      },
      {
        path: 'admin',
        element: <PrivateRoute />,
        children: [
          {
            path: 'onsiteRegistration',
            element: <OnsiteRegistration />,
          },
          {
            path: 'onsiteRegistration/:slug',
            element: <OnsiteRegistration />,
          },
        ],
      },
    ],
  },
])

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Authenticator.Provider>
        <RouterProvider router={router} />
      </Authenticator.Provider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
