/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'

import { SHIRT_COLOR, WORK_SITE } from '../../../../constants/employees'
import {
  FAMILY_TSHIRT_SIZES,
  ONSITE_PAYMENT_MODES,
} from '../../../../constants/models'

const FormParticipantFormSchema = Yup.object().shape({
  participantId: Yup.string().required('ID # is required'),
  workSite: Yup.mixed().required('Work Site is required').oneOf(WORK_SITE),
  firstName: Yup.string(),
  lastName: Yup.string(),
  max: Yup.number(),
  tshirtCount: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.mixed()
          .required('T-Shirt Size is required')
          .oneOf(Object.keys(FAMILY_TSHIRT_SIZES)),
        quantity: Yup.number()
          .required('Quantity is required')
          .min(1, 'Minimum of 1'),
        color: Yup.mixed()
          .required('Shirt Color is required')
          .oneOf(SHIRT_COLOR),
      }),
    )
    .test({
      name: 'validate-total',
      test: (value: any, testContext: any) => {
        // if (testContext.parent.civilStatus === 'SINGLE') {
        const base = testContext.parent.tshirtCount
          ? testContext.parent.tshirtCount
          : value

        const sum = base
          .filter((a) => a.size && a.quantity)
          .reduce(
            (accumulator: number, currentValue: number) =>
              accumulator + parseFloat(currentValue['quantity']),
            0,
          )

        // const index = testContext.parent.tshirtCount.indexOf(value)
        // console.log({ t: testContext, p: testContext.path, sum, index, value })
        if (sum > testContext.parent.max)
          return testContext.createError({
            message: `Total quantity must not exceed ${testContext.parent.max}`,
            path: `${testContext.path}.0.quantity`,
          })

        // if (testContext.parent.civilStatus === 'MARRIED') {
        //   const sum = value.reduce(
        //     (accumulator: number, currentValue: number) =>
        //       accumulator + parseFloat(currentValue['quantity']),
        //     0,
        //   )
        //   if (sum > 10)
        //     return testContext.createError({
        //       message: `Total quantity must not exceed 10`,
        //       path: `${testContext.path}.0.quantity`,
        //     })
        // }
        return true
      },
    }),
  paymentMode: Yup.mixed()
    .required('Payment mode is required')
    .oneOf(Object.keys(ONSITE_PAYMENT_MODES)),
  atdCb: Yup.boolean(),
})
export default FormParticipantFormSchema
