import { Auth } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const PrivateRoute: React.FC = () => {
  const navigate = useNavigate()

  const [user, setUser] = useState()

  useEffect(() => {
    let didCancel = false
    if (didCancel) return

    const getData = async () => {
      try {
        const { attributes } = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
        setUser(attributes)
      } catch (e) {
        console.log(e)
        navigate('/login', { replace: true })
      }
    }

    getData()
    return () => {
      didCancel = true
    }
  }, [navigate, setUser])

  return <>{user && <Outlet />}</>
}
export default PrivateRoute
