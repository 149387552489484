/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { uniqBy } from 'lodash'
import { useState, useEffect, useCallback } from 'react'

import { ErrorProps } from './common'
import useNextToken from './useNextToken'
import { Event as EventApi } from '../API'
import { listEvents, eventsByIsComingSoonAndEndDateTime } from '../api/queries'
import { DEFAULT_ENTRIES_PER_PAGE } from '../constants/api'
import { fetchQuery } from '../utils/ApiUtils'

dayjs.extend(utc)
dayjs.extend(timezone)

type Event = Omit<Exclude<EventApi, null>, '__typename'>

const useEvents = (current = true) => {
  const [events, setEvents] = useState<Event[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorProps | null>()
  const [queryInfo, setQueryInfo] = useState({ options: {}, name: '' })

  const {
    nextToken,
    setNewToken,
    startUsingToken,
    onTokenSuccess,
    onTokenFail,
    tokensLoading,
  } = useNextToken()

  useEffect(() => {
    const queryItems = current
      ? {
          filter: {
            endDateTime: {
              gt: dayjs().tz('Asia/Manila'),
            },
          },
        }
      : {
          isComingSoon: 0,
          endDateTime: {
            lt: dayjs().tz('Asia/Manila'),
          },
          sortDirection: 'DESC',
        }
    const queryName = current
      ? 'listEvents'
      : 'eventsByIsComingSoonAndEndDateTime'

    setQueryInfo({ name: queryName, options: queryItems })
  }, [current])

  useEffect(() => {
    let didCancel = false

    let allItems = []
    let currentNextToken = null
    const fetchData = async () => {
      if (didCancel) return
      setLoading(true)

      if (currentNextToken) startUsingToken(currentNextToken)

      try {
        const { items, nextToken: newNextToken } = (await fetchQuery(
          queryInfo.name,
          current ? listEvents : eventsByIsComingSoonAndEndDateTime,
          {
            limit: DEFAULT_ENTRIES_PER_PAGE,
            ...(currentNextToken && { nextToken: currentNextToken }),
            ...queryInfo.options,
          },
        )) as unknown as { items: Event[]; nextToken: any }

        allItems = uniqBy([...allItems, ...items], 'id')
        if (currentNextToken) onTokenSuccess(currentNextToken)

        if (newNextToken) {
          currentNextToken = newNextToken
          setNewToken(newNextToken)
          if (allItems.length < DEFAULT_ENTRIES_PER_PAGE) {
            fetchData()
            return
          }
        }

        setEvents(allItems)
        allItems = []
        currentNextToken = null
        // setEvents((m) => _.uniqBy([...m, ...items], 'i'))
        // if (nextToken) setNewToken(nextToken)
      } catch (e) {
        console.log(e)
        setError({
          title: 'Fail to load events',
          message: '',
        })
      }
      setLoading(false)
    }

    if (Object.keys(queryInfo.options).length > 0) fetchData()

    return () => {
      didCancel = true
    }
  }, [setNewToken, current, queryInfo])

  const loadMore = useCallback(async () => {
    if (nextToken && Object.keys(queryInfo.options).length > 0) {
      startUsingToken(nextToken)

      try {
        const { items, nextToken: newNextToken } = (await fetchQuery(
          queryInfo.name,
          current ? listEvents : eventsByIsComingSoonAndEndDateTime,
          {
            limit: DEFAULT_ENTRIES_PER_PAGE,
            nextToken,
            ...queryInfo.options,
          },
        )) as unknown as { items: Event[]; nextToken: any }

        onTokenSuccess(nextToken)

        setEvents((m) => uniqBy([...m, ...items], 'id'))
        setError(null)
        if (newNextToken) setNewToken(newNextToken)
      } catch (e) {
        console.log(e)
        onTokenFail(nextToken)
        setError({
          title: 'Fail to load events',
          message: '',
        })
      }
    }
  }, [
    nextToken,
    // onError,
    onTokenFail,
    onTokenSuccess,
    setNewToken,
    startUsingToken,
    current,
    queryInfo,
  ])

  return {
    events,
    apiLoading: loading,
    apiError: error,
    apiLoadMore: loadMore,
    apiLoadingMore: tokensLoading,
  }
}

export default useEvents
