import DevicesIcon from '@mui/icons-material/Devices'
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import HikingIcon from '@mui/icons-material/Hiking'
import MemoryIcon from '@mui/icons-material/Memory'
import PoolIcon from '@mui/icons-material/Pool'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import StorefrontIcon from '@mui/icons-material/Storefront'
import TimerIcon from '@mui/icons-material/Timer'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import ImageCarousel from '../components/elements/ImageCarousel'
import Entitlement from '../components/Entitlement'
import Section from '../components/Section'
import SectionHeader from '../components/SectionHeader'

const HeaderContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '2rem',
  textAlign: 'center',
  paddingLeft: '0',
  [theme.breakpoints.up('sm')]: {
    top: '10rem',
    textAlign: 'left',
    paddingLeft: '4rem',
  },
  [theme.breakpoints.up('md')]: {
    top: '14rem',
    paddingLeft: '8rem',
  },
  width: '100vw',
  zIndex: 1,
}))

const HeaderText = styled(Typography)(() => ({
  color: `white`,
  textShadow: '0px 0px 10px black',
  fontWeight: 'bold',
}))

const IMAGE_URL = `${process.env.PUBLIC_URL}/images/home/`
const URLS = [
  {
    url: `${IMAGE_URL}341520156_626443626004474_4805024835993218326_n.jpeg`,
    caption: 'TWM captured by MOTUS Photo',
  },
  {
    url: `${IMAGE_URL}341871084_1416298512492176_1613556646615419195_n.jpeg`,
    caption: 'TWM captured by MOTUS Photo',
  },
  {
    url: `${IMAGE_URL}352777755_739357891523579_1878726525854622375_n.jpeg`,
    caption: 'AMUM captured by MOTUS Photo',
  },
  {
    url: `${IMAGE_URL}352819658_739346341524734_6857495945399760982_n.jpeg`,
    caption: 'AMUM captured by MOTUS Photo',
  },
  {
    url: `${IMAGE_URL}399695958_845694660889901_7883332465615742619_n.jpeg`,
    caption: 'TWM captured by MOTUS Photo',
  },
  {
    url: `${IMAGE_URL}399707502_845691134223587_8513049735014568372_n.jpeg`,
    caption: 'Captured by MOTUS Photo',
  },
  {
    url: `${IMAGE_URL}405958528_852646580194709_7939372562040742322_n.jpeg`,
    caption: 'Megawear Half Marathon captured by MOTUS Photo',
  },
  {
    url: `${IMAGE_URL}405998627_852652296860804_1264981331193630210_n.jpeg`,
    caption: 'Captured by MOTUS Photo',
  },
]

interface EventTypeItemProps {
  title: string
  Icon?: React.ElementType
  children?: JSX.Element
  text?: string
}
const EventTypeItem: React.FC<EventTypeItemProps> = ({
  title,
  Icon,
  children,
  text,
}) => (
  <Grid item sm={4} xs={12}>
    <Grid
      container
      sx={{ mb: 2 }}
      direction="row"
      justifyContent="center"
      alignItems="center">
      {Icon && <Icon />}
      {children}
      <Typography
        variant="overline"
        sx={{ ml: 2, fontSize: '1.4rem' }}
        color={(theme) => theme.palette.error.main}>
        {title}
      </Typography>
    </Grid>
    <Typography textAlign="justify">{text}</Typography>
  </Grid>
)

const Home: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          width: '100vw',
        }}>
        <div style={{ position: 'relative' }}>
          <ImageCarousel
            items={URLS}
            backgroundSize="cover"
            indicators={false}
          />
          <HeaderContainer>
            <HeaderText variant="h4" sx={{ mb: 1 }}>
              Empowering your moments
              <br />
              Mastering your time
            </HeaderText>
            <HeaderText variant="body1" color="primary" sx={{ mb: 1 }}>
              Join us in embracing the future of sports timing.
            </HeaderText>

            <Link href="/events" sx={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ color: 'white' }}>
                View Events
              </Button>
            </Link>
          </HeaderContainer>
        </div>
      </Box>
      <Section secondary noMargin>
        <SectionHeader
          title="Services"
          description={`
            MEGATechPH supports athletes, racers and events in creating their ultimate sports experience.
          `}
        />
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start">
          <Entitlement
            Icon={StorefrontIcon}
            name="One stop shop race management"
          />
          <Entitlement Icon={DevicesIcon} name="Online registration" />
          <Entitlement Icon={TimerIcon} name="Timing system" />
          <Entitlement Icon={MemoryIcon} name="Race bib and chip" />
        </Grid>
      </Section>
      <Section>
        <SectionHeader
          title="Event types"
          description={`
            With a dedication to precision, innovation, and reliability, we are
            the go-to choice for organizers, athletes, and sports enthusiasts
            alike.
          `}
        />
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center">
          <EventTypeItem Icon={DirectionsRunIcon} title="Run" />
          <EventTypeItem Icon={DirectionsBikeIcon} title="Bike" />
          <EventTypeItem Icon={PoolIcon} title="Swim" />
          <EventTypeItem title="Triathlon">
            <>
              <PoolIcon />
              <DirectionsBikeIcon />
              <DirectionsRunIcon />
            </>
          </EventTypeItem>
          <EventTypeItem title="Duathlon">
            <>
              <DirectionsBikeIcon />
              <DirectionsRunIcon />
            </>
          </EventTypeItem>
          <EventTypeItem title="Aquathlon">
            <>
              <PoolIcon />
              <DirectionsRunIcon />
            </>
          </EventTypeItem>
          <EventTypeItem Icon={HikingIcon} title="Trail Run" />
          <EventTypeItem
            Icon={SettingsAccessibilityIcon}
            title="...And many more"
          />
        </Grid>
        <Link
          href="/events"
          sx={{ textDecoration: 'none', textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ color: 'white' }}>
            View Events
          </Button>
        </Link>
      </Section>
    </>
  )
}

export default Home
