/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useRef, useLayoutEffect } from 'react'

const isBrowser = typeof window !== `undefined`

function getScrollPosition({
  element,
  useWindow,
}: {
  element: any
  useWindow: any
}) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

/*
 * This hook providesa  scroll position utility.
 *
 * @param {function}  effect    The function to invoke when a scroll event is triggered.
 *                              The hook passes { prevPos, currPos } as arguments to this function.
 * @param {array}     deps      Any additional dependencies for which useLayoutEffect should run.
 * @param {node}      element   The DOM node that the scroll events should tracked for. If false, uses the whole page.
 * @param {boolean}   useWindow Whether to use window.scroll or getBoundingClientRect to get element position
 * @param {number}    wait      A wait time used for throttling the effect
 *
 * Use the hook like this (examples):
 * useScrollPosition(({ prevPos, currPos })= { Your code here });
 * useScrollPosition(({ prevPos, currPos })= { Your code here }, [element], element);
 * useScrollPosition(({ prevPos, currPos })= { Your code here }, [element], element, true, 500);
 */

const useScrollPosition = (
  effect: any,
  deps: any[] = [],
  element = false,
  useWindow = false,
  wait = null,
) => {
  const position = useRef(getScrollPosition({ element, useWindow }))

  let throttleTimeout: any = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    if (!isBrowser) {
      return () => {}
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}

export default useScrollPosition
