import { Form as FormikForm } from 'formik'
import React from 'react'

interface Props {
  id?: string
  // children: JSX.Element
  children: React.ReactNode
}

const Form: React.FC<Props> = ({ children, ...rest }) => (
  <FormikForm {...rest}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  </FormikForm>
)

export default Form
