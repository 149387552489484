/* eslint-disable import/order */
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Dialog from './elements/Dialog'
import Page from './elements/Page'
import EmptyState from './EmptyState'
import jerseyFormContent from './forms/contents/JerseyFormContent'
import Form from './forms/elements/Form'
import FormGrid from './forms/elements/FormGrid'
import jerseySchema from './forms/schemas/JerseySchema'
import FormButtonContainer from './forms/elements/FormButtonContainer'
import FormButton from './forms/elements/FormButton'
import { Participant as ParticipantApi } from '../API'
import { doMutation } from '../utils/ApiUtils'
import { updateParticipant } from '../api/mutations'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import useParticipantDetails from '../hooks/useParticipantDetails'

type Participant = Omit<Exclude<ParticipantApi, null>, '__typename'>

const CheckCircleIconContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.success.main,
  marginBottom: theme.spacing(theme['container'].padding),
  '& svg': {
    fontSize: '40px',
  },
  '& h4': {
    color: theme.palette.success.main,
    marginLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
}))

const SubmitJersey = () => {
  const [hasError, setHasError] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)
  const [participant, setParticipant] = useState<Participant>()

  const [showSize, setShowSize] = useState(false)
  const [showBikeSize, setShowBikeSize] = useState(false)
  const [showSizeImage, setShowSizeImage] = useState('')

  const { participantId } = useParams()
  const {
    participant: initParticipant,
    apiLoading: loading,
    apiError,
  } = useParticipantDetails({
    id: participantId,
  })

  useEffect(() => {
    setPageLoading(loading)
  }, [loading, setPageLoading])

  useEffect(() => {
    if (
      initParticipant &&
      initParticipant.bibNumber !== null &&
      initParticipant.bibNumber <= initParticipant.category.maxJerseys
    ) {
      if (initParticipant.jerseySize !== null) {
        setSubmitted(true)
        setHasError('Participant has already submitted their jersey size.')
      } else setParticipant(initParticipant)
    }
  }, [initParticipant, setParticipant])

  const renderContent = () => {
    if (apiError)
      return (
        <div style={{ textAlign: 'center' }}>
          <EmptyState header={apiError.title} text={apiError.message}>
            <Link href="/events">
              <Button variant="contained" color="secondary">
                BACK TO EVENTS
              </Button>
            </Link>
          </EmptyState>
        </div>
      )

    if (submitted) {
      if (!hasError) {
        return (
          <Page centered>
            <CheckCircleIconContainer>
              <CheckCircleIcon />
              <Typography variant="h4">Jersey Size Selected</Typography>
            </CheckCircleIconContainer>
            <div style={{ height: 16 }} />
            <Link href="/events" sx={{ textDecoration: 'none' }}>
              <Button color="secondary" variant="contained">
                BACK to Events
              </Button>
            </Link>
          </Page>
        )
      }

      return (
        <div style={{ textAlign: 'center' }}>
          <EmptyState header="Oops!" text={`${hasError}`} />
        </div>
      )
    }

    if (!participant)
      return (
        <div style={{ textAlign: 'center' }}>
          <EmptyState
            header="Hmm… We cannot find the participant you are looking for"
            text="...or may not be one of the bike-out participants"
          />
        </div>
      )

    return (
      <Container maxWidth="md">
        <div
          style={{ margin: 'auto', textAlign: 'center', marginBottom: '10px' }}>
          <Button
            color="primary"
            onClick={() => {
              setShowSizeImage('jersey_size_chart_v2.jpg')
              setShowSize(false)
              setShowBikeSize(true)
            }}>
            View Bike Jersey Size Chart
          </Button>
        </div>
        <div style={{ height: 30 }} />

        <Formik
          initialValues={{
            ...participant,
            eventName: participant.event.name,
            categoryName: participant.category.name,
          }}
          validateOnMount={true}
          enableReinitialize
          validateOnBlur={false}
          validationSchema={jerseySchema}
          onSubmit={async (values, actions) => {
            setPageLoading(true)
            try {
              await doMutation('updateParticipant', updateParticipant, {
                input: {
                  id: participant.id,
                  jerseySize: values.jerseySize,
                },
              })
              setHasError(null)
            } catch (e) {
              console.log(e)
              setHasError('Please contact admin@megatechph.com for assistance.')
            }
            setSubmitted(true)
            setPageLoading(false)
            actions.setSubmitting(false)
          }}>
          {(form) => {
            // const updateField = (key, value) => {
            //   form.setFieldValue(key, value)
            //   form.setTouched({
            //     ...form.touched,
            //     [key]: participant[key] !== value,
            //   })
            // }

            return (
              <Form id="jersey-form-details">
                <Card>
                  <FormGrid
                    content={jerseyFormContent({
                      values: form.values,
                      formDisabled: form.isSubmitting,
                    })}
                  />
                </Card>
                <FormButtonContainer wrap={false}>
                  <FormButton
                    submit
                    primary
                    outlined
                    disabled={!form.isValid || form.isSubmitting}>
                    {'Submit'}
                  </FormButton>
                </FormButtonContainer>
              </Form>
            )
          }}
        </Formik>

        <Dialog
          open={showSize || showBikeSize}
          maxWidth={'lg'}
          onClose={() => {
            setShowSize(false)
            setShowBikeSize(false)
          }}
          content={
            <Grid
              item
              lg={12}
              sx={(theme) => ({
                height: '100vh',
                overflow: 'hidden',
                [theme.breakpoints.up('md')]: {
                  width: 'calc(100vw/2)',
                  height: 'calc(100vh/2)',
                },
              })}>
              <Box
                sx={(theme) => ({
                  height: '100%',
                  overflow: 'scroll',
                  [theme.breakpoints.up('md')]: {
                    overflow: 'unset',
                  },
                })}>
                <CardMedia
                  sx={(theme) => ({
                    height: '650px',
                    backgroundSize: 'cover',
                    [theme.breakpoints.up('md')]: {
                      height: '100%',
                      backgroundSize: 'contain',
                    },
                  })}
                  image={`${process.env.PUBLIC_URL}/images/${showSizeImage}`}
                />
              </Box>
            </Grid>
          }
          buttons={
            <>
              <Button
                variant="text"
                onClick={() => {
                  setShowSizeImage('')
                  setShowSize(false)
                  setShowBikeSize(false)
                }}>
                Close
              </Button>
            </>
          }
        />
      </Container>
    )
  }

  return (
    <Page noPadding>
      <div style={{ height: 48 }} />
      {!loading && renderContent()}
    </Page>
  )
}

export default SubmitJersey
