import React, { createContext, useState } from 'react'

import PageLoader from '../components/Page/Loader'

export const PageLoaderContext = createContext(null)

export const PageLoaderContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [loading, setLoading] = useState(false)

  const contextValues = {
    pageLoading: loading,
    setLoading,
  }

  return (
    <PageLoaderContext.Provider value={contextValues}>
      {loading && <PageLoader />}
      {children}
    </PageLoaderContext.Provider>
  )
}
