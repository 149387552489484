import * as Yup from 'yup'

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Email Address must be a valid email')
    .required('Email Address is required'),
  confirmEmail: Yup.string()
    .email('Confirm Email Address must be a valid email')
    .required('Confirm Email Address is required')
    .oneOf([Yup.ref('email'), null], 'Email addresses must match'),
  phoneNumber: Yup.string().required('Phone number is required'),
  confirmPhoneNumber: Yup.string()
    .required('Confirm phone number is required')
    .oneOf([Yup.ref('phoneNumber'), null], 'Phone numbers must match'),
})

export default UserSchema
