/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import PoolIcon from '@mui/icons-material/Pool'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { Dayjs } from 'dayjs'

import {
  COUNTRIES,
  GENDERS,
  TEAM_TYPES,
  TSHIRT_SIZES,
} from '../../../constants/models'
import { isCategoryMultisports } from '../../../utils/ApiHelpers'
import FormCheckboxGroup, { Option } from '../elements/FormCheckboxGroup'
import FormDatePicker from '../elements/FormDatePicker'
import FormSelectInput from '../elements/FormSelectInput'
import FormTextInput from '../elements/FormTextInput'

interface CategoryProps {
  id: string
  name: string
  priority: number
  disabled?: boolean
}

const tandemForm = ({ formDisabled, updateField }) => ({
  id: 'tandem-form-details',
  name: 'Tandem Participant Information',
  inputs: [
    {
      id: 'tandemName',
      size: { sm: 12, md: 6 },
      field: (
        <FormTextInput
          name="tandemName"
          label="Name"
          required
          autoComplete="new-tandemName"
          disabled={formDisabled}
        />
      ),
    },
    {
      id: 'tandemBirthdate',
      size: { sm: 12, md: 6 },
      field: (
        <FormDatePicker
          name="tandemBirthdate"
          label="Birthdate"
          required
          autoComplete="new-tandemBirthdate"
          placeholder="YYYY-MM-DD"
          disabled={formDisabled}
          onModified={(value: Dayjs) =>
            updateField('tandemBirthdate', value, true)
          }
        />
      ),
    },
    {
      id: 'tandemShirtSize',
      size: { sm: 12, md: 6 },
      field: (
        <FormSelectInput
          name="tandemShirtSize"
          label="T-shirt size"
          autoComplete="new-tandemShirtSize"
          placeholder="Select T-shirt Size"
          disabled={formDisabled}
          options={Object.entries(TSHIRT_SIZES).map(([key, value]) => ({
            value: key,
            text: value,
          }))}
          required
        />
      ),
    },
  ],
})

const ParticipantFormContent = ({
  values,
  updateField,
  formDisabled,
  categories,
  checkCurrentUser,
  handleCheckCurrentUser,
  handleCategoryChange,
  tandemInfo,
  hasAdditionalId,
  additionalIdFieldLabel,
  handleRelayEventChange,
  selectedRelayEvent,
  currentRelayEvent,
  firstParticipants,
  isOnsiteRegistration,
  subCategories,
}) => {
  return [
    {
      id: 'category-form-details',
      name: 'Event',
      inputs: [
        {
          id: 'categoryId',
          size: { sm: 12, md: 6 },
          field: (
            <FormSelectInput
              name="categoryId"
              label="Category"
              autoComplete="new-categoryId"
              disabled={formDisabled}
              placeholder="Select Category"
              options={categories
                .sort((a: CategoryProps, b: CategoryProps) => {
                  if (!!a.priority && !b.priority) return -1
                  if (!!b.priority && !a.priority) return 1
                  return a.priority - b.priority
                })
                .map((category: CategoryProps) => ({
                  value: category.id,
                  text: category.name,
                  disabled: category.disabled,
                }))}
              onModified={handleCategoryChange}
              required
            />
          ),
        },
        {
          ...(isCategoryMultisports(values) && {
            id: 'relayEvent',
            size: { sm: 12, md: 6 },
            field: (
              <FormCheckboxGroup
                name="relayEvent"
                label="Relay Event"
                required
                options={
                  [
                    ...(values.teamType === TEAM_TYPES.DUATHLON
                      ? [
                          {
                            text: 'RUN 1',
                            value: 'RUN1',
                            image: <DirectionsRunIcon />,
                            disabled:
                              !currentRelayEvent.includes('RUN1') &&
                              selectedRelayEvent.includes('RUN1'),
                          },
                        ]
                      : [
                          {
                            text: 'SWIM',
                            value: 'SWIM',
                            image: <PoolIcon />,
                            disabled:
                              !currentRelayEvent.includes('SWIM') &&
                              selectedRelayEvent.includes('SWIM'),
                          },
                        ]),
                    {
                      text: 'BIKE',
                      value: 'BIKE',
                      image: <DirectionsBikeIcon />,
                      disabled:
                        !currentRelayEvent.includes('BIKE') &&
                        selectedRelayEvent.includes('BIKE'),
                    },
                    {
                      text: 'RUN 2',
                      value: `RUN${
                        values.teamType === TEAM_TYPES.DUATHLON ? '2' : ''
                      }`,
                      image: <DirectionsRunIcon />,
                      disabled:
                        !currentRelayEvent.includes(
                          `RUN${
                            values.teamType === TEAM_TYPES.DUATHLON ? '2' : ''
                          }`,
                        ) &&
                        selectedRelayEvent.includes(
                          `RUN${
                            values.teamType === TEAM_TYPES.DUATHLON ? '2' : ''
                          }`,
                        ),
                    },
                  ] as Option[]
                }
                checkedOptions={values.relayEvent}
                disabled={formDisabled}
                placeholder="Select at least 1 Relay Event"
                onModified={handleRelayEventChange}
              />
            ),
          }),
        },
        {
          id: 'shirtSize',
          size: { sm: 12, md: 6 },
          field: (
            <FormSelectInput
              name="shirtSize"
              label="T-shirt size"
              autoComplete="new-shirtSize"
              placeholder="Select T-shirt Size"
              disabled={formDisabled}
              options={Object.entries(TSHIRT_SIZES).map(([key, value]) => ({
                value: key,
                text: value,
              }))}
              required
            />
          ),
        },
        {
          id: 'teamName',
          size: 6,
          field: (
            <FormTextInput
              name="teamName"
              label="Team Name"
              autoComplete="new-teamName"
              disabled={formDisabled}
            />
          ),
        },
        {
          ...(subCategories &&
            Object.keys(subCategories).length > 0 && {
              id: 'subCategory',
              size: 6,
              field: (
                <FormSelectInput
                  name="subCategory"
                  label={subCategories.label}
                  autoComplete="new-subCategory"
                  placeholder={`Select ${subCategories.label}`}
                  disabled={formDisabled}
                  options={subCategories.options
                    .sort((a, b) => a.priority - b.priority)
                    .map((value: any) => ({
                      value: value.name,
                      text: value.name,
                    }))}
                  required={
                    'required' in subCategories && subCategories.required
                  }
                />
              ),
            }),
        },
      ],
    },
    {
      id: 'participant-form-details',
      name: 'Participant',
      inputs: [
        {
          ...(!isOnsiteRegistration && {
            id: 'checkCurrentUser',
            size: 12,
            field: (
              <Typography variant="body2">
                <Checkbox
                  checked={checkCurrentUser}
                  onChange={handleCheckCurrentUser}
                  inputProps={{
                    'aria-label': 'primary checkbox',
                    name: 'checkCurrentUser',
                  }}
                />
                Add User as Participant
              </Typography>
            ),
          }),
        },
        {
          ...(hasAdditionalId && {
            id: 'additionalId',
            size: { sm: 12 },
            field: (
              <FormTextInput
                name="additionalId"
                label={additionalIdFieldLabel}
                autoComplete="new-additionalId"
                disabled={formDisabled}
              />
            ),
          }),
        },
        {
          ...(firstParticipants &&
            firstParticipants.NUMBER > firstParticipants.TOTAL &&
            firstParticipants.ADDITIONAL_INFO_LABEL && {
              id: 'firstParticipantInfo',
              size: { sm: 12 },
              field: (
                <FormTextInput
                  name="firstParticipantInfo"
                  label={firstParticipants.ADDITIONAL_INFO_LABEL}
                  autoComplete="new-firstParticipantInfo"
                  disabled={formDisabled}
                />
              ),
            }),
        },
        {
          id: 'firstName',
          size: { sm: 12, md: 6 },
          field: (
            <FormTextInput
              name="firstName"
              label="First Name"
              required
              autoComplete="new-firstName"
              disabled={formDisabled}
            />
          ),
        },
        {
          id: 'lastName',
          size: { sm: 12, md: 6 },
          field: (
            <FormTextInput
              name="lastName"
              label="Last Name"
              required
              autoComplete="new-lastName"
              disabled={formDisabled}
            />
          ),
        },
        {
          id: 'birthdate',
          size: { sm: 12, md: 4 },
          field: (
            <FormDatePicker
              name="birthdate"
              label="Birthdate"
              required
              autoComplete="new-birthdate"
              placeholder="YYYY-MM-DD"
              disabled={formDisabled}
              onModified={(value: Dayjs) =>
                updateField('birthdate', value, true)
              }
            />
          ),
        },
        {
          id: 'nationality',
          size: { sm: 12, md: 4 },
          field: (
            <FormSelectInput
              name="nationality"
              label="Nationality"
              autoComplete="new-nationality"
              placeholder="Select Nationality"
              disabled={formDisabled}
              options={Object.entries(COUNTRIES).map(([key, value]) => ({
                value: key,
                text: value,
              }))}
              required
            />
          ),
        },
        {
          id: 'gender',
          size: { sm: 12, md: 4 },
          field: (
            <FormSelectInput
              name="gender"
              label="Gender"
              autoComplete="new-gender"
              placeholder="Select Gender"
              disabled={formDisabled}
              options={Object.entries(GENDERS).map(([key, value]) => ({
                value: key,
                text: value,
              }))}
              required
            />
          ),
        },
        {
          id: 'address',
          size: { sm: 12, md: 12 },
          field: (
            <FormTextInput
              name="address"
              label="Home Address"
              required
              autoComplete="new-address"
              disabled={formDisabled}
            />
          ),
        },

        {
          id: 'phoneNumber',
          size: { sm: 12, md: 6 },
          field: (
            <FormTextInput
              name="phoneNumber"
              label="Phone Number"
              required
              autoComplete="new-phoneNumber"
              disabled={formDisabled}
              onCut={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => {
                e.preventDefault()
              }}
            />
          ),
        },
        {
          ...(!isOnsiteRegistration && {
            id: 'confirmPhoneNumber',
            size: { sm: 12, md: 6 },
            field: (
              <FormTextInput
                name="confirmPhoneNumber"
                label="Confirm Phone Number"
                required
                autoComplete="new-confirmPhoneNumber"
                disabled={formDisabled}
                onCut={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                onPaste={(e) => {
                  e.preventDefault()
                }}
              />
            ),
          }),
        },
        {
          ...(isOnsiteRegistration && {
            id: 'email',
            size: { sm: 12, md: 6 },
            field: (
              <FormTextInput
                name="email"
                label="Email Address"
                autoComplete="new-email"
                disabled={formDisabled}
                onCut={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                onPaste={(e) => {
                  e.preventDefault()
                }}
                onModified={(e) => {
                  updateField('email', e.target.value.toUpperCase().trim())
                }}
              />
            ),
          }),
        },
        // {
        //   ...(!isOnsiteRegistration && {
        //     id: 'confirmEmail',
        //     size: { sm: 12, md: 6 },
        //     field: (
        //       <FormTextInput
        //         name="confirmEmail"
        //         label="Confirm Email Address"
        //         autoComplete="new-confirmEmail"
        //         disabled={formDisabled}
        //         onCut={(e) => e.preventDefault()}
        //         onCopy={(e) => e.preventDefault()}
        //         onPaste={(e) => {
        //           e.preventDefault()
        //         }}
        //         onModified={(e) => {
        //           updateField(
        //             'confirmEmail',
        //             e.target.value.toUpperCase().trim(),
        //           )
        //         }}
        //       />
        //     ),
        //   }),
        // },
      ],
    },
    { ...(tandemInfo.isTandem && tandemForm({ formDisabled, updateField })) },
    {
      id: 'participant-emergency-form-details',
      name: 'Emergency Contact Person',
      inputs: [
        {
          id: 'emergencyContactName',
          size: { sm: 12, md: !isOnsiteRegistration ? 12 : 6 },
          field: (
            <FormTextInput
              name="emergencyContactName"
              label="Emergency Contact Name"
              required
              autoComplete="new-emergencyContactName"
              disabled={formDisabled}
            />
          ),
        },
        {
          id: 'emergencyContactPhone',
          size: { sm: 12, md: 6 },
          field: (
            <FormTextInput
              name="emergencyContactPhone"
              label="Emergency Contact Number"
              required
              autoComplete="new-emergencyContactPhone"
              disabled={formDisabled}
              onCut={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => {
                e.preventDefault()
              }}
            />
          ),
        },
        {
          ...(!isOnsiteRegistration && {
            id: 'confirmEmergencyContactPhone',
            size: { sm: 12, md: 6 },
            field: (
              <FormTextInput
                name="confirmEmergencyContactPhone"
                label="Confirm Emergency Contact Number"
                required
                autoComplete="new-confirmEmergencyContactPhone"
                disabled={formDisabled}
                onCut={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                onPaste={(e) => {
                  e.preventDefault()
                }}
              />
            ),
          }),
        },
      ],
    },
  ]
}
export default ParticipantFormContent
