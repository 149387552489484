import * as Yup from 'yup'

const RepeaterFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, 'First name must not exceed 50 characters')
    .required('First Name is required'),
  lastName: Yup.string()
    .max(50, 'Last name must not exceed 50 characters')
    .required('Last Name is required'),
  bibNumber: Yup.string().required('Previous bib number is required'),
})

export default RepeaterFormSchema
