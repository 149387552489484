import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Gallery } from 'react-grid-gallery'

import Section from '../components/Section'
import { MOTTO } from '../constants/models'

const IMAGE_URL = `${process.env.PUBLIC_URL}/images/aboutus/`
const images = [
  {
    src: `${IMAGE_URL}330421841_1371882396965571_2444123099855029055_n.jpeg`,
    width: 960,
    height: 720,
  },
  {
    src: `${IMAGE_URL}331965343_560608419357693_2976046963766220759_n.jpeg`,
    width: 1280,
    height: 960,
  },
  {
    src: `${IMAGE_URL}332107094_1364815130983923_8015623203151779427_n.jpeg`,
    width: 886,
    height: 920,
  },
  {
    src: `${IMAGE_URL}332142398_1345022069407232_5689437458363759840_n.jpeg`,
    width: 960,
    height: 720,
  },
  {
    src: `${IMAGE_URL}332285220_587141379625294_5607041081246714731_n.jpeg`,
    width: 720,
    height: 960,
  },
  {
    src: `${IMAGE_URL}342470542_602047115291019_141193534212051287_n.jpg`,
    width: 1028,
    height: 768,
  },
  {
    src: `${IMAGE_URL}343328951_1943808095969956_6415252624503878765_n.jpeg`,
    width: 1024,
    height: 682,
    thumbnailCaption: 'By MOTUS Photo',
  },
  {
    src: `${IMAGE_URL}352827393_739384161520952_3889272185940928683_n.jpg`,
    width: 1024,
    height: 682,
    thumbnailCaption: 'By MOTUS Photo',
  },
  {
    src: `${IMAGE_URL}354232524_201068049560214_3955777085560241801_n.jpg`,
    width: 768,
    height: 1028,
  },
  {
    src: `${IMAGE_URL}361646330_766500342142667_9034473202124732694_n.jpg`,
    width: 1024,
    height: 682,
    thumbnailCaption: 'By MOTUS Photo',
  },
  {
    src: `${IMAGE_URL}370314819_243688638631488_7338446138242898427_n.jpeg`,
    width: 1903,
    height: 1356,
  },
  // {
  //   src: `${IMAGE_URL}373553271_793770162749018_170483055398927654_n.jpg`,
  //   width: 1024,
  //   height: 682,
  //   thumbnailCaption: 'By MOTUS Photo',
  // },
  {
    src: `${IMAGE_URL}377700897_800445052081529_3164911549180182758_n.jpg`,
    width: 1024,
    height: 682,
    thumbnailCaption: 'By MOTUS Photo',
  },
  {
    src: `${IMAGE_URL}385045237_264840619849623_5323243528924276266_n.jpg`,
    width: 1024,
    height: 907,
  },
  {
    src: `${IMAGE_URL}386100599_267934586206893_7325644642265339934_n.jpeg`,
    width: 816,
    height: 1270,
  },
  {
    src: `${IMAGE_URL}399711917_845792537546780_1269035720186359064_n.jpg`,
    width: 1024,
    height: 682,
    thumbnailCaption: 'By MOTUS Photo',
  },
  {
    src: `${IMAGE_URL}404014273_293011430365875_2282275830308899176_n.jpeg`,
    width: 828,
    height: 620,
  },
  {
    src: `${IMAGE_URL}404167781_847879154004785_8692370580525696043_n.jpg`,
    width: 1024,
    height: 682,
    thumbnailCaption: 'By MOTUS Photo',
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>By MOTUS Photo</div>
    //   </div>
    // ),
  },
  {
    src: `${IMAGE_URL}405951281_852766920182675_3387859783511787587_n.jpg`,
    width: 1024,
    height: 682,
    thumbnailCaption: 'By MOTUS Photo',
  },
]

interface MissionVisionItemProps {
  title: string
  Icon: React.ElementType
  text: string
}

const MissionVisionItem: React.FC<MissionVisionItemProps> = ({
  title,
  Icon,
  text,
}) => (
  <Grid item sm={6} xs={12}>
    <Grid
      container
      sx={{ mb: 2 }}
      direction="row"
      justifyContent="center"
      alignItems="center">
      <Icon color="secondary" />
      <Typography
        variant="h6"
        sx={{ ml: 2 }}
        color={(theme) => theme.palette.secondary.main}>
        {title}
      </Typography>
    </Grid>
    <Typography textAlign="justify">{text}</Typography>
  </Grid>
)

const AboutUs: React.FC = () => {
  return (
    <>
      <Section>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4">MEGATechPH</Typography>
          <Typography
            variant="overline"
            color={(theme) => theme.palette.secondary.main}>
            {MOTTO}
          </Typography>
        </Box>
        <Typography textAlign="justify">
          Welcome to MEGATechPH, a trailblazer in sports timing systems. At
          MEGATechPH, we take pride in revolutionizing the way sporting events
          are timed and managed. With a dedication to precision, innovation, and
          reliability, we are the go-to choice for organizers, athletes, and
          sports enthusiasts alike.
          <br />
          <br />
          Our cutting-edge sports timing solutions are crafted to meet the
          demands of diverse sports, from marathons to cycling races and
          everything in between. Backed by state-of-the-art technology, our
          systems ensure accurate and real-time results, enhancing the overall
          experience for participants and spectators.
          <br />
          <br /> At MEGATechPH, we go beyond providing just a timing system. We
          are your partner in orchestrating seamless events, offering
          customizable solutions to match the unique requirements of each sport.
          Our team is driven by a passion for sports excellence and a commitment
          to delivering timing solutions that set new standards.
          <br />
          <br />
          Join us in embracing the future of sports timing. Choose MEGATechPH
          for precision, efficiency, and the thrill of perfectly timed moments
          in every competition.
        </Typography>
      </Section>
      <Section secondary>
        <Grid container spacing={3}>
          <Grid item container spacing={6}>
            <MissionVisionItem
              title="Mission"
              Icon={RocketLaunchIcon}
              text={`We are dedicated to providing time system solutions that seamlessly integrate into diverse lifestyles and work environments. Our mission is to enhance efficiency, foster work-life balance, and promote personal and professional growth by delivering intuitive, reliable, and personallized timing tools.`}
            />
            <MissionVisionItem
              title="Vision"
              Icon={VisibilityIcon}
              text={`To be a global leader in innovative sports timing solutions, empowering individuals and organizations to optimize productivity and achieve their goals through cutting-edge technology.`}
            />
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Typography variant="h4" textAlign="center" sx={{ mb: 3 }}>
          Our partners
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={6}>
          <Grid item>
            <Link href="https://www.facebook.com/MegawearCebu" target="_blank">
              <img
                height={80}
                src={`${window.location.origin}/images/megawear_icon.png`}
              />
            </Link>
          </Grid>

          <Grid item>
            <img
              height={80}
              src={`${window.location.origin}/images/safeevents_icon.jpg`}
            />
          </Grid>

          <Grid item>
            <Link href="https://www.mylaps.com" target="_blank">
              <img
                height={160}
                src={`${window.location.origin}/images/mylaps.jpeg`}
              />
            </Link>
          </Grid>
        </Grid>
      </Section>
      <Section secondary>
        <Typography variant="h4" textAlign="center" sx={{ mb: 3 }}>
          Meet our team
        </Typography>
        <Gallery
          images={images}
          enableImageSelection={false}
          rowHeight={360}
          margin={4}
        />
      </Section>
    </>
  )
}
export default AboutUs
