import { Typography } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import { styled } from '@mui/material/styles'
import React, { memo } from 'react'
import Carousel from 'react-material-ui-carousel'
import { CarouselProps } from 'react-material-ui-carousel/dist/components/types'

export interface CarouselItemProps {
  url: string
  caption?: string
}

interface Props extends CarouselProps {
  items: CarouselItemProps[]
  imageStyle?: string
  isFull?: boolean
  backgroundSize?: string
  backgroundHeight?: string
}

const Caption = styled(Typography)(() => ({
  position: 'absolute',
  bottom: '1rem',
  width: '100vw',
  textAlign: 'center',
  color: 'white',
  textShadow: '0px 0px 2px #000',
  fontWeight: 'bold',
  textTransform: 'uppercase',
}))

// const DivContainainer = styled('div')(({ theme }) => ({
const DivContainainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}))

const ImageCarousel: React.FC<Props> = ({
  items,
  imageStyle,
  isFull,
  backgroundSize = 'contain',
  backgroundHeight,
  ...rest
}: Props) => (
  <DivContainainer>
    <Carousel {...rest}>
      {items.map((item) => (
        <div style={{ position: 'relative' }} key={item.url}>
          <CardMedia
            key={`fullCover-${item.url}`}
            sx={(theme) => ({
              backgroundSize,
              height: isFull ? '900px' : backgroundHeight || '350px',
              [theme.breakpoints.up('sm')]: {
                height: isFull ? '900px' : backgroundHeight || '600px',
              },
              ...(imageStyle && {
                [theme.breakpoints.up('md')]: {
                  ...JSON.parse(imageStyle),
                },
              }),
            })}
            image={item.url}
          />
          <Caption variant="overline">{item.caption}</Caption>
        </div>
      ))}
    </Carousel>
  </DivContainainer>
)

export default memo(ImageCarousel)
