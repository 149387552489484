import React from 'react'

import Button from '../../elements/Button'

interface Props {
  children: JSX.Element | string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  submit?: boolean
  primary?: boolean
  secondary?: boolean
  outlined?: boolean
  contained?: boolean
  disabled?: boolean
  text?: boolean
  form?: string
  fullWidth?: boolean
}
const FormButton: React.FC<Props> = ({
  submit = false,
  primary = false,
  secondary = false,
  outlined = false,
  contained = false,
  disabled = false,
  text = false,
  fullWidth = false,
  form,
  onClick,
  children,
}) => (
  <Button
    type={`${submit ? 'submit' : 'button'}`}
    primary={primary}
    secondary={secondary}
    outlined={outlined}
    contained={contained}
    text={text}
    {...(onClick && { onClick })}
    disabled={disabled}
    form={form}
    fullWidth={fullWidth}
  >
    {children}
  </Button>
)

export default FormButton
