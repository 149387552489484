import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

const dimension = '14rem'

const imageDimensions = {
  width: dimension,
  height: dimension,
}

// const ContainerDiv = styled('div')(({ theme }) => ({
//   padding: '1rem 2.5rem',
//   background: `linear-gradient(transparent 40%, ${theme.palette.grey[100]} 40%) no-repeat`,

//   '& .MuiTypography-root': {
//     paddingTop: '1rem',
//     fontWeight: 500,
//     textTransform: 'uppercase',
//   },
// }))
const ContainerDiv = styled(Grid)(({ theme }) => ({
  background: `linear-gradient(transparent 40%, ${theme.palette.grey[100]} 40%) no-repeat`,

  '& .MuiTypography-root': {
    paddingTop: '1rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}))

const ImageDiv = styled('div')(({ theme }) => ({
  width: `${imageDimensions.width}`,
  height: `${imageDimensions.height}`,
  overflow: 'hidden',
  border: `6px solid ${theme.palette.error.main}`,
  borderRadius: '50%',

  '&:hover': {
    cursor: 'pointer',
    borderColor: `${theme.palette.primary.main}`,
  },
}))

const IconDiv = styled(Grid)(({ theme }) => ({
  backgroundColor: 'white',
  // width: `${imageDimensions.width}`,
  // height: `${imageDimensions.height}`,
  width: '4rem',
  height: '4rem',
  overflow: 'hidden',
  border: `6px solid ${theme.palette.error.main}`,
  borderRadius: '50%',
  display: 'flex',
  margin: 'auto',
}))

interface Props {
  name: string
  image?: string
  Icon?: React.ElementType
}

const Entitlement: React.FC<Props> = ({ image, name, Icon }) => (
  <ContainerDiv
    item
    sx={{
      padding: `1rem ${image ? '2.5rem' : ''}`,
    }}>
    {image && (
      <ImageDiv>
        <CardMedia
          style={{
            height: `${imageDimensions.height}`,
            backgroundSize: 'cover',
          }}
          image={image}
        />
      </ImageDiv>
    )}
    {Icon && (
      <IconDiv>
        <Icon
          sx={{
            display: 'block',
            margin: 'auto',
            fontSize: '2rem',
          }}
        />
      </IconDiv>
    )}
    <Typography
      variant="subtitle1"
      color="textSecondary"
      textAlign="center"
      gutterBottom
      align="justify"
      sx={{
        width: '14rem',
      }}>
      {name}
    </Typography>
  </ContainerDiv>
)

export default Entitlement
