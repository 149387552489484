/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'

import { ErrorProps } from './common'
import { Event as EventApi } from '../API'
import { eventBySlug } from '../api/queries'
import { ACCESS_TYPES } from '../constants/models'
import { fetchQuery } from '../utils/ApiUtils'

type Event = Omit<Exclude<EventApi, null>, '__typename'>

interface Props {
  slug: string | null | undefined
}
const useEventDetails = ({ slug }: Props) => {
  const [event, setEvent] = useState<Event>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorProps>()

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      if (didCancel) return
      setLoading(true)

      try {
        const { items } = (await fetchQuery('eventBySlug', eventBySlug, {
          slug,
        })) as unknown as { items: Event[] }

        if (items.length > 0)
          setEvent({
            ...items[0],
            accessType: items[0].accessType ?? ACCESS_TYPES.PUBLIC,
            ...(items[0].firstParticipants &&
              JSON.parse(JSON.parse(items[0].firstParticipants as string))),
            ...(items[0].subCategories && {
              subCategories: JSON.parse(
                JSON.parse(items[0].subCategories as string),
              ),
            }),
          })
      } catch (e) {
        console.log(e)
        setError({
          title: 'Fail to load event',
          message: '',
        })
      }
      setLoading(false)
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [slug])

  return {
    event,
    apiLoading: loading,
    apiError: error,
  }
}

export default useEventDetails
