/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'

import { WORK_SITE } from '../../../../constants/employees'

const FormParticipantFormSchema = Yup.object().shape({
  participantId: Yup.string().required('ID # is required'),
  workSite: Yup.mixed().required('Work Site is required').oneOf(WORK_SITE),
})
export default FormParticipantFormSchema
