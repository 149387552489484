import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Tag = styled('div')(({ theme }) => ({
  position: 'absolute',
  padding: '10px 40px',
  backgroundColor: theme.palette.error.main,
  color: 'white',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
}))

// export const ListContainer = styled('div')(({ theme }) => ({
export const ListContainer = styled('div')(() => ({
  width: 'auto',
  height: '200px',
  overflow: 'hidden',
  textAlign: 'center',
}))

export const ProgressContainer = styled('div')(() => ({
  height: '100%',
  margin: 'auto',
  display: 'table',
}))

export const Text = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))
