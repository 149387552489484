import FilledInput from '@mui/material/FilledInput'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectProps } from '@mui/material/Select'
import { useTheme } from '@mui/material/styles'
import { FieldProps } from 'formik'
import React from 'react'

interface Option {
  text: string
  value: string
  disabled?: boolean
}
interface Props {
  options?: Option[]
}

const SelectInput: React.FC<
  Props & Partial<SelectProps> & Partial<FormControlProps> & Partial<FieldProps>
> = ({
  options,
  label,
  onChange,
  value,
  placeholder,
  error,
  required,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <FormControl
      variant="filled"
      fullWidth
      // sx={[
      //   {
      //     width: '100%',
      //     '& > div': {
      //       borderTopLeftRadius: 8,
      //       borderTopRightRadius: 8,
      //     },
      //     '& > div::before, & > div:hover::before': {
      //       borderBottom: 0,
      //     },
      //     '& > div::after': {
      //       borderBottomColor: theme.palette.primary.main,
      //     },
      //     '&:focus-within > label': {
      //       // color: theme.palette.primary.main,
      //       color: theme.palette.text.primary,
      //     },
      //     '& > div > svg': {
      //       right: theme.spacing(3),
      //     },
      //     '&:disabled > label': {
      //       color: 'rgba(0, 0, 0, .38)',
      //     },
      //   },
      // ]}
      error={!!error}
      required={required}
      disabled={rest.disabled}
    >
      <InputLabel shrink={value !== ''} htmlFor={`select-${label}-helper`}>
        {label}
      </InputLabel>
      <Select
        value={options && options.length > 0 && value ? value : ''}
        onChange={onChange}
        input={<FilledInput name={label + ''} id={`select-${label}-helper`} />}
        {...rest}
        sx={[
          {
            '& > select': {
              color: theme.palette.text.secondary,
            },
          },
          {
            ...(value === '' && {
              color: 'rgba(0, 0, 0, .54)',
            }),
          },
        ]}
      >
        <MenuItem
          value=""
          key={`${label}-menuItem-none`}
          sx={[
            {
              '&:not(:last-child)': {
                borderBottom: '1px solid rgba(122, 153, 172,0.08)',
              },
              '&:hover': {
                backgroundColor: 'rgba(226,239,244,0.55)',
              },
            },
          ]}
        >
          {placeholder || <em>None</em>}
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            value={option.value}
            key={`${label}-menuItem-${option.value}`}
            disabled={!!option.disabled}
            selected={option.value === value}
            sx={[
              {
                '&:not(:last-child)': {
                  borderBottom: '1px solid rgba(122, 153, 172,0.08)',
                },
                '&:hover': {
                  backgroundColor: 'rgba(226,239,244,0.55)',
                },
              },
              {
                ...(option.value === value && {
                  backgroundColor: `rgba(226,239,244,0.55)!important`,
                }),
              },
            ]}
          >
            {option.text}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}

export default SelectInput
