import { useTheme } from '@aws-amplify/ui-react'
import { Container, SxProps, Typography } from '@mui/material'
import React from 'react'

interface Props {
  header?: string
  text?: string
  children?: JSX.Element
  containerSx?: SxProps
}

const EmptyState: React.FC<Props> = ({
  header,
  text,
  children,
  containerSx,
}) => {
  const { tokens } = useTheme()
  return (
    <Container sx={containerSx}>
      <Typography variant="h5" sx={{ marginBottom: tokens.space.large.value }}>
        {header}
      </Typography>
      <Typography>{text}</Typography>
      {children}
    </Container>
  )
}
export default EmptyState
