/* eslint-disable @typescript-eslint/no-explicit-any */
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useTheme, styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { memo } from 'react'

import { formatCurrency, formatDate } from '../constants/formats'
import { COUNTRIES, TEAM_TYPES, TSHIRT_SIZES } from '../constants/models'

const Divider = styled('div')(({ theme }) => ({
  backgroundColor: '#eee',
  height: 1,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

interface TotalProps {
  shoppingCartTotal: number
}

const Total: React.FC<TotalProps> = memo(
  ({ shoppingCartTotal }: TotalProps) => (
    <Grid container sx={(theme) => ({ paddingY: theme.spacing(2) })}>
      <Grid item xs={4}>
        <Typography variant="h6">Total</Typography>
      </Grid>
      <Grid item xs={8} sx={{ textAlign: 'right' }}>
        <Typography variant="h6">
          {`${formatCurrency(shoppingCartTotal)}`}
        </Typography>
      </Grid>
    </Grid>
  ),
)
Total.displayName = 'Total'

interface Props {
  shoppingCartItems: any[]
  shoppingCartTotal: number
  isFree?: boolean
}

const ShoppingCartBreakdown: React.FC<Props> = ({
  shoppingCartItems,
  shoppingCartTotal,
  isFree = false,
}) => {
  const theme = useTheme()
  const teamTypes = Object.keys(TEAM_TYPES)

  return (
    <Paper sx={{ padding: theme.spacing(2) }}>
      <Typography variant="h5" sx={{ paddingBottom: theme.spacing(2) }}>
        {!isFree ? 'Shopping Cart' : 'Summary'}
      </Typography>
      {!isFree && <Total shoppingCartTotal={shoppingCartTotal} />}
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="caption">Item</Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <Typography variant="caption">{!isFree ? 'Price' : ''}</Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        {shoppingCartItems.map((item, idx) => {
          const isTeam = item.isTriathlon || teamTypes.includes(item.teamType)

          return (
            <Grid key={`shoppingcartitem-${idx}`} container>
              {idx !== 0 && idx !== shoppingCartItems.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}

              <Grid item xs={9}>
                <Typography variant="subtitle2">
                  {`${item.eventName} - ${item.categoryName}${
                    'subCategory' in item ? ` [${item.subCategory}]` : ''
                  }`}
                  <br />
                </Typography>
                <Typography variant="caption">
                  {`${item.firstName} ${item.lastName}`}
                  <br />
                  {`${formatDate(item.birthdate, 'YYYY-MM-DD')} | ${
                    COUNTRIES[item.nationality]
                  } | ${item.gender} `}
                  <br />
                  {`${TSHIRT_SIZES[item.shirtSize]} ${
                    item.teamName ? `| ${item.teamName}` : ''
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'right' }}>
                {!isTeam && !isFree && (
                  <Typography variant="subtitle2">
                    {item.isBonus ? `FREE` : `${formatCurrency(item.fee)}`}
                  </Typography>
                )}
              </Grid>

              {!isTeam && !isFree && (
                <>
                  <Grid item xs={9}>
                    <Typography variant="subtitle2">Transaction Fee</Typography>
                  </Grid>

                  <Grid item xs={3} sx={{ textAlign: 'right' }}>
                    <Typography variant="subtitle2">
                      {item.isBonus
                        ? `FREE`
                        : `${formatCurrency(item.adminFee)}`}
                    </Typography>
                  </Grid>
                </>
              )}

              {idx === shoppingCartItems.length - 1 && isTeam && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="subtitle2">Subtotal</Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign: 'right' }}>
                    <Typography variant="subtitle2">
                      {formatCurrency(item.fee)}
                    </Typography>
                  </Grid>

                  <Grid item xs={9}>
                    <Typography variant="subtitle2">Transaction Fee</Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign: 'right' }}>
                    <Typography variant="subtitle2">
                      {formatCurrency(item.adminFee)}
                    </Typography>
                  </Grid>
                  <Total shoppingCartTotal={shoppingCartTotal} />
                </>
              )}
            </Grid>
          )
        })}
      </Grid>
    </Paper>
  )
}
export default ShoppingCartBreakdown
