import Typography from '@mui/material/Typography'
import React from 'react'

interface Props {
  title: string
}
const Title: React.FC<Props> = ({ title }: Props) => {
  return (
    <Typography variant="h5" sx={{ textAlign: 'center', margin: 'auto' }}>
      {title}
    </Typography>
  )
}

export default Title
