import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'

interface Option {
  text: string
  value: string
  image?: JSX.Element
  disabled?: boolean
}

interface Props {
  options: Option[]
  checkedOptions: string[]
  label: string
}

const CheckboxGroup: React.FC<
  Props & Partial<CheckboxProps> & Partial<FormControlProps>
> = ({
  options,
  checkedOptions,
  label,
  placeholder,
  name,
  error,
  required,
  onChange,
  // ...rest
}) => {
  return (
    <FormControl
      sx={{ sm: 12, md: 6 }}
      component="fieldset"
      error={!!error}
      required={required}
    >
      <FormLabel component="legend" sx={{ fontSize: 'calc(1rem * 0.75)' }}>
        {label}
      </FormLabel>
      <FormGroup sx={{ xs: 12 }}>
        <Grid container spacing={4}>
          {options.map((option) => (
            <Grid item key={option.text}>
              <FormControlLabel
                sx={{
                  sm: 4,
                  xs: 12,
                  display: 'flex',
                  alignItems: 'baseline',
                }}
                control={
                  <Checkbox
                    checked={
                      checkedOptions && checkedOptions.includes(option.value)
                    }
                    onChange={onChange}
                    name={name}
                    value={option.value}
                    disabled={option.disabled}
                    inputProps={{
                      name: `${name}-${option.value}`,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {option.image && (
                      <Typography variant="body2" sx={{ mr: 1 }}>
                        {option.image}
                      </Typography>
                    )}
                    <Typography variant="body2">{option.text}</Typography>
                  </div>
                }
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
      <FormHelperText sx={{ ml: 0 }}>{error || placeholder}</FormHelperText>
    </FormControl>
  )
}
export default CheckboxGroup
