/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/prop-types */
import {
  Button as AmplifyButton,
  Flex,
  Image,
  Loader,
  Icon,
} from '@aws-amplify/ui-react'
import { StorageManager } from '@aws-amplify/ui-react-storage'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Formik } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '@aws-amplify/ui-react/styles.css'
import { v4 as uuidv4 } from 'uuid'

import Page from './elements/Page'
import EmptyState from './EmptyState'
import uploadResultFormContent from './forms/contents/UploadResultFormContent'
import Form from './forms/elements/Form'
import FormButton from './forms/elements/FormButton'
import FormButtonContainer from './forms/elements/FormButtonContainer'
import FormGrid from './forms/elements/FormGrid'
import uploadResultSchema from './forms/schemas/UploadResultSchema'
import Section from './Section'
import { S3Object as S3ObjectApi } from '../API'
import { deleteS3Object, updateParticipant } from '../api/mutations'
import { s3ObjectsByObjectIdAndObjectTypeAndId } from '../api/queries'
import { formatDate } from '../constants/formats'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import useParticipantDetails from '../hooks/useParticipantDetails'
import { doMutation, fetchQuery } from '../utils/ApiUtils'

type S3Object = Omit<Exclude<S3ObjectApi, null>, '__typename'>

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const CheckCircleIconContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.success.main,
  marginBottom: theme.spacing(theme['container'].padding),
  '& svg': {
    fontSize: '40px',
  },
  '& h4': {
    color: theme.palette.success.main,
    marginLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
}))

interface FileProps {
  [key: string]: {
    status: string
  }
}

const UploadResults: React.FC = () => {
  const [hasError, setHasError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const { setLoading: setPageLoading } = useContext(PageLoaderContext)

  const [imageLoading, setImageLoading] = useState(false)

  const ref = React.useRef(null)
  const [files, setFiles] = React.useState<FileProps>({})

  const { participantId } = useParams()
  const {
    participant,
    apiLoading: loading,
    apiError,
  } = useParticipantDetails({
    id: participantId,
  })

  useEffect(() => {
    setPageLoading(loading)
  }, [loading, setPageLoading])

  useEffect(() => {
    if (ref.current) ref.current.clearFiles()
  }, [ref])

  const processFile = ({ file, key, objectId }) => {
    const ext = file.name.split('.').pop()
    return {
      file,
      key: `${key}-${uuidv4()}.${ext}`,
      metadata: {
        objectid: objectId,
        objecttype: 'result',
        contenttype: file.type,
      },
    }
  }

  const onFileRemove = useCallback(
    async ({ key }) => {
      setImageLoading(true)
      setPageLoading(true)
      const existing = Object.keys(files).filter((file) => file.startsWith(key))

      const newKey = existing.length > 0 ? existing[0] : key
      try {
        if (newKey in files && files[newKey].status === 'success') {
          await Storage.remove(newKey)

          const { items } = (await fetchQuery(
            's3ObjectsByObjectIdAndObjectTypeAndId',
            s3ObjectsByObjectIdAndObjectTypeAndId,
            {
              objectId: participantId,
              filter: {
                objectType: { eq: 'result' },
                key: { eq: newKey },
              },
            },
          )) as unknown as { items: S3Object[] }

          await Promise.all(
            items.map(async (item) => {
              await doMutation('deleteS3Object', deleteS3Object, {
                input: {
                  id: item.id,
                },
              })
            }),
          )
        }
      } catch (e) {
        setPageLoading(false)
        setImageLoading(false)
        console.log(e)
      }

      setPageLoading(false)
      setImageLoading(false)
      const currentFiles = files
      delete currentFiles[newKey]
      setFiles(currentFiles)
    },
    [files, setFiles, participantId, setPageLoading],
  )

  const onUploadError = useCallback(
    (error, { key }) => {
      setPageLoading(false)
      console.log(error)
      setFiles((prevFiles) => {
        return {
          ...prevFiles,
          [key]: {
            status: 'error',
          },
        }
      })
    },
    [setFiles, setPageLoading],
  )

  const onUploadSuccess = useCallback(
    ({ key }) => {
      setPageLoading(false)
      setFiles((prevFiles) => {
        return {
          ...prevFiles,
          [key]: {
            status: 'success',
          },
        }
      })
    },
    [setFiles, setPageLoading],
  )

  const onUploadStart = useCallback(
    ({ key }) => {
      setPageLoading(true)
      setFiles((prevFiles) => {
        return {
          ...prevFiles,
          [key]: {
            status: 'uploading',
          },
        }
      })
    },
    [setFiles, setPageLoading],
  )

  const renderContent = () => {
    if (apiError)
      return (
        <div style={{ textAlign: 'center' }}>
          <EmptyState header={apiError.title} text={apiError.message}>
            <Link href="/events">
              <Button variant="contained" color="secondary">
                BACK TO EVENTS
              </Button>
            </Link>
          </EmptyState>
        </div>
      )

    if (submitted) {
      if (!hasError) {
        return (
          <div style={{ textAlign: 'center' }}>
            <CheckCircleIconContainer>
              <CheckCircleIcon />
              <Typography variant="h4">Result submitted</Typography>
            </CheckCircleIconContainer>
            <div style={{ height: 16 }} />
            <Link href="/events" sx={{ textDecoration: 'none' }}>
              <Button color="secondary" variant="contained">
                BACK to Events
              </Button>
            </Link>
          </div>
        )
      }

      return (
        <div style={{ textAlign: 'center' }}>
          <EmptyState header="Oops!" text={`${hasError}`} />
        </div>
      )
    }

    let errorHeader = ''
    let errorText = ''

    if (!participant)
      errorHeader = 'Hmm… We cannot find the participant you are looking for'
    else if (!participant.event.isVirtual)
      errorHeader = 'Hmm… We cannot find the participant you are looking for'
    else if (typeof participant.virtualProgress === 'number')
      errorHeader = 'Hmm.. You have already uploaded your result'
    else if (dayjs().isSameOrBefore(dayjs(participant.event.startDateTime))) {
      errorHeader = 'Too Early!'
      errorText = `Come back between ${formatDate(
        participant.event.startDateTime,
      )} to ${formatDate(participant.event.endDateTime)} to upload your result `
    } else if (
      dayjs().isSameOrAfter(dayjs(participant.event.endDateTime).add(7, 'day'))
    )
      errorHeader = 'Too Late!'

    if (errorHeader.length)
      return (
        <div style={{ textAlign: 'center' }}>
          <EmptyState header={errorHeader} text={errorText} />
          {errorHeader.includes('Too Late!') ||
            (errorHeader.includes('already uploaded') && (
              <Typography>
                Uploading of results is no longer accepted. For questions,
                please contact{' '}
                <Link
                  href="mailto:support@megatechph.com"
                  sx={{ textDecoration: 'none' }}>
                  support@megatechph.com
                </Link>
              </Typography>
            ))}
        </div>
      )

    return (
      <>
        <Formik
          initialValues={{
            ...participant,
            virtualProgress: participant.virtualProgress || '',
            eventName: participant.event.name,
            categoryName: participant.category.name,
          }}
          validateOnMount={true}
          enableReinitialize
          validateOnBlur={false}
          validationSchema={uploadResultSchema}
          onSubmit={async (values, actions) => {
            setPageLoading(true)
            try {
              await doMutation('updateParticipant', updateParticipant, {
                input: {
                  id: participantId,
                  virtualProgress: values.virtualProgress,
                },
              })
              setHasError(null)
            } catch (e) {
              setHasError(
                'Please contact support@megatechph.com for assistance.',
              )
            }
            setSubmitted(true)
            setPageLoading(false)
            actions.setSubmitting(false)
          }}>
          {(form) => {
            // const updateField = (key, value) => {
            //   form.setFieldValue(key, value)
            //   form.setTouched({
            //     ...form.touched,
            //     [key]: participant[key] !== value,
            //   })
            // }

            return (
              <Form id="upload-result-form-details">
                <Card>
                  <FormGrid
                    content={uploadResultFormContent({
                      values: form.values,
                      formDisabled: form.isSubmitting,
                    })}
                  />
                  <Box sx={{ p: 3 }}>
                    <Typography variant="body2">
                      Upload picture/screenshot of your running watch/app.
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ mb: 2, display: 'block' }}>
                      (Maximum 5 images of *.jpeg and *.png types will be
                      accepted)
                    </Typography>
                    <div
                      style={{
                        textAlign: 'center',
                        padding: '10px',
                        marginTop: '10px',
                        border: '1px dashed grey',
                        borderRadius: '5px',
                      }}>
                      <StorageManager
                        acceptedFileTypes={['.jpeg', '.jpg', 'image/png']}
                        accessLevel="public"
                        maxFileCount={5}
                        processFile={(data) => {
                          return processFile({
                            ...data,
                            objectId: participant.id,
                          })
                        }}
                        onFileRemove={onFileRemove}
                        onUploadError={onUploadError}
                        onUploadSuccess={onUploadSuccess}
                        onUploadStart={onUploadStart}
                        displayText={{
                          // some text are plain strings
                          // others are functions that take an argument
                          dropFilesText: `Drag 'n drop some files here, or click to select files`,
                          getFilesUploadedText(count) {
                            return `${count} images uploaded`
                          },
                        }}
                        ref={ref}
                        components={{
                          FileList({ files, onCancelUpload, onDeleteUpload }) {
                            return (
                              <Flex direction="row">
                                {files.map(
                                  ({
                                    file,
                                    key,
                                    progress,
                                    id,
                                    status,
                                    uploadTask,
                                  }) => (
                                    <Flex
                                      key={key}
                                      justifyContent="center"
                                      alignItems="center"
                                      width="13rem"
                                      height="13rem"
                                      position="relative">
                                      <Image
                                        borderRadius="small"
                                        height="100%"
                                        objectFit="cover"
                                        src={URL.createObjectURL(file)}
                                        alt={key}
                                      />
                                      <Loader
                                        position="absolute"
                                        style={{
                                          width: '70px',
                                          height: '70px',
                                        }}
                                        percentage={progress}
                                        isDeterminate
                                        isPercentageTextHidden
                                      />

                                      <AmplifyButton
                                        opacity="50"
                                        borderRadius="xxl"
                                        backgroundColor="background.primary"
                                        position="absolute"
                                        variation="link"
                                        style={{
                                          width: '70px',
                                          height: '70px',
                                        }}
                                        onClick={() => {
                                          if (status === 'uploading') {
                                            onCancelUpload({ id, uploadTask })
                                          } else {
                                            onDeleteUpload({ id })
                                          }
                                        }}>
                                        <Icon
                                          fontSize="large"
                                          color="font.error"
                                          viewBox={{ width: 512, height: 512 }}
                                          paths={[
                                            {
                                              d: 'M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z',
                                              strokeWidth: '32',
                                              fill: 'none',
                                              strokeMiterlimit: '10',
                                              stroke: 'currentColor',
                                            },
                                            {
                                              d: 'M320 320L192 192m0 128l128-128',
                                              strokeWidth: '32',
                                              fill: 'none',
                                              strokeLinecap: 'round',
                                              stroke: 'currentColor',
                                            },
                                          ]}
                                        />
                                      </AmplifyButton>
                                    </Flex>
                                  ),
                                )}
                              </Flex>
                            )
                          },
                        }}
                      />
                    </div>
                  </Box>
                </Card>
                <FormButtonContainer wrap={false}>
                  <FormButton
                    submit
                    primary
                    outlined
                    disabled={
                      !form.isValid ||
                      form.isSubmitting ||
                      imageLoading ||
                      Object.values(files).length < 1 ||
                      Object.values(files).filter(
                        (file) =>
                          file === undefined ||
                          (file !== undefined &&
                            ['error', 'uploading'].includes(file.status)),
                      )?.length > 0
                    }>
                    {'Submit'}
                  </FormButton>
                </FormButtonContainer>
              </Form>
            )
          }}
        </Formik>
      </>
    )
  }

  return (
    <Section>
      <Page noPadding>
        <Container maxWidth="md">{!loading && renderContent()}</Container>
      </Page>
    </Section>
  )
}

export default UploadResults
