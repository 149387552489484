/* eslint-disable @typescript-eslint/no-explicit-any */
import Card from '@mui/material/Card'
import { Formik } from 'formik'
import React from 'react'

import repeaterFormContent from './contents/RepeaterFormContent'
import Form from './elements/Form'
import FormButton from './elements/FormButton'
import FormButtonContainer from './elements/FormButtonContainer'
import FormGrid from './elements/FormGrid'
import repeaterSchema from './schemas/RepeaterFormSchema'

interface Props {
  user?: any
  updateData: (values) => any
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  editing?: boolean
  setLoading?: (value: boolean) => void
  displayError?: (values: any) => void
}

const RepeaterForm: React.FC<Props> = ({
  user: initUser,
  updateData,
  editing,
  setLoading,
  onCancel,
  displayError,
}) => {
  return (
    <Formik
      initialValues={initUser}
      validateOnMount={editing}
      enableReinitialize
      validationSchema={repeaterSchema}
      onSubmit={(values, actions) => {
        setLoading(true)
        const [, error] = updateData(values)
        if (error) {
          displayError({ title: error.title, message: error.message })
        }
        setLoading(false)
        actions.setSubmitting(false)
      }}
    >
      {(form) => {
        return (
          <Form id="repeater-form">
            <>
              <Card>
                <FormGrid
                  content={repeaterFormContent({
                    formDisabled: form.isSubmitting,
                  })}
                />
              </Card>
            </>
            <FormButtonContainer wrap={false}>
              <FormButton
                secondary
                outlined
                onClick={onCancel}
                disabled={form.isSubmitting}
              >
                Cancel
              </FormButton>
              <FormButton
                submit
                primary
                outlined
                disabled={!form.isValid || form.isSubmitting}
              >
                {'Next'}
              </FormButton>
            </FormButtonContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default RepeaterForm
