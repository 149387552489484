import React, { useContext, useEffect } from 'react'

import EventList from '../components/EventList'
import Section from '../components/Section'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import useEvents from '../hooks/useEvents'
import usePagination from '../hooks/usePagination'

const PastEvents: React.FC = () => {
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)
  const { events, apiLoading, apiError, apiLoadMore, apiLoadingMore } =
    useEvents(false)

  const { setTriggerElement } = usePagination(apiLoadMore)

  useEffect(() => {
    let didCancel = false
    if (didCancel) return

    setPageLoading(apiLoading)
    return () => {
      didCancel = true
    }
  })

  return (
    <Section>
      <EventList
        {...{
          events,
          apiError,
          apiLoading,
          apiLoadingMore,
          isCurrent: false,
          onTriggerElement: setTriggerElement,
        }}
      />
    </Section>
  )
}
export default PastEvents
