import * as Yup from 'yup'

import { JERSEY_SHIRT_SIZES } from '../../../constants/models'

const JerseySchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  eventName: Yup.string(),
  categoryName: Yup.string(),
  jerseySize: Yup.mixed()
    .required('Jersey shirt size is required')
    .oneOf(Object.keys(JERSEY_SHIRT_SIZES)),
})

export default JerseySchema
