/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card } from '@mui/material'
import Container from '@mui/system/Container'
import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import userFormContent from './contents/UserFormContent'
import Form from './elements/Form'
import FormButton from './elements/FormButton'
import FormButtonContainer from './elements/FormButtonContainer'
import FormGrid from './elements/FormGrid'
import userSchema from './schemas/UserSchema'
import useEventDetails from '../../hooks/useEventDetails'
import Button from '../elements/Button'
import EmptyState from '../EmptyState'

interface Props {
  show: boolean
  user?: any
  updateData: (values) => any
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  editing?: boolean
  setLoading?: (value: boolean) => void
  displayError?: (values: any) => void
}

const RegistrationForm: React.FC<Props> = ({
  show,
  user: initUser,
  updateData,
  onCancel,
  editing,
  setLoading,
  displayError,
}) => {
  const { slug } = useParams()
  const {
    event,
    apiLoading: loading,
    apiError,
  } = useEventDetails({
    slug,
  })
  const user = initUser

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  const renderContent = () => {
    if (apiError)
      return (
        <EmptyState header={apiError.title} text={apiError.message}>
          <Link to="/events">
            <Button variant="contained" color="secondary">
              BACK TO EVENTS
            </Button>
          </Link>
        </EmptyState>
      )

    if (!event)
      return (
        <EmptyState
          header="Hmm… We cannot find the event you are looking for"
          text=""
        />
      )
    return (
      <>
        <Formik
          initialValues={initUser}
          validateOnMount={editing ? true : false}
          enableReinitialize
          validateOnBlur={false}
          validationSchema={userSchema}
          onSubmit={async (values, actions) => {
            setLoading(true)
            Object.keys(values).forEach((key) => {
              if (typeof values[key] === 'string')
                values[key] = values[key]?.trim()
            })
            const [, error] = await updateData(values)
            if (error) {
              displayError({ title: error.title, message: error.message })
            }
            setLoading(false)
            actions.setSubmitting(false)
          }}>
          {(form) => {
            //{ isSubmitting, values, touched, isValid, setFieldValue, setTouched }
            const updateField = (key, value) => {
              form.setFieldValue(key, value)
              form.setTouched({
                ...form.touched,
                [key]: user[key] !== value,
              })
            }

            return (
              <Form id="user-form">
                <>
                  <Card>
                    <FormGrid
                      content={userFormContent({
                        formDisabled: form.isSubmitting,
                        updateField,
                      })}
                    />
                  </Card>
                </>
                <FormButtonContainer wrap={false}>
                  <FormButton
                    secondary
                    outlined
                    onClick={onCancel}
                    disabled={form.isSubmitting}>
                    Cancel
                  </FormButton>
                  <FormButton
                    submit
                    primary
                    outlined
                    disabled={
                      !form.isValid || form.isSubmitting
                      //// !form.isValid || form.isSubmitting
                      //(form.dirty && !form.isValid) ||
                      //// !form.dirty ||
                      //(!form.dirty && !user) ||
                      //form.isSubmitting
                      ////|| !user
                    }>
                    {'Next'}
                  </FormButton>
                </FormButtonContainer>
              </Form>
            )
          }}
        </Formik>
      </>
    )
  }

  return (
    <Container disableGutters>{!loading && show && renderContent()}</Container>
  )
}
export default RegistrationForm
