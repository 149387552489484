/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from 'aws-amplify'
import { useState, useEffect } from 'react'

import { ErrorProps } from './common'
import { Participant as ParticipantApi, GetParticipantQuery } from '../API'
import { getParticipant } from '../api/queries'

type Participant = Omit<Exclude<ParticipantApi, null>, '__typename'>

const fetchQuery = async (query: any, variables: any) => {
  return new Promise((resolve, reject) => {
    const result = API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    }) as Promise<{
      data: GetParticipantQuery
    }>

    result
      .then(({ data: { getParticipant } }) => {
        resolve(getParticipant)
      })
      .catch((err) => reject(err))
  })
}

interface Props {
  id: string | null | undefined
}

const useParticipantDetails = ({ id }: Props) => {
  const [participant, setParticipant] = useState<Participant>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorProps>()

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      if (didCancel) return
      setLoading(true)

      try {
        const data = (await fetchQuery(getParticipant, {
          id,
        })) as Participant

        setParticipant(data)
      } catch (e) {
        console.log(e)
        setError({
          title: 'Fail to load participant',
          message: '',
        })
      }
      setLoading(false)
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [id])

  return {
    participant,
    apiLoading: loading,
    apiError: error,
  }
}

export default useParticipantDetails
