import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { GridProps } from '@mui/system'
import React from 'react'

interface Props {
  id?: string
  title: string
  text: string | JSX.Element
  Icon?: React.ElementType
  gridProps?: GridProps
}

const TextWithIcon: React.FC<Props> = ({ title, text, Icon, gridProps }) => {
  const theme = useTheme()

  return (
    <Grid container spacing={2} {...gridProps}>
      {Icon && (
        <Icon
          style={{
            marginTop: 20,
            marginLeft: theme.spacing(2),
          }}
        />
      )}
      <Grid item xs container direction="column">
        {title && (
          <Typography
            variant="h6"
            style={{
              whiteSpace: 'pre-line',
              wordBreak: 'break-word',
            }}
          >
            {title}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          style={{
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            ...(title && { display: 'block' }),
          }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default TextWithIcon
