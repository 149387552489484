import { CheckboxProps } from '@mui/material/Checkbox'
import { FormControlProps } from '@mui/material/FormControl'
import { Field, FieldProps } from 'formik'
import React from 'react'

import { getProperty } from '../../../utils/ObjectUtil'
import CheckboxGroup from '../../elements/CheckboxGroup'

export interface Option {
  text: string
  value: string
  image?: JSX.Element
  disabled?: boolean
}
interface FormCheckboxProps {
  onModified: ((
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void) &
    React.FormEventHandler<HTMLDivElement>
}

const FormCheckboxGroup: React.FC<
  Props &
    FormCheckboxProps &
    Partial<FormControlProps> &
    Partial<CheckboxProps> &
    Partial<FieldProps>
> = ({
  name,
  options,
  checkedOptions,
  label,
  onModified,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, handleChange }, // also errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => (
  <CheckboxGroup
    name={name}
    options={options}
    checkedOptions={checkedOptions}
    label={label}
    error={getProperty(touched, field.name) && getProperty(errors, field.name)}
    {...field}
    {...rest}
    onChange={(e) => {
      handleChange(e)
      if (typeof onModified === 'function') onModified(e)
    }}
  />
)

interface Props {
  name: string
  label: string
  options: Option[]
  checkedOptions: string[]
}

const FormikFieldArrayWrapper: React.FC<
  Props & Partial<CheckboxProps> & Partial<FormCheckboxProps>
> = ({ name, ...rest }) => {
  return <Field name={name} component={FormCheckboxGroup} {...rest} />
}

export default FormikFieldArrayWrapper
