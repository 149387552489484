import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import FormButton from './forms/elements/FormButton'
import FormButtonContainer from './forms/elements/FormButtonContainer'
import ShoppingCartBreakdown from './ShoppingCartBreakdown'

const Gap = styled('div')(() => ({
  height: 16,
}))

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shoppingCartItems: any[]
  shoppingCartTotal: number
  loading: boolean
  checkWaiver: boolean
  handleCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  checkAgreeVerify?: boolean
  isVirtualEvent?: boolean
  isFree?: boolean
  handleFreeSubmit?: React.MouseEventHandler<HTMLButtonElement>
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
}

const RegistrationSummary: React.FC<Props> = ({
  shoppingCartItems,
  shoppingCartTotal,
  loading,
  checkWaiver,
  checkAgreeVerify,
  handleCheck,
  isVirtualEvent,
  isFree = false,
  handleFreeSubmit,
  onCancel,
}) => {
  return (
    <Grid item xs={12} sm={6}>
      <ShoppingCartBreakdown
        shoppingCartItems={shoppingCartItems}
        shoppingCartTotal={shoppingCartTotal}
        isFree={isFree}
      />
      {isVirtualEvent && (
        <>
          <Gap />
          <Typography
            variant="subtitle2"
            sx={{ color: 'red', fontStyle: 'italic' }}>
            Race kit will be sent thru courier service and will be paid via COD
          </Typography>
        </>
      )}
      <Gap />
      <Typography variant="body1">
        Read, understand, and accept the following to proceed to checkout.
      </Typography>
      <Gap />
      <Typography variant="body2">
        <Checkbox
          checked={checkWaiver}
          onChange={handleCheck}
          disabled={loading}
          inputProps={{
            'aria-label': 'primary checkbox',
            name: 'waiverCB',
          }}
        />
        Participant/s have read, understood, and accepted the&nbsp;
        <Link href="/waiver" target="_blank">
          waiver
        </Link>
        .
      </Typography>
      {!isVirtualEvent && (
        <>
          <Typography variant="body2">
            <Checkbox
              checked={checkAgreeVerify}
              onChange={handleCheck}
              disabled={loading}
              inputProps={{
                'aria-label': 'primary checkbox',
                name: 'agreeVerify',
              }}
            />
            Participant/s agree to undergo the verification process if
            identified as potential winner .
          </Typography>
        </>
      )}

      <FormButtonContainer wrap={false}>
        <FormButton secondary outlined onClick={onCancel} disabled={loading}>
          Back
        </FormButton>
        {isFree && (
          <FormButton
            primary
            contained
            onClick={handleFreeSubmit}
            disabled={!checkWaiver || loading}>
            Submit
          </FormButton>
        )}
      </FormButtonContainer>
    </Grid>
  )
}

export default RegistrationSummary
