import bcrypt from 'bcryptjs-react'
import * as CryptoJS from 'crypto-js'

export const getProperty = (obj, path) =>
  path
    ? path.split('.').reduce((acc, part) => acc && acc[part], obj)
    : undefined

/*
 * Takes two objects and returns an object with the properties that are different
 * Warning:
 * Naive comparison of nested objects that assumes the same order of properties.
 * Recommended only to use on objects that have gone through one of the models (see src/api/models) which guarantees the order of properties.
 *
 * @param {object}  newObj  The new object that we want to keep properties from.
 * @param {object}  oldObj  The old object that we want to use to remove unchanged properties from newObj.
 *
 * @return  {object}  The object with changed properties.
 */
export const getUpdatedValues = (newObj, oldObj) => {
  const diffedObj = {}
  Object.entries(newObj).forEach(([key, value]) => {
    const oldValue = oldObj[key]
    if (typeof value !== typeof oldValue) {
      diffedObj[key] = value
      return
    }
    if (typeof value === 'object') {
      if (
        Array.isArray(value) &&
        (value.length !== oldValue.length ||
          value.some((val, i) => val !== oldValue[i]))
      ) {
        diffedObj[key] = value
        return
      }
      if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        diffedObj[key] = value
      }
    } else if (value !== oldValue) {
      diffedObj[key] = value
    }
  })
  return diffedObj
}

export const hashText = (text: string, salt: string) => {
  return bcrypt.hashSync(text, salt)
}

export const compareHash = (plainText: string, hashedText: string) => {
  return bcrypt.compareSync(plainText, hashedText)
}

export const encryptText = (text: string) => {
  return CryptoJS.AES.encrypt(text, process.env.REACT_APP_CRYPTO_KEY).toString()
}

export const decryptText = (text: string) => {
  const bytes = CryptoJS.AES.decrypt(text, process.env.REACT_APP_CRYPTO_KEY)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const getName = (fullName: string) => {
  const fullNameArr = fullName.trim().split(', ')
  const lastName = fullNameArr.splice(0, 1)[0]
  return {
    firstName: fullNameArr.join(' '),
    lastName,
  }
}
