import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import React from 'react'

interface Props {
  children: JSX.Element[] | JSX.Element
  centered?: boolean
  horizontal?: boolean
  noPadding?: boolean
}
const Page: React.FC<Props> = ({
  children,
  centered,
  horizontal,
  noPadding,
}) => {
  const theme = useTheme()
  return (
    <Container
      disableGutters
      style={{
        display: 'flex',
        width: '100%',
        ...(!noPadding && {
          padding: theme.spacing(8, 8),
        }),
        flexDirection: 'column',
        ...(centered && {
          alignItems: 'center',
        }),
        ...(horizontal && {
          flexDirection: 'row',
        }),
      }}>
      {children}
    </Container>
  )
}
export default Page
