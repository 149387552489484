/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import * as Yup from 'yup'

import { TSHIRT_SIZES, GENDERS, COUNTRIES } from '../../../constants/models'
import { isCategoryMultisports } from '../../../utils/ApiHelpers'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(customParseFormat)

const validateTandemField = (value: any, testContext: any) => {
  if (testContext.parent.isTandem)
    if (value !== undefined && value !== null) {
      return value && value.toString().trim().length > 0
    } else return false

  return true
}

const validateRequiredConfirmPhone = (value: any, testContext: any) => {
  if (!testContext.parent.isOnsiteRegistration) {
    if (value !== undefined && value !== null)
      return value && value.toString().trim().length > 0
    return false
  }

  return true
}

const validateMatchConfirmPhone = (
  value: any,
  testContext: any,
  matchTo: string,
) => {
  if (!testContext.parent.isOnsiteRegistration) {
    if (value !== undefined && value !== null)
      return value && value.toString().trim().length > 0 && value === matchTo
    return false
  }

  return true
}

const ParticipantSchema = Yup.object().shape({
  categoryId: Yup.string().required('Category ID is required'),
  firstName: Yup.string()
    .max(50, 'First Name must not exceed 50 characters')
    .required('First Name is required'),
  lastName: Yup.string()
    .max(50, 'Last Name must not exceed 50 characters')
    .required('Last Name is required'),
  teamName: Yup.string().max(50, 'Team name must not exceed 50 characters'),
  bibName: Yup.string()
    //   .required('Name on bib is required')
    .max(25, 'Name on bib must not exceed 25 numbers'),
  shirtSize: Yup.mixed()
    .required('T-Shirt size is required')
    .oneOf(Object.keys(TSHIRT_SIZES)),
  birthdate: Yup.mixed()
    .required('Birthdate is required')
    .test(
      'validate-format',
      'Birthdate must be valid date in format YYYY-MM-DD',
      (value) => {
        return dayjs(value as string, 'YYYY-MM-DD', true).isValid()
      },
    )
    .test('validate-future', 'Birthdate must not be a future date', (value) => {
      if (value !== undefined) return dayjs().isAfter(value as Date)
    }),
  nationality: Yup.mixed()
    .required('Nationality is required')
    .oneOf(Object.keys(COUNTRIES)),
  gender: Yup.mixed()
    .required('Gender is required')
    .oneOf(Object.keys(GENDERS)),
  address: Yup.string()
    .max(100, 'Address must not exceed 100 characters')
    .required('Address is required'),
  phoneNumber: Yup.string()
    .max(20, 'Phone must not exceed 20 characters')
    .required('Phone is required'),
  // confirmPhoneNumber: Yup.string()
  //   .max(20, 'Confirm Phone must not exceed 20 characters')
  //   .required('Confirm Phone is required')
  //   .oneOf([Yup.ref('phoneNumber'), null], 'Phone numbers must match'),

  // ONSITE REGISTRATION
  isOnsiteRegistration: Yup.boolean(),
  confirmPhoneNumber: Yup.string()
    .max(20, 'Confirm Phone must not exceed 20 characters')
    .test(
      'required-when-not-onsite-registration',
      'Confirm Phone is required',
      validateRequiredConfirmPhone,
    )
    .test(
      'match-to-phoneNumber',
      'Phone numbers must match',
      (value, testContext) => {
        return validateMatchConfirmPhone(
          value,
          testContext,
          testContext.parent.phoneNumber,
        )
      },
    ),

  email: Yup.string().email('Email Address must be a valid email'),
  // confirmEmail: Yup.string().test(
  //   'not-equal-email',
  //   'Email addresses must match',
  //   (value, testContext) => {
  //     if (
  //       'email' in testContext.parent &&
  //       testContext.parent.email.length > 0
  //     ) {
  //       return testContext.parent.email === value
  //     }
  //     return true
  //   },
  // ),
  emergencyContactName: Yup.string()
    .max(50, 'Emergency Contact Name must not exceed 50 characters')
    .required('Emergency Contact Name is required'),
  emergencyContactPhone: Yup.string()
    .max(20, 'Emergency Contact Number must not exceed 20 characters')
    .required('Emergency Contact Number is required')
    .test(
      'not-equal-phoneNumber',
      'Emergency Contact Number must not be equal to Phone number',
      (value, testContext) => {
        if (
          'phoneNumber' in testContext.parent &&
          testContext.parent.phoneNumber.length > 0
        ) {
          return testContext.parent.phoneNumber !== value
        }
        return false
      },
    ),
  confirmEmergencyContactPhone: Yup.string()
    .max(20, 'Confirm Emergency Contact Number must not exceed 20 characters')
    .test(
      'required-when-not-onsite-registration',
      'Confirm Emergency Contact Number is required',
      validateRequiredConfirmPhone,
    )
    .test(
      'match-to-emergencyContactPhone',
      'Emergency Contact Phone numbers must match',
      (value, testContext) => {
        return validateMatchConfirmPhone(
          value,
          testContext,
          testContext.parent.emergencyContactPhone,
        )
      },
    ),
  // .required('Confirm Emergency Contact Number is required')
  // .oneOf(
  //   [Yup.ref('emergencyContactPhone'), null],
  //   'Emergency Contact Phone numbers must match',
  // ),

  // TRIATHLON/DUATHLON relay categories
  isTriathlon: Yup.boolean(),
  teamType: Yup.string(),
  relayEvent: Yup.array()
    .of(Yup.string())
    .test(
      'required-with-triathlon',
      'Relay Event is required',
      (value, testContext) => {
        if (isCategoryMultisports(testContext.parent))
          return Array.isArray(value) && value.length > 0

        return true
      },
    ),

  // TANDEM categories
  isTandem: Yup.boolean(),
  tandemAgeRange: Yup.mixed(),
  eventEndDateTime: Yup.mixed(),
  tandemName: Yup.string()
    .test(
      'required-with-tandem',
      'Tandem participant name is required',
      validateTandemField,
    )
    .test(
      'validate',
      'Tandem participant name must not exceed 50 characters',
      (value) => {
        if (value !== undefined && value !== null)
          return /^[a-z ,.'-]{1,50}$/i.test(value.toString())
        return true
      },
    ),
  tandemBirthdate: Yup.mixed()
    .test(
      'required-with-tandem',
      'Tandem participant birthdate is required',
      validateTandemField,
    )
    .test(
      'validate-format',
      'Tandem participant birthdate must be valid date in format YYYY-MM-DD',
      (value) => {
        if (value !== undefined && value !== null)
          return dayjs(value as string, 'YYYY-MM-DD', true).isValid()
        return true
      },
    )
    .test({
      name: 'validate-range',
      test: (value: any, testContext: any) => {
        // console.log({ value, testContext })
        if (value !== undefined && value !== null) {
          if (
            'tandemAgeRange' in testContext.parent &&
            'eventEndDateTime' in testContext.parent &&
            testContext.parent.tandemAgeRange.length > 0
          ) {
            const bday = dayjs(
              `${value}T23:59:59+08:00`,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            const eventDay = dayjs(testContext.parent.eventEndDateTime)

            const range = testContext.parent.tandemAgeRange.split('-')

            const ageDuringEvent = eventDay.diff(bday, 'year')
            if (
              ageDuringEvent < parseInt(range[0]) ||
              ageDuringEvent > parseInt(range[1])
            ) {
              return testContext.createError({
                message: `Child birthdate must be in the specified age range ${testContext.parent.tandemAgeRange}`,
                path: testContext.path, // Fieldname
              })
            }
          }
        }
        return true
      },
    })
    .test(
      'validate-future',
      'Child birthdate must not be a future date',
      (value) => {
        if (value !== undefined && value !== null)
          return dayjs().isAfter(value as string)
        return true
      },
    ),
  tandemShirtSize: Yup.mixed()
    .test(
      'required-with-tandem',
      'Tandem participant t-shirt size is required',
      validateTandemField,
    )
    .oneOf(Object.keys(TSHIRT_SIZES)),
  // Additional ID
  additionalId: Yup.string(),

  // firstParticipants
  firstParticipants: Yup.mixed().nullable(),
  firstParticipantInfo: Yup.string().test({
    name: 'validate-length',
    test: (value: any, testContext: any) => {
      // console.log({ value, testContext })
      if (value !== undefined && value !== null) {
        if ('firstParticipants' in testContext.parent) {
          const length =
            testContext.parent.firstParticipants.INFO_LABEL_LENGTH ?? 25

          if (testContext.parent.firstParticipantInfo.trim().length > length) {
            return testContext.createError({
              message: `Must not exceed ${length} characters`,
              path: testContext.path, // Fieldname
            })
          }
        }
      }
      return true
    },
  }),

  // subCategories
  subCategories: Yup.mixed().nullable(),
  subCategory: Yup.string().test({
    name: 'validate-length',
    test: (value: any, testContext: any) => {
      // console.log({ value, testContext })
      if (
        'subCategories' in testContext.parent &&
        Object.keys(testContext.parent.subCategories || {}).length > 0
      ) {
        if (
          'required' in testContext.parent.subCategories &&
          testContext.parent.subCategories.required
        ) {
          if ((value || '').length === 0) {
            return testContext.createError({
              message: `${testContext.parent.subCategories.label} is required`,
              path: testContext.path, // Fieldname
            })
          }
        }

        if (value !== undefined && value !== null) {
          const subCategoryValues =
            testContext.parent.subCategories.options.map((a) => a.name)

          if (!subCategoryValues.includes(value)) {
            return testContext.createError({
              message: `Invalid ${testContext.parent.subCategories.label} selected`,
              path: testContext.path, // Fieldname
            })
          }
        }
      }
      return true
    },
  }),
})

export default ParticipantSchema
