/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import * as Yup from 'yup'

import { COUNTRIES, PAYMENT_MODES } from '../../../constants/models'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)

const validateCardField = (value: any, testContext: any) => {
  if (
    'paymentType' in testContext.parent &&
    testContext.parent.paymentType === PAYMENT_MODES.CARD.key
  )
    if (value !== undefined && value !== null) {
      return value && value.toString().trim().length > 0
    }

  return true
}

const PaymongoBillingSchema = Yup.object().shape({
  paymentType: Yup.string().required('Payment type is required'),
  name: Yup.string().required('Name is required'),
  phone: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Email Address must be a valid email'),
  addressLine1: Yup.string().required('Address is required'),
  addressLine2: Yup.string(),
  state: Yup.string(),
  postalCode: Yup.string().required('Postal Code is required'),
  city: Yup.string().required('City is required'),
  country: Yup.string()
    .required('Country is required')
    .oneOf(Object.keys(COUNTRIES)),
  privacyPolicy: Yup.boolean().oneOf(
    [true],
    'You must accept the privacy policy',
  ),

  // require when type is card
  // https://www.regular-expressions.info/creditcard.html
  cardNumber: Yup.mixed()
    .test('require-with-card', 'Card number is required', validateCardField)
    .test('validate', 'Card number is invalid', (value) => {
      if (value !== undefined && value !== null) {
        return /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/.test(
          value.toString().trim().replaceAll('-', '').replaceAll(' ', ''),
        )
      }
      return true
    }),
  expMonth: Yup.mixed()
    .test(
      'require-with-card',
      'Card expiry month is required',
      validateCardField,
    )
    .test('validate', 'Expiry month must be from 01-12', (value) => {
      if (value !== undefined && value !== null)
        return /^((0[1-9])|(1[0-2]))$/.test(value.toString())
      return true
    }),
  expYear: Yup.mixed()
    .test(
      'require-with-card',
      'Card expiry year is required',
      validateCardField,
    )
    .test('validate', 'Expiry year must be exactly 2 digits', (value) => {
      if (value !== undefined && value !== null)
        return /^[0-9]{2}$/.test(value.toString())
      return true
    })
    .test('future-date', 'Expiry must be future date', (value, testContext) => {
      if (
        'expMonth' in testContext.parent &&
        testContext.parent.expMonth !== undefined &&
        testContext.parent.expMonth !== null
      )
        if (value !== undefined && value !== null) {
          return dayjs(
            `20${value}-${testContext.parent.expMonth}-01`,
            'YYYY-MM-DD',
          ).isSameOrAfter(dayjs())
        }

      return true
    }),
  cvc: Yup.mixed()
    .test('require-with-card', 'Card cvc is required', validateCardField)
    .test('validate', 'CVV/CVC must be 3 or 4 digits', (value) => {
      if (value !== undefined && value !== null)
        return /^[0-9]{3,4}$/.test(value.toString())
      return true
    }),
})

export default PaymongoBillingSchema
