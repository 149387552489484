export const color = (primary, secondary, defaultValue = null) => {
  if (primary) {
    return 'primary'
  }
  if (secondary) {
    return 'secondary'
  }
  return defaultValue
}

export const variant = (contained, outlined, text, defaultValue = null) => {
  if (contained) {
    return 'contained'
  }
  if (outlined) {
    return 'outlined'
  }
  if (text) {
    return 'text'
  }
  return defaultValue
}
