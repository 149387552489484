import EmailIcon from '@mui/icons-material/Email'
import FacebookIcon from '@mui/icons-material/Facebook'
import { Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'
import { styled, useTheme } from '@mui/material/styles'
import React from 'react'

import Section from '../Section'

const Link = styled(MuiLink)(
  ({ theme }) => `
  text-decoration: none;
  padding: 0 8px;
  color: ${theme.palette.secondary.main};
  :hover {
    color: ${theme.palette.primary.main};
  }
`,
)

const LogoLink = styled(MuiLink)`
  padding: 0px;
`

interface IconLinkProps {
  href: string
  label: string | JSX.Element
  icon?: JSX.Element
  target?: string
}
const IconLink: React.FC<IconLinkProps> = (props) => {
  return (
    <Link
      href={props.href}
      sx={{ display: 'flex', alignItems: 'center' }}
      target={props.target || ''}>
      {props.icon}
      {props.label}
    </Link>
  )
}

const Footer: React.FC = () => {
  const theme = useTheme()
  return (
    <Section secondary noMargin>
      <Container>
        <Grid
          container
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          })}>
          <Grid
            item
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'row',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            })}>
            <Grid
              item
              flexDirection="column"
              sx={{
                [theme.breakpoints.down('sm')]: {
                  alignSelf: 'center',
                },
              }}>
              <LogoLink
                href={`/`}
                sx={(theme) => ({
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                  [theme.breakpoints.up('sm')]: {
                    ml: 2,
                    mr: 4,
                  },
                })}
                underline="none">
                <img
                  height={65}
                  src={window.location.origin + '/images/megatechph_icon.png'}
                  alt="MEGATechPH"
                />
              </LogoLink>
            </Grid>
            <Grid
              item
              container
              sx={(theme) => ({
                display: 'flex',
                alignSelf: 'center',
                flexDirection: 'column',
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'center',
                },
              })}>
              <Grid item>
                <IconLink
                  href="mailto:support@megatechph.com"
                  icon={<EmailIcon sx={{ mr: 1 }} />}
                  label="support@megatechph.com"
                />
              </Grid>
              <Grid item>
                <IconLink
                  href="https://www.facebook.com/profile.php?id=100089710614257"
                  icon={<FacebookIcon sx={{ mr: 1 }} />}
                  label="MEGATechPH FB page"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                marginTop: 3,
              },
            })}>
            <Grid item>
              <IconLink
                href="https://tracx.events/en/events/1251"
                target="_blank"
                label={
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}>
                    Live Tracker
                  </Typography>
                }
              />
            </Grid>
            <Grid item>
              <IconLink
                href="/events"
                label={
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}>
                    Upcoming Events
                  </Typography>
                }
              />
            </Grid>
            <Grid item>
              <IconLink
                href="/pastEvents"
                label={
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}>
                    Past Events
                  </Typography>
                }
              />
            </Grid>
            <Grid item>
              <IconLink
                href="http://results.megatechph.com/StartPage.aspx?CId=20402"
                target="_blank"
                label={
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}>
                    Results
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}
export default Footer
