import { useCallback, useMemo, useReducer } from 'react'

/*
 * This hook provides a nextToken utility.
 *
 * @return  {object}  The return object contains the following properties:
 * - nextToken        - the next token to use
 * - setNewToken      - call when adding a new token, eg setNewToken(token)
 * - startUsingToken  - call when you start using a token, eg startUsingToken(token)
 * - onTokenSuccess   - call when a request using a token succeeds, eg onTokenSuccess(token)
 * - onTokenFail      - call when a request using a token fails, eg onTokenFail(token)
 * - tokensLoading    - returns whether any of the tokens are currently being used
 *
 * Use the hook like:
 * const { nextToken, setNewToken, startUsingToken, onTokenSuccess, onTokenFail, tokensLoading } = useNextToken();
 */

interface Token {
  used: boolean
  loading: boolean
}

const useNextToken = () => {
  const [tokens, dispatch] = useReducer(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any, action: { type: string; token: string }) => {
      switch (action.type) {
        case 'newToken':
          return {
            ...state,
            [action.token]: {
              used: false,
              loading: false,
            },
          }
        case 'useToken':
          return {
            ...state,
            [action.token]: {
              used: false,
              loading: true,
            },
          }
        case 'usedTokenSuccess':
          return {
            ...state,
            [action.token]: {
              used: true,
              loading: false,
            },
          }
        case 'usedTokenFail':
          return {
            ...state,
            [action.token]: {
              used: false,
              loading: false,
            },
          }
        default:
          throw new Error('Unexpected action.')
      }
    },
    {},
  )

  const availableTokens = (
    Object.entries(tokens) as unknown as [string, Token][]
  ).find(([, value]) => !value.used && !value.loading)
  const nextAvailableToken =
    Array.isArray(availableTokens) &&
    availableTokens.length > 0 &&
    availableTokens[0]

  const setNewToken = useCallback((token: string) => {
    dispatch({ type: 'newToken', token })
  }, [])

  const startUsingToken = useCallback((token: string) => {
    dispatch({ type: 'useToken', token })
  }, [])

  const onTokenSuccess = useCallback((token: string) => {
    dispatch({ type: 'usedTokenSuccess', token })
  }, [])

  const onTokenFail = useCallback((token: string) => {
    dispatch({ type: 'usedTokenFail', token })
  }, [])

  const tokensLoading = useMemo(
    () =>
      (Object.values(tokens) as unknown as Token[]).some(
        (t: Token) => t.loading,
      ),
    [tokens],
  )

  return {
    nextToken: nextAvailableToken || undefined,
    setNewToken,
    startUsingToken,
    onTokenSuccess,
    onTokenFail,
    tokensLoading,
  }
}

export default useNextToken
