import React from 'react'
import { HashLink, HashLinkProps } from 'react-router-hash-link'

interface Props extends HashLinkProps {
  children?: JSX.Element | JSX.Element[]
  right?: boolean
  underline?: boolean
}

const CustomHashLink: React.FC<Props & HashLinkProps> = ({
  children,
  right = false,
  underline = false,
  ...rest
}) => {
  return (
    <HashLink
      style={{
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
        },
        ...(right && { alignSelf: 'flex-end' }),
        ...(underline && {
          '&:hover': {
            textDecoration: 'underline',
          },
        }),
      }}
      {...rest}
    >
      {children}
    </HashLink>
  )
}

export default CustomHashLink
