/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from 'aws-amplify'

export const addNextToken = (token, config) => {
  if (!token) {
    return config
  }

  if (!Object.keys(config)) {
    return {
      headers: {
        'query-token': token,
      },
    }
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      'query-token': token,
    },
  }
}

// Converts the params to string
//
// ```
// params = {
//   amountAuthorised: {
//     gte: 250,
//     lte: 500,
//   },
//   transactionDate: {
//     after: '2019-09-20',
//   },
// },
// ```
//
// output:
// amountAuthorised[gte]=250&amountAuthorised[lte]=500&transactionDate[after]=2019-09-20
export const generateFilter = (params) => {
  const operators = ['eq', 'neq', 'lte', 'gte', 'before', 'after']

  let filters = ''
  Object.keys(params).forEach((param) => {
    Object.keys(params[param]).forEach((op) => {
      if (operators.includes(op)) {
        if (filters.length > 0) filters += '&'
        filters += `${param}[${op}]=${params[param][op]}`
      }
    })
  })

  return filters
}

// Converts the queryParams to string
//
// ```
// params = {
//   q: ['locationName:Wayne', 'mshStatus:Activated'],
//   sort: ['+terminalId', '-transactionDate'],
//   filter: {
//     amountAuthorised: {
//       gte: 250,
//       lte: 500,
//     },
//     transactionDate: {
//       after: '2019-09-20',
//     },
//   },
// }
// ```
//
// output:
// ?q=locationName:Wayne,mshStatus:Activated&sort=+terminalId,-transactionDate&transactionDate[gte]=2019-09-01&transactionDate[lte]=2019-09-30
export const generateQuery = (params) => {
  let queryString = ''

  // convert `filter` to string using `generateFilter()`
  if ('filter' in params) {
    // eslint-disable-next-line no-param-reassign
    params.filter = generateFilter(params.filter)
  }

  const items = Object.keys(params)
  if (items.length > 0) {
    items.forEach((key) => {
      if (queryString.length > 0) queryString += '&'

      if (key === 'filter') queryString += `${params[key]}`
      else if (Array.isArray(params[key]))
        queryString += `${key}=${params[key].join()}`
      else queryString += `${key}=${params[key]}`
    })
  }

  if (queryString.length > 0) queryString = `?${queryString}`

  return queryString
}

const map = (f) => (arr) => arr.map(f)
const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((y, f) => f(y), x)
const dissoc =
  (prop) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ [prop]: _, ...obj }) =>
    obj
const mapObj = (f) => (obj) =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {})
const replaceEmptyStringWithNull = (x) => (x === '' ? null : x)

export const prepareBatchData = map(
  pipe(dissoc('id'), mapObj(replaceEmptyStringWithNull)),
)

export const fetchQuery = async <T>(
  name: string,
  query: any,
  variables: any,
) => {
  return new Promise((resolve, reject) => {
    const result = API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    }) as Promise<{
      data: T
    }>

    result
      .then(({ data }) => {
        resolve({
          items: data[name].items,
          nextToken: data[name].nextToken,
        })
      })
      .catch((err) => reject(err))
  })
}

export const doMutation = async <T>(
  name: string,
  query: any,
  variables: any,
) => {
  return new Promise((resolve, reject) => {
    const result = API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    }) as Promise<{
      data: T
      errors: any
    }>

    result
      .then(({ data, errors }) => {
        resolve({ data: data[name], errors })
      })
      .catch((err) => reject(err))
  })
}

/**
 * Wait for the given milliseconds
 * @param {number} milliseconds The given time to wait
 * @returns {Promise} A fulfilled promise after the given time has passed
 */
const waitFor = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

/**
 * Execute a promise and retry with exponential backoff
 * based on the maximum retry attempts it can perform
 * @param {Promise} promise promise to be executed
 * @param {function} onRetry callback executed on every retry
 * @param {number} maxRetries The maximum number of retries to be attempted
 * @returns {Promise} The result of the given promise passed in
 */
export const retry = (promise, onRetry, maxRetries) => {
  // Notice that we declare an inner function here
  // so we can encapsulate the retries and don't expose
  // it to the caller. This is also a recursive function
  let lastResponse = undefined
  async function retryWithBackoff(retries) {
    try {
      // Make sure we don't wait on the first attempt
      if (retries > 0) {
        // Here is where the magic happens.
        // on every retry, we exponentially increase the time to wait.
        // Here is how it looks for a `maxRetries` = 4
        // (2 ** 1) * 100 = 200 ms
        // (2 ** 2) * 100 = 400 ms
        // (2 ** 3) * 100 = 800 ms
        const timeToWait = 2 ** retries * 100
        console.log(`waiting for ${timeToWait}ms...`)
        await waitFor(timeToWait)
      }
      lastResponse = await promise(retries)
      return lastResponse
    } catch (e) {
      // only retry if we didn't reach the limit
      // otherwise, let the caller handle the error
      if (retries < maxRetries - 1) {
        onRetry()
        return retryWithBackoff(retries + 1)
      } else {
        console.warn('Max retries reached. Bubbling the error up')
        // throw e
        return lastResponse
      }
    }
  }

  return retryWithBackoff(0)
}
