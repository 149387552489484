/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from '@aws-amplify/ui-react'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import useFormEventDetails from '../../hooks/useFormEventDetails'
import EmptyState from '../EmptyState'
import formUserFormContent from '../forms/contents/FormParticipants/UserFormContent'
import Form from '../forms/elements/Form'
import FormButton from '../forms/elements/FormButton'
import FormButtonContainer from '../forms/elements/FormButtonContainer'
import FormGrid from '../forms/elements/FormGrid'
import formUserFormSchema from '../forms/schemas/FormParticipants/UserFormSchema'

interface Props {
  show: boolean
  user?: any
  updateData: (values) => any
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  editing?: boolean
  setLoading?: (value: boolean) => void
  displayError?: (values: any) => void
}

const User: React.FC<Props> = ({
  show,
  user: initUser,
  updateData,
  editing,
  setLoading,
  displayError,
}) => {
  const theme = useTheme()

  // const initParticipant = {
  //   participantId: '',
  //   firstName: '',
  //   lastName: '',
  //   civilStatus: '',
  //   paymentMode: ONSITE_PAYMENT_MODES.CASH.toUpperCase(),
  //   tshirtCount: [{ size: '', quantity: 1, color: 'BLUE' }],
  //   atdCb: false,
  // }

  const { slug } = useParams()
  const {
    event,
    apiLoading: loading,
    apiError,
  } = useFormEventDetails({
    slug,
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  const renderContent = () => {
    if (apiError)
      return (
        <EmptyState header={apiError.title} text={apiError.message}>
          <Link href="/events">
            <Button variant="contained" color="secondary">
              BACK TO EVENTS
            </Button>
          </Link>
        </EmptyState>
      )
    if (!event)
      return (
        <EmptyState
          header="Hmm… We cannot find the event you are looking for"
          text=""
          // text="Click the button in the top right corner to add your first merchant"
        />
      )

    return (
      <Container
        sx={{
          paddingTop: 4,
          marginBottom: theme.spacing(10),
        }}>
        <Formik
          initialValues={initUser}
          validateOnMount={editing}
          enableReinitialize
          validationSchema={formUserFormSchema}
          onSubmit={async (values, actions) => {
            setLoading(true)
            const [, error] = await updateData(values)
            if (error) {
              displayError({ title: error.title, message: error.message })
            }
            setLoading(false)
            actions.setSubmitting(false)
          }}>
          {(form) => {
            return (
              <Form>
                <>
                  <FormGrid
                    content={formUserFormContent({
                      formDisabled: form.isSubmitting,
                    })}
                  />
                </>
                <FormButtonContainer
                  wrap={false}
                  style={{ margin: theme.spacing(2, 0) }}>
                  <></>
                  <FormButton
                    submit
                    primary
                    outlined
                    disabled={
                      !form.isValid || form.isSubmitting
                      //// !form.isValid || form.isSubmitting
                      //(form.dirty && !form.isValid) ||
                      //// !form.dirty ||
                      //(!form.dirty && !user) ||
                      //form.isSubmitting
                      ////|| !user
                    }>
                    {'Next'}
                  </FormButton>
                </FormButtonContainer>
              </Form>
            )
          }}
        </Formik>
      </Container>
    )
  }

  return <>{!loading && show && renderContent()}</>
}
export default User
