/* eslint-disable react/react-in-jsx-scope */
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'

import { COUNTRIES, PAYMENT_MODES } from '../../../constants/models'
import FormSelectInput from '../elements/FormSelectInput'
import FormTextInput from '../elements/FormTextInput'

const cardForm = ({ formDisabled }) => ({
  id: 'paymongo-card-form-details',
  name: 'Payment Information',
  inputs: [
    {
      id: 'cardNumber',
      size: 12,
      field: (
        <FormTextInput
          name="cardNumber"
          label="Card Number"
          required
          autoComplete="new-name"
          disabled={formDisabled}
        />
      ),
    },
    {
      id: 'expMonth',
      size: { sm: 12, md: 4 },
      field: (
        <FormTextInput
          name="expMonth"
          label="Expiry Month"
          placeholder="MM"
          required
          autoComplete="new-expMonth"
          disabled={formDisabled}
          inputProps={{ maxLength: 2 }}
        />
      ),
    },
    {
      id: 'expYear',
      size: { sm: 12, md: 4 },
      field: (
        <FormTextInput
          name="expYear"
          label="Expiry Year"
          placeholder="YY"
          required
          autoComplete="new-expYear"
          disabled={formDisabled}
          inputProps={{ maxLength: 2 }}
        />
      ),
    },
    {
      id: 'cvc',
      size: { sm: 12, md: 4 },
      field: (
        <FormTextInput
          name="cvc"
          label="CVV/CVC"
          required
          autoComplete="new-cvc"
          disabled={formDisabled}
          inputProps={{ maxLength: 4 }}
        />
      ),
    },
  ],
})

const PaymongoBillingFormContent = ({
  values,
  formDisabled,
  showCard,
  handleChange,
  handleCheckPolicy,
}) => [
  {
    id: 'paymongo-payment-type-details',
    name: 'Payment Type',
    inputs: [
      {
        id: 'paymentType',
        size: 12,
        field: (
          <RadioGroup
            name="paymentType"
            value={values.paymentType}
            onChange={handleChange}>
            {Object.keys(PAYMENT_MODES).map((mode: string) => {
              return (
                <FormControlLabel
                  key={`paymentOptions-${mode}`}
                  value={mode}
                  disabled={formDisabled}
                  control={<Radio />}
                  label={
                    <div style={{ display: 'flex' }}>
                      <img
                        height={40}
                        src={`${window.location.origin}/images/${PAYMENT_MODES[mode].image}`}
                        alt={`Paymongo ${PAYMENT_MODES[mode].name}`}
                      />
                      <Typography
                        sx={{ lineHeight: '40px', marginLeft: '10px' }}>
                        {PAYMENT_MODES[mode].name}
                      </Typography>
                    </div>
                  }
                />
              )
            })}
          </RadioGroup>
        ),
      },
    ],
  },
  {
    id: 'paymongo-billing-form-details',
    name: 'Customer Information',
    inputs: [
      {
        id: 'name',
        size: 12,
        field: (
          <FormTextInput
            name="name"
            label="Name"
            required
            autoComplete="new-name"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'email',
        size: 12,
        field: (
          <FormTextInput
            name="email"
            label="Email Address"
            required
            autoComplete="new-email"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'phone',
        size: 12,
        field: (
          <FormTextInput
            name="phone"
            label="Phone Number"
            required
            autoComplete="new-phone"
            disabled={formDisabled}
          />
        ),
      },
    ],
  },
  {
    id: 'paymongo-address-form-details',
    name: 'Billing Address',
    inputs: [
      {
        id: 'addressLine1',
        size: 12,
        field: (
          <FormTextInput
            name="addressLine1"
            label="Address"
            required
            autoComplete="new-addressLine1"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'state',
        size: 6,
        field: (
          <FormTextInput
            name="state"
            label="State"
            autoComplete="new-city"
            disabled={formDisabled}
          />
        ),
      },

      {
        id: 'city',
        size: 6,
        field: (
          <FormTextInput
            name="city"
            label="City"
            required
            autoComplete="new-city"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'postalCode',
        size: { sm: 12, md: 6 },
        field: (
          <FormTextInput
            name="postalCode"
            label="Postal Code"
            required
            autoComplete="new-postalCode"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'country',
        size: { sm: 12, md: 6 },
        field: (
          <FormSelectInput
            name="country"
            label="Country"
            required
            autoComplete="new-country"
            disabled={formDisabled}
            options={Object.entries(COUNTRIES).map(([key, value]) => ({
              value: key,
              text: value,
            }))}
          />
        ),
      },
    ],
  },
  { ...(showCard && cardForm({ formDisabled })) },
  {
    id: 'paymongo-privacy-policy-details',
    name: 'Privacy Policy',
    inputs: [
      {
        id: 'privacyPolicy',
        size: 12,
        field: (
          <Typography variant="body2">
            <Checkbox
              checked={values.privacyPolicy}
              onChange={handleCheckPolicy}
              disabled={formDisabled}
              inputProps={{
                'aria-label': 'primary checkbox',
                name: 'privacyPolicy',
              }}
            />
            {`I have read and agreed to PayMongo's `}
            <Link href="https://www.paymongo.com/privacy" target="_blank">
              Privacy Policy
            </Link>
            .
          </Typography>
        ),
      },
    ],
  },
]

export default PaymongoBillingFormContent
