import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import LinearProgress from '@mui/material/LinearProgress'
import { Breakpoint, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

interface Props {
  open: boolean
  onClose?: (event: React.MouseEvent<HTMLElement>) => void
  title?: JSX.Element | string
  content?: JSX.Element
  buttons?: JSX.Element
  loading?: boolean
  thin?: boolean
  fullWidth?: boolean
  maxWidth?: Breakpoint
  overflow?: string
}

const CustomDialog: React.FC<Props> = ({
  open,
  onClose,
  title,
  content,
  buttons,
  loading,
  thin,
  fullWidth = false,
  maxWidth = 'sm',
  overflow = 'hidden',
}) => {
  const theme = useTheme()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={maxWidth}
      fullWidth={fullWidth}>
      {title && (
        <DialogTitle
          style={{
            padding: theme.spacing(4),
          }}>
          <Typography
            variant="body1"
            style={{
              fontSize: '2.125rem',
              lineHeight: 1.235,
              letterSpacing: '0.00735em',
            }}>
            {title}
          </Typography>
        </DialogTitle>
      )}
      {loading && (
        <LinearProgress
          style={{
            marginBottom: -4,
            ...(title && {
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(1),
            }),
          }}
        />
      )}
      {content && (
        <DialogContent
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: {
              padding: theme.spacing(4),
            },
            display: 'flex',
            flexDirection: 'column',
            ...(thin && {
              padding: 0,
            }),
            overflow,
          })}>
          {content}
        </DialogContent>
      )}
      {buttons && (
        <DialogActions
          style={{
            padding: theme.spacing(0, 4, 2),
          }}>
          {buttons}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default CustomDialog
