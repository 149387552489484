import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Divider from '@mui/material/Divider'
import MuiLink from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Div = styled('div')(
  () => `
    display: inline-block 
`,
)

const MenuItemDiv = styled('div')(
  () => `
    display: block 
`,
)

const Link = styled(MuiLink)(
  ({ theme }) => `
  text-transform: uppercase; 
  font-weight: bold;
  padding: 0 8px;
  text-decoration: none;
  display: inline-flex;
  color: ${theme.palette.primary.main};
  :hover {
    color: ${theme.palette.secondary.main};
  }
`,
)

const MenuLink = styled(MuiLink)(
  ({ theme }) => `
  width: 100%;
  text-transform: uppercase; 
  text-decoration: none;
  color: ${theme.palette.grey[900]};
  :hover {
    color: ${theme.palette.secondary.main};
  }
`,
)

interface MenuItem {
  name: string
  to: string
  target?: string
  divider?: boolean
}
interface Props {
  name: string
  to?: string
  items?: MenuItem[]
}

const HeaderNavMenu: React.FC<Props> = ({ name, to, items }) => {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (to: string | undefined) => {
    setAnchorEl(null)
    if (to !== undefined) navigate(to, { replace: true })
  }

  return (
    <Div key={`basic-button-${name}`}>
      <Link
        id={`basic-button-${name}`}
        aria-controls={items?.length > 0 && open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={items?.length > 0 && open ? 'true' : undefined}
        {...(to && {
          href: to,
        })}
        {...(items?.length > 0 && {
          onClick: (e) => {
            handleClick(e)
            e.preventDefault()
          },
        })}>
        {name}
        {items?.length > 0 && <ArrowDropDownIcon />}
      </Link>
      {items && (
        <Menu
          id={`basic-menu${name}`}
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(undefined)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {items?.map((item) => (
            <MenuItemDiv key={item.to}>
              {!!item.divider && <Divider />}
              <MenuItem>
                <MenuLink
                  {...(item.target && {
                    href: item.to,
                    target: item.target,
                  })}
                  {...(!item?.target && {
                    onClick: (e) => {
                      handleClose(item.to)
                      e.preventDefault()
                    },
                  })}>
                  {item.name}
                </MenuLink>
              </MenuItem>
            </MenuItemDiv>
          ))}
        </Menu>
      )}
    </Div>
  )
}

export default HeaderNavMenu
