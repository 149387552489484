/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useState, useEffect } from 'react'

import { ErrorProps } from './common'
import { FormParticipant as FormParticipantApi } from '../API'
import { formParticipantsByParticipantIdAndCreatedAt } from '../api/queries'
import { fetchQuery } from '../utils/ApiUtils'

dayjs.extend(utc)
dayjs.extend(timezone)

type FormParticipant = Omit<Exclude<FormParticipantApi, null>, '__typename'>

interface Props {
  eventId: string
  participantId: string
}
const useFormParticipantDetails = ({ eventId, participantId }: Props) => {
  const [participant, setParticipant] = useState<FormParticipant>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorProps>()

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      if (didCancel) return
      setParticipant(null)
      setLoading(true)

      try {
        const { items } = (await fetchQuery(
          'formParticipantsByParticipantIdAndCreatedAt',
          formParticipantsByParticipantIdAndCreatedAt,
          {
            participantId,
            filter: {
              formEventId: { eq: eventId },
            },
            // limit: 1,
            // createdAt: {
            //   lt: dayjs().tz('Asia/Manila'),
            // },
            sortDirection: 'DESC',
          },
        )) as unknown as { items: FormParticipant[] }

        if (items.length > 0) {
          const item = items[0]
          if (item.participantId === participantId) {
            item.tshirtCount = JSON.parse(JSON.parse(item.tshirtCount))
            setParticipant(item)
          }
        }
      } catch (e) {
        console.log(e)
        setError({
          title: 'Fail to retrieve data',
          message: '',
        })
      }
      setLoading(false)
    }

    if (participantId) fetchData()

    return () => {
      didCancel = true
    }
  }, [eventId, participantId])

  return {
    participant,
    apiLoading: loading,
    apiError: error,
  }
}

export default useFormParticipantDetails
