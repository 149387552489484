import Button, { ButtonProps } from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import React from 'react'

import { color, variant } from '../../utils/MUIUtils'

interface Props {
  submit?: boolean
  primary?: boolean
  secondary?: boolean
  outlined?: boolean
  contained?: boolean
  text?: boolean
}
const StyledButton: React.FC<Props & Partial<ButtonProps>> = ({
  onClick,
  submit,
  children,
  primary,
  secondary,
  outlined,
  contained,
  text,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <Button
      type={submit ? 'submit' : 'button'}
      color={color(primary, secondary, 'primary')}
      sx={[
        {
          padding: theme.spacing(2, 4),
          '&:disabled': {
            opacity: 0.4,
            color: `${
              contained
                ? theme.palette.grey
                : primary
                ? theme.palette.primary.main
                : theme.palette.secondary.main
            }`,
            borderColor: `${
              primary
                ? theme.palette.primary.main
                : theme.palette.secondary.main
            }`,
            backgroundColor: `${
              contained
                ? primary
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main
                : 'transparent'
            }`,
          },
          lineHeight: '16px',
        },
      ]}
      variant={variant(contained, outlined, text, 'text')}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default StyledButton
