/* eslint-disable @typescript-eslint/no-explicit-any */

import Card from '@mui/material/Card'
import { Formik } from 'formik'
import React from 'react'

import paymongoCheckoutFormContent from './forms/contents/PaymongoCheckoutFormContent'
import Form from './forms/elements/Form'
import FormButton from './forms/elements/FormButton'
import FormGrid from './forms/elements/FormGrid'
import paymongoCheckoutSchema from './forms/schemas/PaymongoCheckoutSchema'
import { formatCurrency } from '../constants/formats'
import { PAYMENT_MODES } from '../constants/models'

interface Props {
  shoppingCartTotal: number
  initialValues?: any
  updateData?: (values: any) => any
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  editing?: boolean
  setLoading: (value1: boolean) => void
  displayError?: (values: any) => void
  setPaymentMode: (value: string) => void
}

const PaymongoCheckoutForm: React.FC<Props> = ({
  shoppingCartTotal,
  initialValues,
  updateData,
  editing = false,
  setLoading,
  displayError,
  setPaymentMode,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount={editing}
      validateOnBlur={false}
      validationSchema={paymongoCheckoutSchema}
      onSubmit={async (values, actions) => {
        setLoading(true)
        const [, error] = await updateData(values)
        if (error) {
          displayError({ title: error.title, message: error.message })
        } else {
          actions.resetForm()
        }
        setLoading(false)
        actions.setSubmitting(false)
      }}
    >
      {(form) => {
        const updateField = (key, value) => {
          form.setFieldValue(key, value)
          form.setTouched({
            ...form.touched,
            [key]: initialValues[key] !== value,
          })
        }

        return (
          <Form id="paymongo-checkout-form">
            <Card>
              <FormGrid
                content={paymongoCheckoutFormContent({
                  values: form.values,
                  formDisabled: form.isSubmitting,
                  showCard: form.values.paymentType
                    ? form.values.paymentType === PAYMENT_MODES.CARD.key
                    : initialValues.paymentType === PAYMENT_MODES.CARD.key,
                  handleChange: (
                    event: React.ChangeEvent<HTMLInputElement>,
                    value: string,
                  ) => {
                    setPaymentMode(value)
                    updateField('paymentType', value)
                  },
                  handleCheckPolicy: (
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => {
                    updateField('privacyPolicy', checked)
                  },
                })}
              />
              <div style={{ padding: '20px' }}>
                <FormButton
                  submit
                  primary
                  contained
                  fullWidth
                  disabled={
                    (form.dirty && !form.isValid) ||
                    !form.dirty ||
                    form.isSubmitting
                  }
                >
                  {`Pay ${formatCurrency(shoppingCartTotal)}`}
                </FormButton>
              </div>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

export default PaymongoCheckoutForm
