/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'

const UploadResultSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  eventName: Yup.string(),
  categoryName: Yup.string(),
  distance: Yup.number(),
  virtualProgress: Yup.number()
    .required('Distance covered is required')
    .test({
      name: 'validate-min',
      test: (value: any, testContext: any) => {
        if (value !== undefined && value !== null) {
          if (value < testContext.parent.category.distance) {
            return testContext.createError({
              message: `Distance covered must be greater than or equal to ${testContext.parent.category.distance} km`,
              path: testContext.path, // Fieldname
            })
          }
        }
        return true
      },
    }),
})

export default UploadResultSchema
