/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { uniq } from 'lodash'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { default as CustomButton } from './elements/Button'
import Table from './elements/Table'
import FormButton from './forms/elements/FormButton'
import FormButtonContainer from './forms/elements/FormButtonContainer'
import ParticipantForm from './forms/ParticipantForm'
import SizeChartDialog from './SizeChartDialog'
import { Event as EventApi } from '../API'
import { formatDate } from '../constants/formats'
import {
  DEFAULT_PARTICIPANT_COUNT,
  TEAM_TYPES,
  TSHIRT_SIZES,
} from '../constants/models'
import { CartContext } from '../contexts/CartContext'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import { getBonusCategory, isCategoryMultisports } from '../utils/ApiHelpers'

interface Props {
  show: boolean
  event: Event
  // categoryId provided for teamType categories
  categoryId?: string
  isOnsiteRegistration?: boolean
  handlePrimaryAction: React.MouseEventHandler<HTMLButtonElement>
  primaryActionText?: string
  handleSecondaryAction: React.MouseEventHandler<HTMLButtonElement>
  secondaryActionText?: string
}
type Event = Omit<Exclude<EventApi, null>, '__typename'>

const participantTableHeaders = ({
  removeProduct,
  setSelectedParticipant,
  setShowParticipantDialog,
  isOnsiteRegistration,
  subCategories,
}) => [
  {
    idKey: 'firstName',
    key: 'firstName',
    name: 'First Name',
  },
  {
    idKey: 'lastName',
    key: 'lastName',
    name: 'Last Name',
  },
  {
    idKey: 'birthdate',
    key: 'birthdate',
    name: 'Birthdate',
    format: {
      type: 'raw',
      parser: (rawValue) =>
        rawValue ? formatDate(rawValue, 'YYYY-MM-DD') : '',
    },
  },
  {
    idKey: 'gender',
    key: 'gender',
    name: 'Gender',
  },
  ...(isOnsiteRegistration
    ? [
        {
          idKey: 'email',
          key: 'email',
          name: 'Email',
        },
      ]
    : []),
  {
    idKey: 'categoryName',
    key: 'categoryName',
    name: 'Category',
  },
  ...(subCategories
    ? [
        {
          idKey: 'subCategory',
          key: 'subCategory',
          name: subCategories.label,
        },
      ]
    : []),
  {
    idKey: 'shirtSize',
    key: 'shirtSize',
    name: 'Shirt size',
    format: {
      type: 'raw',
      parser: (rawValue) => (rawValue ? TSHIRT_SIZES[rawValue] : ''),
    },
  },
  {
    idKey: 'editOption',
    key: 'editOption',
    name: 'Actions',
    format: {
      type: 'editIcon',
      onClick: (row) => {
        setSelectedParticipant({ ...row, birthdate: dayjs(row.birthdate) })
        setShowParticipantDialog(true)
      },
      ariaLabel: 'Edit participant',
    },
    collapsed: true,
  },
  {
    key: 'deleteOption',
    name: '',
    format: {
      type: 'deleteIcon',
      onClick: (row) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you want to delete participant?')) {
          removeProduct({ id: `${row.id}` })
        }
      },
      ariaLabel: 'Remove participant',
    },
    collapsed: true,
  },
]

const StepParticipant: React.FC<Props> = ({
  show,
  event,
  // categoryId provided for teamType categories
  categoryId,
  isOnsiteRegistration = false,
  handlePrimaryAction,
  primaryActionText = 'Next',
  handleSecondaryAction,
  secondaryActionText = 'Back',
}) => {
  const [showSize, setShowSize] = useState(false)
  const {
    cartUser,
    cartItems,
    itemCount,
    addProduct,
    updateProduct,
    removeProduct,
  } = useContext(CartContext)
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)
  const [categories, setCategories] = useState([])

  const [isTeam, setIsTeam] = useState(false)
  const [maxItemCount, setMaxItemCount] = useState(DEFAULT_PARTICIPANT_COUNT)

  const [selectedRelayEvent, setSelectedRelayEvent] = useState([])
  const [bonusCategory, setBonusCategory] = useState('')
  const [participants, setParticipants] = useState([])
  const [selectedParticipant, setSelectedParticipant] = useState({})
  const [showParticipantDialog, setShowParticipantDialog] = useState(false)

  const getTotalParticipants = useMemo(
    () =>
      event.categories.items.reduce(
        (acc, cur) => acc + cur?.categoryCount?.count,
        0,
      ),
    [event],
  )

  useEffect(() => {
    if (event?.categories?.items) {
      const filteredCategories = event?.categories?.items.filter((cat) => {
        if (categoryId) return cat.id === categoryId && !cat.isIndividual
        return !cat.isIndividual
      })

      setCategories(
        filteredCategories.map((cat) => ({
          id: cat.id,
          name: cat.name,
          fee: cat.fee,
          adminFee: cat.adminFee,
          priority: cat.priority,
          disabled:
            typeof cat.maxParticipants === 'number' &&
            cat.categoryCount['count'] >= cat.maxParticipants,
          isTandem: cat.isTandem || false,
          tandemAgeRange: `${cat.tandemAgeRange}`,
          eventEndDateTime: event.endDateTime,
          isTriathlon: cat.isTriathlon || false,
          bonusParticipants: cat.bonusParticipants || 0,
          teamType: cat.teamType,
        })),
      )
    }
  }, [event, categoryId, setCategories])

  const updateParticipantList = useCallback(() => {
    setParticipants(cartItems)
  }, [cartItems])

  const updateBonusCategory = useCallback(() => {
    let newBonusCategory = ''
    if (cartItems.length && cartItems.length >= DEFAULT_PARTICIPANT_COUNT) {
      newBonusCategory = getBonusCategory(cartItems, categories).id
    }
    setBonusCategory(newBonusCategory)
  }, [cartItems, categories])

  const updateRelayEvent = useCallback(() => {
    let newRelayEvent = []
    cartItems.forEach((cartItem) => {
      const multisportsCategory = categories.filter(
        (cat) =>
          cat.id === cartItem.categoryId &&
          isCategoryMultisports(cat) &&
          !cat.isIndividual,
      )
      if (Object.keys(multisportsCategory).length > 0)
        newRelayEvent = [...newRelayEvent, ...cartItem.relayEvent]
    })
    setSelectedRelayEvent(uniq(newRelayEvent))
  }, [cartItems, categories])

  const updateMaxItemCount = useCallback(() => {
    if (categories.length === 1 && isCategoryMultisports(categories[0])) {
      setIsTeam(true)
      setMaxItemCount(3)
      return
    }

    // check if DOUBLES
    const doublesCategories = categories.filter(
      (category) => category.teamType === TEAM_TYPES.DOUBLES,
    )
    if (doublesCategories.length === categories.length) {
      setIsTeam(true)
      setMaxItemCount(2)
      return
    }

    const bonusCnt =
      cartItems.length && cartItems.length >= DEFAULT_PARTICIPANT_COUNT
        ? getBonusCategory(cartItems, categories).count
        : 0

    setIsTeam(false)
    setMaxItemCount(DEFAULT_PARTICIPANT_COUNT + bonusCnt)
  }, [cartItems, categories])

  useEffect(() => {
    updateParticipantList()
    updateBonusCategory()
    updateRelayEvent()

    updateMaxItemCount()
  }, [cartItems, updateMaxItemCount])

  return (
    <>
      {show && (
        <>
          <div
            style={{
              margin: 'auto',
              textAlign: 'center',
              marginBottom: '10px',
            }}>
            <Button color="primary" onClick={() => setShowSize(true)}>
              View T-Shirt Size Chart
            </Button>
          </div>
          <div style={{ height: 30 }} />
          <Typography variant="subtitle2" textAlign="center">
            (
            {isTeam && maxItemCount === 2
              ? 'Add '
              : 'You can add a maximum of '}
            {maxItemCount} participants)
          </Typography>

          {itemCount < maxItemCount && (
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '14px',
              }}>
              <CustomButton
                secondary
                contained
                sx={{
                  margin: 'auto',
                }}
                text
                onClick={() => {
                  setSelectedParticipant(null)
                  setShowParticipantDialog(true)
                }}>
                +Add Participant
              </CustomButton>
            </div>
          )}
          <Table
            key="participantList"
            idKey="participantList"
            headers={participantTableHeaders({
              removeProduct,
              setSelectedParticipant,
              setShowParticipantDialog,
              isOnsiteRegistration,
              subCategories: event.subCategories || null,
            })}
            rows={participants || []}
            namedRows
          />
          <FormButtonContainer wrap={false}>
            <FormButton secondary outlined onClick={handleSecondaryAction}>
              {secondaryActionText}
            </FormButton>
            <FormButton
              primary
              outlined
              disabled={
                isTeam ? itemCount < maxItemCount : participants.length === 0
              }
              onClick={handlePrimaryAction}>
              {primaryActionText}
            </FormButton>
          </FormButtonContainer>
          <ParticipantForm
            categories={categories}
            bonusCategory={bonusCategory}
            isOnsiteRegistration={isOnsiteRegistration}
            selectedRelayEvent={selectedRelayEvent}
            currentUser={cartUser}
            setLoading={setPageLoading}
            editing={!!selectedParticipant}
            participant={selectedParticipant || { nationality: 'PH' }}
            updateData={(participant) => {
              const currentCategory = categories.filter(
                (cat) => cat.id === participant.categoryId,
              )[0]

              const fee = currentCategory.fee
              const adminFee = currentCategory.adminFee

              let currentCategoryName = `${
                participant.additionalId && event.hasAdditionalId
                  ? event.additionalIdName
                  : ''
              }${currentCategory?.name}`

              if (isCategoryMultisports(currentCategory))
                currentCategoryName += ` (${participant.relayEvent.join(', ')})`

              if (selectedParticipant) {
                updateProduct({
                  ...participant,
                  eventId: event.id,
                  eventName: event.name,
                  categoryName: currentCategoryName,
                  fee,
                  adminFee,
                  teamType: currentCategory.teamType,
                })
              } else {
                addProduct({
                  ...participant,
                  eventId: event.id,
                  eventName: event.name,
                  categoryName: currentCategoryName,
                  fee,
                  adminFee,
                  teamType: currentCategory.teamType,
                })
              }
              updateParticipantList()

              setSelectedParticipant(null)
              setShowParticipantDialog(false)
              setPageLoading(false)
              return [null, null]
            }}
            dialogProps={{
              open: showParticipantDialog,
              onClose: () => setSelectedParticipant(null),
            }}
            onCancel={() => {
              setSelectedParticipant(null)
              setShowParticipantDialog(false)
            }}
            hasAdditionalId={!!event.hasAdditionalId}
            additionalIdFieldLabel={
              event.hasAdditionalId ? event.additionalIdFieldLabel : 'ID #'
            }
            firstParticipants={
              event.firstParticipants
                ? {
                    ...(event.firstParticipants &&
                      JSON.parse(
                        JSON.parse(event.firstParticipants as string),
                      )),
                    TOTAL: getTotalParticipants,
                  }
                : null
            }
            subCategories={event.subCategories || null}
          />

          <SizeChartDialog
            open={showSize}
            onDialogClose={() => {
              setShowSize(false)
            }}
            onButtonClose={() => {
              setShowSize(false)
            }}
          />
        </>
      )}
    </>
  )
}
export default StepParticipant
