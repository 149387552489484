// import { AmplifyS3Image } from "@aws-amplify/ui-react";

import Button from '@mui/material/Button'
import CardMedia from '@mui/material/CardMedia'
import Grid, { GridProps } from '@mui/material/Grid'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'

import Entitlement from './Entitlement'
import { Entitlement as EntitlementApi } from '../API'
import Dialog from '../components/elements/Dialog'

// const ImageGrid = styled(Grid)(({ theme }) => ({
const ImageGrid = styled(Grid)(() => ({
  width: `calc(100vw / 2)`,
  height: `calc(100vh / 2)`,
  '& > div': {
    width: 'unset',
    height: 'unset',
    '& > div': {
      backgroundColor: 'unset',
      backgroundSize: 'contain',
      width: 'auto',
      height: `calc(100vh / 2)`,
    },
  },
}))

interface RetrievedPresignedUrl {
  key: string
  presignedUrl: string
}

interface PresignedUrl {
  [key: string]: string
}

type Entitlement = Omit<Exclude<EntitlementApi, null>, '__typename'>

interface Props extends GridProps {
  entitlements?: Entitlement[]
}

const getS3PresignedUrl = (key: string) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const presignedUrl = await Storage.get(key)
      resolve({ key, presignedUrl })
    } catch (e) {
      console.log(e)
      reject()
    }
  })
}

const EventDetailsEntitlements: React.FC<Props> = ({ entitlements }) => {
  const theme = useTheme()
  const [selectedEntitlement, setSelectedEntitlement] =
    useState<Entitlement | null>()
  const [open, setOpen] = useState(false)

  const [presignedUrls, setPresignedUrls] = useState<PresignedUrl>({})
  useEffect(() => {
    const fetch = async () => {
      if (entitlements instanceof Array) {
        const results = await Promise.all(
          entitlements
            ?.filter((entitlement) => entitlement?.photo?.thumbnailKey)
            .map((entitlement) =>
              getS3PresignedUrl(entitlement?.photo?.thumbnailKey || ''),
            ),
        )

        const imageUrls: PresignedUrl = {}
        results.forEach((result: RetrievedPresignedUrl) => {
          imageUrls[result.key] = result.presignedUrl
        })
        setPresignedUrls(imageUrls)

        return
      }
    }
    if (entitlements) fetch()
  }, [entitlements])

  return (
    <Grid container item spacing={2}>
      {/* Entitlements */}
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Typography variant="h5" sx={{ marginTop: theme.spacing(6) }}>
          Inclusions
        </Typography>
      </Grid>
      {entitlements && (
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start">
          {entitlements instanceof Array &&
            entitlements
              .sort((a: Entitlement, b: Entitlement) => {
                if (!!a.priority && !b.priority) return -1
                if (!!b.priority && !a.priority) return 1
                return a.priority - b.priority
              })
              .map((entitlement: Entitlement) => (
                <Grid
                  key={`entitlement${entitlement.name}`}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={(theme) => ({
                    marginBottom: theme.spacing(4),
                  })}
                  onClick={() => {
                    if (
                      entitlement?.photo?.thumbnailKey &&
                      entitlement.photo.thumbnailKey in presignedUrls
                    ) {
                      setSelectedEntitlement(entitlement)
                      setOpen(true)
                    }
                  }}>
                  <Grid item xs={12}>
                    <Entitlement
                      image={
                        entitlement?.photo?.thumbnailKey &&
                        entitlement.photo.thumbnailKey in presignedUrls
                          ? `${presignedUrls[entitlement.photo.thumbnailKey]}`
                          : `${process.env.PUBLIC_URL}/images/megatechph_icon.png`
                      }
                      name={entitlement.name}
                    />
                  </Grid>
                </Grid>
              ))}

          <Dialog
            open={open}
            onClose={() => {
              setOpen(false)
            }}
            title={selectedEntitlement?.name || ''}
            content={
              <ImageGrid item lg={12}>
                <div
                  style={{
                    overflow: 'hidden',
                  }}>
                  <CardMedia
                    style={{
                      backgroundSize: 'contain',
                    }}
                    image={
                      selectedEntitlement?.photo?.key &&
                      selectedEntitlement.photo.key in presignedUrls
                        ? `${presignedUrls[selectedEntitlement.photo.key]}`
                        : `${process.env.PUBLIC_URL}/images/megatechph_icon.png}`
                    }
                  />
                </div>
              </ImageGrid>
            }
            buttons={
              <>
                <Button
                  variant="text"
                  onClick={() => {
                    setOpen(false)
                  }}>
                  Close
                </Button>
              </>
            }
          />
        </Grid>
      )}
    </Grid>
  )
}
export default EventDetailsEntitlements
