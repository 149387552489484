/* eslint-disable react/react-in-jsx-scope */
import FormTextInput from '../elements/FormTextInput'

const UserFormContent = ({ formDisabled, updateField }) => [
  {
    id: 'user-form-details',
    name: 'Personal Information',
    inputs: [
      {
        id: 'firstName',
        size: 6,
        field: (
          <FormTextInput
            name="firstName"
            label="First Name"
            required
            autoComplete="new-firstName"
            disabled={formDisabled}
            onModified={(e) => {
              updateField('firstName', e.target.value.toUpperCase())
            }}
          />
        ),
      },
      {
        id: 'lastName',
        size: 6,
        field: (
          <FormTextInput
            name="lastName"
            label="Last Name"
            required
            autoComplete="new-lastName"
            disabled={formDisabled}
            onModified={(e) => {
              updateField('lastName', e.target.value.toUpperCase())
            }}
          />
        ),
      },
      {
        id: 'email',
        size: 6,
        field: (
          <FormTextInput
            name="email"
            label="Email Address"
            required
            autoComplete="new-email"
            disabled={formDisabled}
            onCut={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault()
            }}
            onModified={(e) => {
              updateField('email', e.target.value.toUpperCase().trim())
            }}
          />
        ),
      },
      {
        id: 'confirmEmail',
        size: 6,
        field: (
          <FormTextInput
            name="confirmEmail"
            label="Confirm Email Address"
            required
            autoComplete="new-confirmEmail"
            disabled={formDisabled}
            onCut={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault()
            }}
            onModified={(e) => {
              updateField('confirmEmail', e.target.value.toUpperCase().trim())
            }}
          />
        ),
      },

      {
        id: 'phoneNumber',
        size: 6,
        field: (
          <FormTextInput
            name="phoneNumber"
            label="Phone Number"
            required
            autoComplete="new-phoneNumber"
            disabled={formDisabled}
            onCut={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault()
            }}
            onModified={(e) => {
              updateField('phoneNumber', e.target.value.toUpperCase())
            }}
          />
        ),
      },
      {
        id: 'confirmPhoneNumber',
        size: 6,
        field: (
          <FormTextInput
            name="confirmPhoneNumber"
            label="Confirm Phone Number"
            required
            autoComplete="new-confirmPhoneNumber"
            disabled={formDisabled}
            onCut={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault()
            }}
            onModified={(e) => {
              updateField('confirmPhoneNumber', e.target.value.toUpperCase())
            }}
          />
        ),
      },
    ],
  },
]
export default UserFormContent
