import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { API } from 'aws-amplify'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Section from './Section'
import { CartContext } from '../contexts/CartContext'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import { PaymentIntentResponse } from '../models/Paymongo/PaymentIntent'

const SuccessCircleIconContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  textAlign: 'center',
  color: theme.palette.success.main,
  marginBottom: theme.spacing(theme['container'].padding),
  '& svg': {
    fontSize: '40px',
  },
  '& h4': {
    color: theme.palette.success.main,
    marginLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
}))
const FailCircleIconContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.error.main,
  marginBottom: theme.spacing(theme['container'].padding),
  '& svg': {
    fontSize: '40px',
  },
  '& h4': {
    color: theme.palette.error.main,
    marginLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
}))

const retrievePaymentIntent = async (id: string) => {
  return new Promise((resolve, reject) => {
    const apiName = 'api85b3bd57Payment'
    const path = '/paymongo'
    const myInit = {
      headers: {}, // OPTIONAL
      response: true,
      queryStringParameters: {
        id,
      },
    }

    API.get(apiName, path, myInit)
      .then((response) => {
        resolve(response.data)
      })
      // .catch((error) => {
      .catch(() => {
        reject()
      })
  })
}

const ActionButtons: React.FC = () => (
  <div style={{ marginTop: 16 }}>
    <Link href="/events" sx={{ textDecoration: 'none' }}>
      <Button color="secondary" variant="contained">
        BACK to Events
      </Button>
    </Link>
  </div>
)

const PaymongoResult = () => {
  const location = useLocation()
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)
  const [paymentStatus, setPaymentStatus] = useState<PaymentIntentResponse>()
  const [orderSuccessful, setOrderSuccessful] = useState(false)
  const [freeRegistration, setFreeRegistration] = useState(false)
  const { clearCart } = useContext(CartContext)

  useEffect(() => {
    clearCart()
  }, [])

  useEffect(() => {
    let didCancel = false

    const getStatus = async () => {
      setPageLoading(true)
      if (didCancel) return

      const id = new URLSearchParams(location.search).get('payment_intent_id')

      if (id !== null) {
        const result: PaymentIntentResponse = await retrievePaymentIntent(id)
        setPaymentStatus(result)
      } else {
        const freeRegistrationParam = new URLSearchParams(location.search).get(
          'free_registration',
        )
        setFreeRegistration(freeRegistrationParam !== null)
      }
      setPageLoading(false)
    }

    getStatus()

    return () => {
      didCancel = true
    }
  }, [location, setPageLoading])

  useEffect(() => {
    if (paymentStatus?.data?.attributes?.payments?.length > 0) {
      setOrderSuccessful(
        paymentStatus.data.attributes.payments[0].attributes['status'] ===
          'paid',
      )
    }
  }, [paymentStatus, setOrderSuccessful])

  useEffect(() => {
    setOrderSuccessful(freeRegistration)
  }, [freeRegistration, setOrderSuccessful])

  const renderContent = () => {
    if (orderSuccessful)
      return (
        <>
          <SuccessCircleIconContainer>
            <CheckCircleIcon />
            <Typography variant="h4">
              {freeRegistration
                ? 'Registration Successful'
                : 'Processing Payment'}
            </Typography>
          </SuccessCircleIconContainer>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Thank you for registering.{' '}
            {!freeRegistration && 'We are currently processing your payment. '}
            An email confirmation will be sent to you shortly
            {!freeRegistration && ' once your payment is received'}!
          </Typography>

          <Typography variant="caption">
            <strong>NOTE: </strong>If you can not find email in your inbox,
            please check your spam or junk folder. If you did not get any email
            confirmation, contact{' '}
            <a
              href="mailto:support@megatechph.com"
              style={{ textDecoration: 'none' }}>
              support@megatechph.com
            </a>{' '}
            to be safe on your side.
          </Typography>

          <ActionButtons />
        </>
      )

    return (
      <>
        <FailCircleIconContainer>
          <ErrorIcon />
          <Typography variant="h4">Payment Failed</Typography>
        </FailCircleIconContainer>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We are unable to capture your payment. Please ensure that all payment
          information is correct.
        </Typography>

        <Typography variant="caption">
          <strong>NOTE: </strong>
          You may want to contact{' '}
          <a
            href="mailto:support@megatechph.com"
            style={{ textDecoration: 'none' }}>
            support@megatechph.com
          </a>{' '}
          to be safe on your side.
        </Typography>

        <ActionButtons />
      </>
    )
  }

  return (
    <Section centered>
      {(paymentStatus || freeRegistration) && renderContent()}
    </Section>
  )
}

export default PaymongoResult
