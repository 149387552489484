import Typography from '@mui/material/Typography'
import React from 'react'

interface Props {
  title: string
  description: string
}

const SectionHeader: React.FC<Props> = ({ title, description }) => (
  <>
    <Typography variant="h4" textAlign="center" sx={{ mb: 3 }}>
      {title}
    </Typography>
    <Typography textAlign="center" sx={{ mb: 3 }}>
      {description}
    </Typography>
  </>
)

export default SectionHeader
