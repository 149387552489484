import { useTheme } from '@mui/material/styles'
import { isArray } from 'lodash'
import React from 'react'

interface Props {
  children?: React.ReactNode[] | React.ReactNode
  wrap?: boolean
}

const ButtonContainer: React.FC<Props> = ({ wrap, children }) => {
  const theme = useTheme()
  return (
    <div
      style={{
        margin: theme.spacing(8, 0),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...(wrap && {
          flexWrap: 'wrap',
        }),
      }}
    >
      {isArray(children) ? (
        children.map((child, i) => <div key={i}>{child}</div>)
      ) : (
        <div>{children}</div>
      )}
    </div>
  )
}

export default ButtonContainer
