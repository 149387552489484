import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Participant from './Participants'
import User from './User'
import { getEmployee } from '../../constants/employees'
import { ONSITE_PAYMENT_MODES } from '../../constants/models'
import { CartContext } from '../../contexts/CartContext'
import { PageLoaderContext } from '../../contexts/PageLoaderContext'
import useFormEventDetails from '../../hooks/useFormEventDetails'
import EmptyState from '../EmptyState'
import PageTitle from '../Page/Title'
import Section from '../Section'

const STEP_USER = 0
const STEP_PARTICIPANT = 1

const steps = ['User details', 'Add Shirts']

const FormEventRegistration = () => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(STEP_USER)

  const { cartFPUser, addFPUser, clearCart } = useContext(CartContext)
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)
  const [employee, setEmployee] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const { slug } = useParams()

  const {
    event,
    apiLoading: loading,
    apiError,
  } = useFormEventDetails({
    slug,
  })

  useEffect(() => {
    clearCart()
  }, [])

  useEffect(() => {
    setPageLoading(loading)
  }, [loading, setPageLoading])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const displayError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
  }
  const renderContent = () => {
    if (apiError)
      return (
        <EmptyState header={apiError.title} text={apiError.message}>
          <Link href="/events">
            <Button variant="contained" color="secondary">
              BACK TO EVENTS
            </Button>
          </Link>
        </EmptyState>
      )

    if (!event)
      return (
        <EmptyState
          header="Hmm… We cannot find the event you are looking for"
          text=""
        />
      )

    return (
      <>
        <PageTitle title={`Register to ${event?.name}`} />
        <div style={{ height: 48 }} />
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        <div style={{ height: 32 }} />
        <User
          show={activeStep === STEP_USER}
          user={cartFPUser}
          editing={!!cartFPUser}
          setLoading={setPageLoading}
          updateData={(user) => {
            const employee = getEmployee(slug, user.participantId)
            if (Object.keys(employee).length === 0)
              return [
                null,
                { title: 'Not found', message: 'Employee not found' },
              ]

            if (employee.site !== user.workSite)
              return [
                null,
                { title: 'Not found', message: 'Employee not found' },
              ]
            addFPUser({
              ...user,
              max: employee.max,
              name: employee.name,
            })
            setEmployee({
              ...user,
              max: employee.max,
              name: employee.name,
            })
            setPageLoading(false)
            handleNext()
          }}
          onCancel={() => {
            clearCart()
            navigate(-1)
          }}
          displayError={displayError}
        />
        <Participant
          show={activeStep === STEP_PARTICIPANT}
          event={event}
          employee={employee}
          editing={!!employee}
          setLoading={setPageLoading}
          user={{
            paymentMode: ONSITE_PAYMENT_MODES.CASH.toUpperCase(),
            tshirtCount: [{ size: '#4', quantity: 1, color: 'BLUE' }],
            atdCb: false,
          }}
          onCancel={() => {
            setEmployee(null)
            clearCart()
            handleBack()
          }}
        />
      </>
    )
  }

  return <Section>{!loading && renderContent()}</Section>
}

export default FormEventRegistration
