/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import * as Yup from 'yup'

import { GENDERS } from '../../../constants/models'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(customParseFormat)

const ParticipantMoreInfoSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  eventName: Yup.string(),
  categoryName: Yup.string(),
  birthdate: Yup.mixed()
    .required('Birthdate is required')
    .test(
      'validate-format',
      'Birthdate must be valid date in format YYYY-MM-DD',
      (value) => {
        return dayjs(value as string, 'YYYY-MM-DD', true).isValid()
      },
    )
    .test('validate-future', 'Birthdate must not be a future date', (value) => {
      if (value !== undefined) return dayjs().isAfter(value as string)
    }),
  gender: Yup.mixed()
    .required('Gender is required')
    .oneOf(Object.keys(GENDERS)),
  address: Yup.string()
    .nullable()
    .max(100, 'Address must not exceed 100 characters')
    .required('Address is required'),
})

export default ParticipantMoreInfoSchema
