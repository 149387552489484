import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Storage } from 'aws-amplify'
import { uniq } from 'lodash'
import React, { useEffect, useState } from 'react'

import ImageCarousel, { CarouselItemProps } from './elements/ImageCarousel'
import { Event as EventApi } from '../API'

type Event = Omit<Exclude<EventApi, null>, '__typename'>

interface Props {
  event: Event
}

const ComingSoon: React.FC<Props> = ({ event }: Props) => {
  const [coverPresignedUrl, setCoverPresignedUrl] = useState<
    CarouselItemProps[]
  >([])

  useEffect(() => {
    const fetch = async () => {
      const presignedUrls: string[] = []
      if (event?.fullCover && event.fullCover.length > 0) {
        for await (const fc of event.fullCover) {
          const presigned = await Storage.get(fc.key)
          presignedUrls.push(presigned)
        }
      } else if (event?.cover?.key) {
        const presigned = await Storage.get(event?.cover?.key)
        presignedUrls.push(presigned)
      }
      const uniqueUrls = uniq(presignedUrls)
      if (uniqueUrls.length > 0)
        setCoverPresignedUrl(
          uniqueUrls.map((url) => ({
            url,
          })),
        )
      // setCoverPresignedUrl(uniq(presignedUrls))
    }

    if (
      event?.cover?.key ||
      event?.fullCover?.length > 0 ||
      event?.locationImage?.key
    )
      fetch()
    else
      setCoverPresignedUrl([
        {
          url: `${process.env.PUBLIC_URL}/images/megatechph_icon.png`,
        },
      ])
  }, [event])

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Typography variant="h4">{`Ready, Get Set...`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid item xs={12} justifyContent="center" alignItems="center">
            {coverPresignedUrl.length > 0 && (
              <ImageCarousel
                items={coverPresignedUrl}
                isFull={true}
                navButtonsAlwaysVisible
                autoPlay={false}
                animation="fade"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ComingSoon
