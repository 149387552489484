import { TextFieldProps } from '@mui/material/TextField'
import { FieldProps } from 'formik'
import React from 'react'

import FormInput, { FormInputProps } from './FormInput'

interface Props {
  name: string
}

const FormTextInput: React.FC<
  Props &
    Partial<TextFieldProps> &
    Partial<FieldProps> &
    Partial<FormInputProps>
> = ({ ...props }) => <FormInput type="text" {...props} />

export default FormTextInput
