import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import { Field, FieldProps } from 'formik'
import React from 'react'

interface FormCheckboxProps {
  label: JSX.Element
  onModified?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
}
const FormCheckbox: React.FC<
  CheckboxProps & Partial<FormCheckboxProps> & Partial<FieldProps>
> = ({
  label,
  onModified,
  field: { name, value }, // { name, value, onChange, onBlur }
  form: { handleChange }, // also touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <Checkbox
          name={name}
          checked={value}
          onChange={(e, checked) => {
            handleChange(e)
            if (typeof onModified === 'function') onModified(e, checked)
          }}
        />
      </Grid>
      <Grid item xs>
        {label}
      </Grid>
    </Grid>
  )
}

interface Props {
  name: string
  value?: string
}

const FormikFieldWrapper: React.FC<Props & FormCheckboxProps> = ({
  name,
  ...rest
}) => {
  return (
    <Field name={name} type="checkbox" component={FormCheckbox} {...rest} />
  )
}

export default FormikFieldWrapper
