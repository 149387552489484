import LinearProgress from '@mui/material/LinearProgress'
import React from 'react'

const Loader = () => (
  <div
    style={{
      padding: 'unset',
      position: 'fixed',
      top: 87,
      width: '100%',
      zIndex: 1200,
      display: 'block',
    }}>
    <LinearProgress color="primary" />
  </div>
)

export default Loader
