import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'

interface Props {
  children: JSX.Element[] | JSX.Element
  secondary?: boolean
  centered?: boolean
  noPadding?: boolean
  noMargin?: boolean
}

const Section: React.FC<Props> = ({
  children,
  secondary,
  centered,
  noMargin = false,
  noPadding = false,
}) => (
  <Box
    sx={(theme) => ({
      width: '100vw',
      ...(!noPadding && {
        padding: 4,
      }),
      ...(!noMargin && {
        marginY: 2,
      }),
      ...(secondary && {
        backgroundColor: theme.palette.grey[100],
      }),
    })}>
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...(centered && {
          alignItems: 'center',
        }),
      }}>
      {children}
    </Container>
  </Box>
)
export default Section
