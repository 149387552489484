import {
  Authenticator,
  Button,
  Heading,
  // Image,
  Text,
  ThemeProvider,
  useAuthenticator,
  useTheme,
  View,
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { StyledEngineProvider } from '@mui/material/styles'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import Section from '../components/Section'

const components = {
  Header() {
    const { tokens } = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.large}>
        {/* <Image
          alt="Amplify logo"
          src="https://docs.amplify.aws/assets/logo-dark.svg"
        /> */}
      </View>
    )
  },

  Footer() {
    const { tokens } = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={`${tokens.colors.neutral['80']}`}>
          &copy; All Rights Reserved
        </Text>
      </View>
    )
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme()

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}>
          Sign in to your account
        </Heading>
      )
    },
    Footer() {
      const { tokens } = useTheme()
      const { toResetPassword } = useAuthenticator()

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link">
            Forgot Password?
          </Button>
        </View>
      )
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme()

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}>
          Create a new account
        </Heading>
      )
    },
    Footer() {
      const { tokens } = useTheme()
      const { toSignIn } = useAuthenticator()
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link">
            Back to Sign In
          </Button>
        </View>
      )
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme()
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}>
          Enter Information:
        </Heading>
      )
    },
    Footer() {
      return <Text>Footer Information</Text>
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme()
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}>
          Enter Information:
        </Heading>
      )
    },
    Footer() {
      return <Text>Footer Information</Text>
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme()
      return (
        <Heading
          padding={`${tokens.space.xl} 0 ${tokens.space.xl} 0`}
          level={3}>
          Forgot password
        </Heading>
      )
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme()
      return (
        <View padding={`${tokens.space.large} 0 ${tokens.space.xl} 0`}>
          <Heading level={3}>Reset Password</Heading>
          <Text variation="primary">
            Check your email for the confirmation code
          </Text>
        </View>
      )
    },
    // Footer() {
    //   return <Text>Footer Information</Text>
    // },
  },
}

const formFields = {
  signIn: {
    username: {
      placeholder: 'Email Address',
      isRequired: true,
    },
  },
  signUp: {
    // name: {
    //   placeholder: 'Complete Name',
    //   isRequired: true,
    //   order: 1,
    // },
    email: {
      placeholder: 'Email Address',
      isRequired: true,
      order: 2,
    },
    password: {
      placeholder: 'Password',
      isRequired: true,
      order: 3,
    },
    confirm_password: {
      label: 'Confirm Password',
      isRequired: true,
      order: 4,
    },
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      labelHidden: true,
      placeholder: 'Enter your email',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: true,
      placeholder: 'Enter your Confirmation Code',
      // label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      labelHidden: true,
      placeholder: 'Re-enter new password',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code',
      isRequired: false,
    },
  },
}

const Login = () => {
  const { route } = useAuthenticator((context) => [context.route])
  const location = useLocation()
  const navigate = useNavigate()

  const from = location.state?.from?.pathname || '/'

  const customTheme = {
    name: 'Auth Theme',
    tokens: {
      components: {
        authenticator: {
          router: {
            borderStyle: {
              value: 'none',
            },
          },
        },
        button: {
          primary: {
            backgroundColor: {
              value: '#FFA300',
            },
            _hover: {
              backgroundColor: {
                value: '#c67400',
              },
            },
          },
        },
        tabs: {
          item: {
            _focus: {
              color: {
                value: '#FFA300',
              },
            },
            _hover: {
              color: {
                value: '#FFA300',
              },
            },
            _active: {
              color: {
                value: '#FFA300',
              },
              borderColor: {
                value: '#FFA300',
              },
            },
          },
        },
      },
    },
  }

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])

  return (
    <Section>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={customTheme}>
          <View className="auth-wrapper">
            <Authenticator
              loginMechanisms={['email']}
              formFields={formFields}
              components={components}
              hideSignUp={true}
            />
          </View>
        </ThemeProvider>
      </StyledEngineProvider>
    </Section>
  )
}

export default Login
