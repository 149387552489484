/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useState, useEffect } from 'react'

import { ErrorProps } from './common'
import { FormEvent as FormEventApi } from '../API'
import { formEventBySlug } from '../api/queries'
import { fetchQuery } from '../utils/ApiUtils'

dayjs.extend(utc)
dayjs.extend(timezone)

type FormEvent = Omit<Exclude<FormEventApi, null>, '__typename'>

interface Props {
  slug: string | null | undefined
}
const useFormEventDetails = ({ slug }: Props) => {
  const [event, setEvent] = useState<FormEvent>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorProps>()

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      if (didCancel) return
      setLoading(true)

      try {
        const { items } = (await fetchQuery(
          'formEventBySlug',
          formEventBySlug,
          {
            slug,
            filter: {
              registrationCutOff: {
                gt: dayjs().tz('Asia/Manila'),
              },
            },
          },
        )) as unknown as { items: FormEvent[] }

        if (items.length > 0) {
          const item = items[0]
          item.tshirtFees = JSON.parse(JSON.parse(item.tshirtFees))
          setEvent(item)
        }
      } catch (e) {
        console.log(e)
        setError({
          title: 'Fail to load event',
          message: '',
        })
      }
      setLoading(false)
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [slug])

  return {
    event,
    apiLoading: loading,
    apiError: error,
  }
}

export default useFormEventDetails
