import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Page from './elements/Page'
import EmptyState from './EmptyState'
import participantMoreInfoFormContent from './forms/contents/ParticipantMoreInfoFormContent'
import Form from './forms/elements/Form'
import FormButton from './forms/elements/FormButton'
import FormButtonContainer from './forms/elements/FormButtonContainer'
import FormGrid from './forms/elements/FormGrid'
import participantMoreInfoSchema from './forms/schemas/ParticipantMoreInfoSchema'
import { Participant as ParticipantApi } from '../API'
import { updateParticipant } from '../api/mutations'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import useParticipantDetails from '../hooks/useParticipantDetails'
import { doMutation } from '../utils/ApiUtils'

type Participant = Omit<Exclude<ParticipantApi, null>, '__typename'>

const CheckCircleIconContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.success.main,
  marginBottom: theme.spacing(theme['container'].padding),
  '& svg': {
    fontSize: '40px',
  },
  '& h4': {
    color: theme.palette.success.main,
    marginLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
}))

const ParticipantMoreInfo = () => {
  const [hasError, setHasError] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)
  const [participant, setParticipant] = useState<Participant>()

  const { participantId } = useParams()
  const {
    participant: initParticipant,
    apiLoading: loading,
    apiError,
  } = useParticipantDetails({
    id: participantId,
  })

  useEffect(() => {
    setPageLoading(loading)
  }, [loading, setPageLoading])

  useEffect(() => {
    if (initParticipant) {
      setParticipant(initParticipant)
    }
  }, [initParticipant, setParticipant])

  const renderContent = () => {
    if (apiError)
      return (
        <div style={{ textAlign: 'center' }}>
          <EmptyState header={apiError.title} text={apiError.message}>
            <Link href="/events">
              <Button variant="contained" color="secondary">
                BACK TO EVENTS
              </Button>
            </Link>
          </EmptyState>
        </div>
      )

    if (submitted) {
      if (!hasError) {
        return (
          <Page centered>
            <CheckCircleIconContainer>
              <CheckCircleIcon />
              <Typography variant="h4">Information submitted</Typography>
            </CheckCircleIconContainer>
            <div style={{ height: 16 }} />
            <Link href="/events" sx={{ textDecoration: 'none' }}>
              <Button color="secondary" variant="contained">
                BACK to Events
              </Button>
            </Link>
          </Page>
        )
      }

      return (
        <div style={{ textAlign: 'center' }}>
          <EmptyState header="Oops!" text={`${hasError}`} />
        </div>
      )
    }

    if (!participant)
      return (
        <div style={{ textAlign: 'center' }}>
          <EmptyState
            header="Hmm… We cannot find the participant you are looking for"
            text=""
          />
        </div>
      )

    return (
      <Container maxWidth="md">
        <div style={{ height: 30 }} />

        <Formik
          initialValues={{
            ...participant,
            eventName: participant.event.name,
            categoryName: participant.category.name,
          }}
          validateOnMount={true}
          enableReinitialize
          validateOnBlur={false}
          validationSchema={participantMoreInfoSchema}
          onSubmit={async (values, actions) => {
            setPageLoading(true)
            try {
              await doMutation('updateParticipant', updateParticipant, {
                input: {
                  id: participant.id,
                  birthdate: values.birthdate.trim(),
                  address: values.address.trim(),
                  gender: values.gender.trim(),
                },
              })
              setHasError(null)
            } catch (e) {
              console.log(e)
              setHasError('Please contact admin@megatechph.com for assistance.')
            }
            setSubmitted(true)
            setPageLoading(false)
            actions.setSubmitting(false)
          }}
        >
          {(form) => {
            // const updateField = (key, value) => {
            //   form.setFieldValue(key, value)
            //   form.setTouched({
            //     ...form.touched,
            //     [key]: participant[key] !== value,
            //   })
            // }

            return (
              <Form id="jersey-form-details">
                <Card>
                  <FormGrid
                    content={participantMoreInfoFormContent({
                      values: form.values,
                      formDisabled: form.isSubmitting,
                    })}
                  />
                </Card>
                <FormButtonContainer wrap={false}>
                  <FormButton
                    submit
                    primary
                    outlined
                    disabled={!form.isValid || form.isSubmitting}
                  >
                    {'Submit'}
                  </FormButton>
                </FormButtonContainer>
              </Form>
            )
          }}
        </Formik>
      </Container>
    )
  }

  return (
    <Page noPadding>
      <div style={{ height: 48 }} />
      {!loading && renderContent()}
    </Page>
  )
}

export default ParticipantMoreInfo
