import { compareHash } from '../utils/ObjectUtil'

export const prefix = `$2a$10$DstN3bRFHGc0uIz51KpOme`

export const pastParticipants = {
  'twm-tabogon-2024': {
    '818': 'Vru1/GGlSBfIFyQ3aOMO2DNsPsfl/9.',
    '819': 'daLQRidkxY54vdL.p.6cIxWPljxedVG',
    '822': 'cEtg86JHxOv4xm17RXsaMu7xblWsxou',
    '823': 'chYxdyxtihnWVPgUDEYOwl5okaigb.q',
    '824': 'FJD6t9lf.Cib2uncsoUF4kx5kgwCs.G',
    '825': 'F2hiM4jvb51fSQtFwELOAJUqccLfiVi',
    '826': 'Jk2YAAtDAQ48pWewBGbIFzGmsQNqoXu',
    '827': 'jOompOJIBx8SbF8LgJtbzvXiBzKLaT2',
    '828': 'eIH0Gi.s/lVWXnqLYFuBxuCzs5Ml3Ku',
    '829': 'tsY7Lyj5Kpba3TpGw4.mPJALdkIeyle',
    '830': 'Ml/1pkytpPQSUJ7OCmZ0ZNIk7gcOYb2',
    '831': 'mfaCUKr5BFvbyhMd46e5nZtOU/BLL6i',
    '832': 'iSB2prjMfH/DfQqtxqrngyiac4jMjxG',
    '833': 'Mf.Z5Yig2XQ5CP2pJKIC68/PD0t.zzq',
    '835': '3tMeTb6g/6YKTUJ7tzavQIkA0tRx4L.',
    '836': 'ecRHsSX/1dnpdkIQakw6i8QVGlRxg6C',
    '837': 'Dy8VtAWb6fIoa431YzYRqQcf4gjo1B6',
    '838': 'QF/wZQOKY.sncnf9gz.iYvG.D0zNTum',
    '839': '6HtXsOAmLmtdyv0hK03QuEnVq..4Z4O',
    '841': 'bDPNIUCnaW9LQQvLw7OG8gK5MChqPv6',
    '842': 'KPqqrotB6zWDMHGrP1/PjgL..LUxRLG',
    '843': 'hmtNhETttCeuTQEuZtdcI9kEvJ9Nxme',
    '845': 'KfggRtTHJ0HcNv9CP/eY5zx5r2mtOv.',
    '846': 'dTLUfa1b.dS0i4cjpP/zJU0uKV50mdi',
    '847': 'SZ7fl.wy80seLcQCqYmZ3G3F6KUZjn6',
    '848': 'a7BJj9/Pm2zL99FtMQF59qiE2ptysnC',
    '849': 'sP7CYHxlJuKZgA1MtgMbiiSFe4Vh68y',
    '850': 'OSZrxhaKey45M4k8tScXqK4NyxtFWxe',
    '852': 'HagiZdzg5f5Zhz0wscT1SW5T9n2QN8C',
    '854': 'Pq8/GtayRolGc8yfWG5cNmuvZaNUtBi',
    '855': 'l6E5PQJ1GeIVe5uv9rFC6C5qiP48qUy',
    '856': 'P7HdsvYg8TlK8pHmtBm9ldYZ.kYEIiy',
    '857': 'OOSzMe5PjKiBlYggiMn1z6wDRt4fbLS',
    '858': '5VXup0/Tl.l77Jvpn6zjfkjVNO1A0ZC',
    '859': '.1CMbwBka9Ql6r5wdG34peq9cz37Zae',
    '860': 'tsIrhZHgfcexM7TzkoCcCd11kLYcw2.',
    '861': '3U3l/1HETJ.3sc0TAZfn8gJ9Z58.rDu',
    '863': 'Snj5hWgmJdNiB26L/Hcv84eJzLG6K.W',
    '864': '7ZCIUIL8kpfx0MkVwkOyGsSE1NPm.MS',
    '865': '8oJVY48RpCBxP1cNq4rTvbjp2mjEEZi',
    '866': 'rCFAZWoBqbC.pi6Xq/Ap4D24vUeqgpy',
    '867': '1/qkBc2GpcLRdfqSHI6pxa04LUcm/oG',
    '868': 'Oz80eA39hDP8mVED48vi8KBRgEufw/u',
    '869': 'I1aCr3yX4QwPS.kyTS680VP8GFN4ZaO',
    '870': 'ItrPzucjJPwxRZly22bpOwaTVIyxiTW',
    '871': 'l.s7NG35FC6vINgHM5jiN2HOAWutJPG',
    '873': 'bc.11h.zZOAYDZ7NiqSGqEC/2u2ZTOO',
    '875': '1DHG.EmjZQbez0KStLuUi0UhFTaJvlq',
    '876': 'B38UVVkUU/KNyIYe/5GvACwNnhubmpm',
    '877': '82H1ZdGFe0m1.QPAMgYOxvo5zgH2uba',
    '878': 'VxkjqavKxq9IVIhwXNzhXCaAx1yjiUO',
    '880': 'OfWW0CybLcPqWmoPWDYtQ.J125rehOa',
    '881': 'fhnxVpZwbQSdkpQuJfP/Qm5GguypKdm',
    '882': 'TN.peB0c.RM8o0IWRdGfGYAfODWYxly',
    '883': 'qe5RTsHBbaO0CoSSL.GKlkbHYDe7SQu',
    '884': '5FXe3d/sbo.C9sqDlOxqsIkKSGoazs.',
    '885': 'suMf2GRhQAk6sjVxn8djpqH0Dm60G5C',
    '886': 'IARHi1KNLuIVS8OKADAa.wBSBOHWi6u',
    '887': 'YPEzVfg.cpusYfmY45lGjwllrjaqgE.',
    '888': 'dF4wDcMPjTiCRSxqkfray0fl7dNN5F.',
    '889': 'EKx0HuTvXNqsYq0qrgFjFOlrLgc0gfe',
    '891': '5DGl87TGf7yDJxC0lcJPahhnJA9B4lm',
    '892': '5OLOa0dXQK8BJ/la.5e.4WwdN0qzlhO',
    '893': 'xd4u.uhRQzzHIg2sRpKyby.eduiDkhq',
    '895': 'lJzI5sDD0GgtTzQjPbQW8Ga.E8mq9LK',
    '896': 'JhKhxfCi8VypHtT8WtOBQuhBkmeV636',
    '897': 'gYlmXpbKQOzLlbRJTS7wnZv8Pfg/uDa',
    '898': 'XFE213q8EH/VeOzl/YW3o/u5Q9kkuEq',
    '899': 'JoNmNgrWDi9gDRi9MadG90iXygqem4i',
    '900': 'nedhzmWKqKueTmPPULm7YRGzLX.GuRq',
    '901': 'oDIczrAVK2ozctFzwzEyb/Yi3eeV59i',
    '903': 'GTlPiypY9SnuRtd2YGJWnOmdGPs2CDC',
    '905': 'xOYRRDmspW0o5V9sLQF5Q.Y5hKHk34i',
    '907': 'mi0RSAwWr2S21dxO5AwL5qT8Wj7CuHK',
    '908': 'FOcjmbKxeE0toazaX1gliV3OZlz8kNK',
    '909': 'Y3VI6syCq/DRNs0yBySygadZGwyRCD2',
    '910': 'xXnobLrsm7wW088qEcRxBwOJNx27diS',
    '911': 'tNcYvMSzbeMsgRzNQlh2b/gBxpxTgG.',
    '912': 'MftE/otrTuO.KSewWQ8ozCVAEZbSlES',
    '913': 'g.vCL3tfr5iz6U8LgRBddljkEZpkheK',
    '914': 'ZjDcQk3/AjMJB4bb4aZ8kW/TfS35JQy',
    '915': 'ZtDHM6Ripryu4b32diq2C9lo7ydIF1C',
    '916': 'uJ.TOwcqO4hdV/RC.8fnQiKtHyFYw8a',
    '917': 'V0oi3uVCjGaQM5egU1wcM7JMIBoVRnC',
    '918': 'Zu59aEs5SzDiitutNsQmhw78zD.qbT2',
    '919': 'fAkqHGiL3LTKs2zrdxvUuNbDIOFLqua',
    '920': 'aYo/NMqKtikSCVbbxuOZi/2juHECi1m',
    '921': 'C5N/zhD6pYWZv90/7ZmKCQdPC9jcLv2',
    '922': 'RZb1fk30ZeqMdKpJdbC.n6KXy2GhpJm',
    '923': 'W4P8rJ31llNhGZyEzgxA24hyWm0RRN.',
    '924': 'gGc9AHIHS7zTORT9wqbcDiw2ZTWe2.K',
    '925': 'bQLJbICE9Ew8UfP1fqiifl5pPFY.ggm',
    '926': 'HvoyYrz9FlPHITnrojmurjA79TX2akG',
    '928': 'ngTHG61VZZr91ZTbyVvskksXGImmhhy',
    '929': 'LKawZRLI47SrkQFTcWyDzsM85eyupIG',
    '930': '5QardKAacXsodk.Re9MkmUs5E9wO5YS',
    '931': '5QElexVJqrAq.dsBCzkYg906B/mAe/W',
    '933': 'Cl9ur/vhVEUTR9q6hjnG26Z/brGrbRm',
    '934': 'EDgg/PX.cfkanzD1YzRktCwFSy.w2xu',
    '935': '78Whd1.j5rfwbpTwl48DPgzI927Rgqu',
    '936': '8YFzoRbTvtO7Lp6ZRGx1EmS/VRDRtKm',
    '937': 'go3IlSPoqFcuaeeqvaS0jIqSIXedAPO',
    '938': 'fogQ/0dHjpfVM1aezZa2uYOeUp72NjS',
    '939': 'lIEqywYe0dmEJn22GT9XD5ZZjWZ6SsG',
    '940': 'EWNbA4FsJ38h7vhZN5vA.QBdEPnvALS',
    '941': 'uuS1rM8BxyzZidhNv11RPVkDbD63NfG',
    '942': '4hKhrlhosQpUNwRXeurKF4wo/GBcmzq',
    '943': 'uy1gOFCx8I5TjjeodzD6WnYjyKOHkfG',
    '944': 'hNsawACf.i2KkXLYE2tGIAzRBuOXCsW',
    '945': 'MmWtlUvKDWf8fT8F3kloZ/5zqMBAiGu',
    '947': 'kiEwTedDytg29i.pv0vqpmM3OejH85q',
    '948': 'C8IctNm/A19VrEN6dzo.MaEvq9ayBpu',
    '949': 'DQVAk7qOQ45CpDU/pBdSnHCFeVpfyYm',
    '950': 'Iu6omZpjU/fifaUItvGS2gMAdJqMCMq',
    '951': 'vw7arbmdGGYHlSezWbHNOECEkWhCw5m',
    '952': 'efEHH.WIQNSoj9u9wAR4YbQJ8Lidd7m',
    '954': 'dI7/pOL/luCCxr.DHb3ecaxDzMqQ8Da',
    '955': 'PA25xM4PLO2Qc7gMvKp6LQm.wyAgLry',
    '956': 'z5zmPy5m.Qq04Rz2jw7N53r.7OV.jTO',
    '957': 'r07.oQz13jZW6hHmrKdDHCM.kREPw7.',
    '958': 'DiMzzx8kWC723Hu5o3Zak0fW1HtPen6',
    '959': '89YOmE1j.m2aO49bDFeHiernns7/z3i',
    '960': 'qyqWyAcMqM/HunpPJIGbay8yKjDrbPy',
    '961': 'lfk4qiZi4be4VIE2TnfGxBC/r3NxdAW',
    '962': 'V4ASHyVFiefh3dQvQJvJRv16gue5X8a',
    '963': 'sVOlIVGFVAvgHAYTPiJKUVvSCGizIB.',
    '964': 'StQINg0rsJNotZBuT5cdl7ZEWL/mWl.',
    '965': '8SBVvfxtkrdIIQx5BVMrkwFbXoe/Wlu',
    '966': 'mz.rLsegoHboNApgTvUqYDWl0z2HETm',
    '967': '4.YzEEj0fsxsYYlertaR./pHPfycsQC',
    '968': '7BZ9SwuGSRpXLrUCm513aLIpHm2z4Ta',
    '969': 'y32cPxScFS8NsRn38tjJdYB6Pq5BkKC',
    '970': 'IdDF1cWaOagrO2BBVTVCWPdm85orL3a',
    '971': '6R8UfkOjJpZmjQZ0jfKcAOeBs.YlIL.',
    '972': 'p9r4gY60Xp1tUrqlvDxiP2ZNDm5LM5S',
    '973': 'J3AP4kztNIJMq3IeiONKMqVGf59Qe/i',
    '974': 'q9ppl3ZJf6e/Hg29b.8P08O5rteoULG',
    '975': '3ru1FzccwgFsd7lfiyskv8EJ0oAoa12',
    '976': 'wL0ih/YurBNQEA4SZxUX/2FQav8qpPW',
    '977': 'c/5XSDAX0nR0sshLwv4fEWsRP4sr/bq',
    '978': '/Ls0ueZTJbK1xWg.Iiex3oxHfbs/n8e',
    '980': 'i/DhF0749oTmjab81NAFgz8gKMvNAa2',
    '981': 'nSuDtOqmidGLV.hFCS0j4fFPFmS0nxS',
    '982': 'pTNCAqFQA5yuquEQxI5EYf6r4ozScnS',
    '983': 'VL9APSHZJTiDWjria2I95Fni5aTHttO',
    '984': '0b/9ruErpuQ7zecKQz7Xsz0jnUky/7q',
    '985': 'lV9MXHwiQxfS8kFfpDMNEOXiPMseKw.',
    '986': 'twvf1vyuk38WAIYdpIerkLVaaXVBTBa',
    '987': 'MHESmwiMFSKX/ux1cowfzhUrx2N4TPq',
    '988': '93P3p/L3mgKtobWV2HRo7u2FrYw1b.O',
    '989': 'quY5fi2PqGRQOEnlrVqmGlZG9N6sQxW',
    '991': 'Qh4jPP3kYR6QI7VwuO2LPNl4jFl9s1m',
    '992': '.9M5NziRKLrwPIHOgwgN2RdKsLq9nSS',
    '993': 'voTEf0yLpnP6EJcbP/3KE1ttnu5fTnq',
    '994': '8.cY0ZxFwwVnf4Zhta6uRMsRKpZZoVi',
    '995': 'pAfa7HkLmwm4l9VSHLYD8D8JTSetJgq',
    '997': 'ovYWnhpBbpV0dY5Lw1fX51uBZaCwaWy',
    '998': '7gx/SUX2EXNbYgu6UwaFmshLkr0C776',
    '999': 'Ad.7PlibD1v0aTqfIr/d37wOP5E3xy.',
    '1001': 'EH0MPZYOL/eIBr2b5EtdRqRFsh0muE.',
    '1002': 'bfXmuouAfkv4q3LwyOgKCZ/tV6zTZzy',
    '1003': 'DWw1Bd/cFy0Keqdij.wn2C28kDi7iZe',
    '1004': 'qMxcpcQtRSf96n9XBUqJs1DulN/7AEe',
    '1005': 'dmnW9Of0Ds.3ya9.WWnycLvPtzObs82',
    '1007': 'VfTbPbjtDYFSWKBVzECyhYZd9waWq5y',
    '1008': 'aDytOadEUHZiCEmxP4JMsKa4y6P1v06',
    '1009': '34IejrYvJyftuB9VckyA.FwAVk1EFKu',
    '1010': 't6W6mEQNxzbz8AjNPCTIainQQ4LnUBu',
    '1012': 'i.lqPNt9HMojyz0KpNG9C7zXV2tIeHK',
    '1013': '3N8d/Qqv1cdEJ8IMZxQUK.LPkPA.klS',
    '1014': '..xsKar81.1ESWQqx62kEgUvOkVqtFm',
    '1016': 'wuBWTSXedXgYxsUPV8/iWHT6DJjjHAi',
    '1017': 'f8mNXS5Ffw1XeR7nUhS03w5w/7wZHqa',
    '1018': 'uQxcGFMaQyvdnUjuniNNGyezi/Fk.aK',
    '1019': '/yYSe48Vcttu1ZMQDNzrdPwEi/GBVp2',
    '1020': 'sxCDm.fC7CzSIjRtVmCBmUKibDZh312',
    '1021': '3YApb9HkpQUkva4XgcI6EnHy2xekfDu',
    '1022': 'LTr.jNb17w306VLSwUqyF4mMvxsI99C',
    '1023': 'oYBe2HMhikAf.PGTYkD8UMb2UL8fDaW',
    '1025': 'efR3YkzTYhsq5iVS/k5a738tsYK2QLi',
    '1027': '5rtaUWU2YkxVrZ.ZzSEiTq0HwIrjTgK',
    '1029': 'BL2UFqB0l3lqs2E18v2txHL9u/A93ZG',
    '1030': 'wMo6W.nFkQmGiCa71x4nxcBND8Ms9ZO',
    '1031': 'KwWh.hq/lVwuVGgDa6GeKawEmoNS4Hy',
    '1032': 'pw9PI79H75P6vQ9lwP0gH/hk3qy0KVm',
    '1033': 'AHIpBuldLsiVdNqiwQSuKagmR7UK.em',
    '1034': 'qULM.vQ6Vm4Jt1bOrg8xa.X1ezTLog2',
    '1035': '/jB0CS3q9EJh2isyp674gyZQgTyZko6',
    '1036': 'YbP8czZ71BU9gyNb8PeDTg1B6.iHbSe',
    '1037': 'HRYiK4dbrz.iiXLxLQ82K3kch1nq/T2',
    '1038': 'tBXV/lV1fjKeN3co48UcnOcaNFkuX1S',
    '1039': 'mfwQrPLVUOqVRjQNVEZ/WRkTPIR/1da',
    '1040': 'VzXlgpleiwDHHaosPVUweRiXnyc4VQC',
    '1041': 'biRDwJSsN5YAA1cJJHI.U21QpkQoZny',
    '1042': 'ivkHkXvRsPXxJBtTn0mmpk9om5CrbFi',
    '1043': 'I.4GItBRpdgTQ7fkNEdpqlly6USvqMm',
    '1044': 'vkhTcPSlni7NGoGbaUfqFPjfolNBz1C',
    '1045': '0hKQUU68zeq5LD.nBO7GItl4SDxxbSi',
    '1046': '16BjEcsc3FMqkiBUMH107grdas76vYG',
    '1047': 'bc6ynYTWDSNFJRbxQFk7E/FqmyQM1ke',
    '1048': 'wfc1ipnSb/KK5toUW2b22AUZ21ERfl.',
    '1049': 'HGBNe4eAQrp.ozw0D27cyl9AtXRwlkm',
    '1050': 'S3B.aCcXT0oEyDHRJQNZ/Wl9ZB8PL1q',
    '1051': 'cobbR5zpSe5R0jfYFTHHw849FEt3Iu2',
    '1052': 'BG4APUH1/eAqdj8ocAT.Xhid5PGpk7i',
    '1053': 'TkXtZvIbcpIg9ltpC3C/4/aYjip9Z2C',
    '1054': '.J2gRH4VqLKyelLCs4w8EZvSwqII.zC',
    '1055': '3F9jaFnLxxhB7zpMCFNIOCezRdJCeia',
    '1056': 'wM3bHypjeeWT8vS0Jo8/aLk8rocDUEm',
    '1059': 'fQPqlA0JCdzW8RrOAW4q0P4ddVK0/O6',
    '1060': 'SfG2m.7IJScZA467AWsh3491yYgjWaC',
    '1061': '6BOO1aLkwjkNgCiu4l5wCVxA2Q9NEXS',
    '1062': 'PHpjzUTXul.hmtbWE.9YOYpMJK5e7zm',
    '1063': 'jw3ZUjupQ368f4JdpqUlt28ay62KWo6',
    '1064': 'm3bbjLiof2hfXPJFv9G4H3LPxy2ZQIy',
    '1065': 'YyUhM9VXeOxzTJ22d5.8xTRee.eM5V2',
    '1066': 'iA0Bb9gWYjbYmgG7uBWBLzosOduueB6',
    '1068': 'uzxk2pUhe0ELHuwLdsFsGNTxOn.vO8K',
    '1069': 'hkPJrua2hNhEW1Sdj2F/31b6r/09I9S',
    '1070': 'bDKifiss8vqspVVEA1Vpj60C5KMru/O',
    '1071': 'mBg7n2EHz1xUQ8AP7WPVYgt3suHJRhW',
    '1072': 'SLi09wDhOhsPAFmYElJXqmamA0FwuSG',
    '1073': 'yDU3QWQ5MJXfYPYb3g/h3iE.X00CRD.',
    '1074': 'DQTdjOa96aqOYYUMoQIhkK97qfR7hA6',
    '1075': 'zPH8./XYm5bIAwkLfogwc4oUtXQ63TW',
    '1076': 'YhBzP9G37xgVcJTyVC28EeUL2t6ArgC',
    '1077': 'f6A4IR3qE9pQ86kRpQ3UuLZ73sh9OMy',
    '1078': 'hgBsWiw7sGUNapL4tYTFtVfAPMyR9/e',
    '1079': 'Ei8oDCrK/FiXr9yvNOToPC3qAOnGhp2',
    '1080': 'OKgdnPWZbdLrv/Fn5JYZKmf6Ql7KCbO',
    '1081': 'uVTkk5WY7z6MZbsQO.4LngtlnOHMXRW',
    '1082': 'DUVYblxPG9LoXQNdFRy2o3baYf7FQlK',
    '1083': 'mpEH8SRoyUD5DvsnsonTaNWoozt.R9y',
    '1084': 'b4cgTUU1X7vSf3CoF7WUFJVD0z8/NSO',
    '1085': 'rcNRrA6tjWDaQA6wmUf/FgBPWaPCqSS',
    '1086': 'k1kFQusZehgHhiKUT08GDaReMGC32Cq',
    '1087': 'FjfOi1h7dfLPYgKSBoDE.h8.P6phJWa',
    '1088': 'RXYrGslHNZp4EJ5D0C1eLBwOfHOfi4m',
    '1090': 'IrLfwspq6tZTEzBtmvNnA365CtAuOxG',
    '1091': '2PudY09uZ./kuvd5wTqQA5yz6DOn4cS',
    '1092': 'kL9pQVdSSjqK98L//JYrSpIy6XRGgai',
    '1094': 'w9gOC6qknAPNUXuZL2mi5CmzzDvIene',
    '1095': 'KwVIE4nSzsa.qCMcKRmSYHsKSCO9HH6',
    '1098': 'RpGN0yMgN4rZLawPAq8iV.JGi/JhVS6',
    '1099': 'loH3xhW51xVwqMtcf9gq0oCx70ahh.i',
    '1100': 'DDOKBdINEzEjN7CU.bJGVLrGanzwjZm',
    '1101': '.akScJZbHH9Y.D3jiKnaiz6roKO05WW',
    '1102': 'mWlsk6MaZqT6OcoqNbX3EX4cdYZD1GK',
    '1103': 'pBfovoJ/l96nHXJAxLpoJPyw5XpWmIi',
    '1104': 'KkSzLOwYa1ZCQvEBi5jd/QibxZwLZdq',
    '1105': 'HtIFgL2js.CwYVJ5q9H0lp1Eg3H9bnC',
    '1106': 'iCragR9B.Zk3Aw5u4H1crzXR8ZWvzai',
    '1107': 'k3lXtknS3z9ykYa.aBVHwux3fDVtEE6',
    '1108': 'p57KHj4xuSxh3xsQCBOHd8WELu7b4ee',
    '1109': 'r1eP.F0Ai5JfzwXmMm4owYs9ouaMHdC',
    '1110': 'JglRlH9ruGZAtMR2z7VPwIdgzh0XQbC',
    '1111': 'Fza6XPf3vT1E95wPTo9BG8Q/xwTHm.a',
    '1112': 'WEjc9wzTpzRacvBZaxL/D5W.xrLOPNu',
    '1113': 'kns7GRSg9YVyU1mUlYojxgJSpKYBSn.',
    '1114': 'csQKKo79ygNmINGv.J4gdgfhLxuVIee',
    '1115': 'cyGnw19YeQfj9CMr8r9A8.IJ6Mlynve',
    '1116': '.cgfrjnm4jG.o8j/iud2XmlYU6eUrUG',
    '1117': 'o3QeS8BIfra0RhJkjyJHMOkfCAmt/ue',
    '1118': 's7Wrm8skGJnbkHBVOyqPE2Vv1pZxMr.',
    '1119': 'r87oRX7C6qSAbCyafvcx7ZkMCYkGlAC',
    '1120': 'uLsjs1gooFLDPRqznU9mS8MTIL2S4zC',
    '1121': 'BQwdv5rs/iiiI5cLMy51/ZDm.shcY7K',
    '1122': 'DvxtikaQ21JqgHn5xPDs3s4mi2ykJnG',
    '1123': 'pwkQGpXTyNMT882sCKBaKHQsg.d1x2K',
    '1124': 'XE7XTlF4iaxZBoeNemsXvL50VolPyz2',
    '1127': '6RgJCVKjBaBkEvkCdubFVGUzwcqjeQW',
    '1128': 'hGiySbEA62fvVPRY8IVBRjnUbpu1pRW',
    '1129': 'tUkXXg4HV/NbeI.Cpo3T.zgR5DFPvby',
    '1131': 'eDlxYTUpDqMjH0Zoyylw56tMFIDjPI.',
    '1133': 'N9QIPtdsIKtuKQn4NvxW7DU.nR7/H6i',
    '1135': '04/8OqXu5A5ogYX2NAJAacyUwG05kRK',
    '1136': 'zRRQFlbLChlxHWQ6BeCgNN25DpUyioC',
    '1137': 'vIIWHjbFmVEReFS5PhX4TKeU1HsSFlS',
    '1140': '3jEGPWKYznIkPpGMani/kIroLg6KCuq',
    '1142': 'gQwKhRjFo4mFmU8Xq/a2lGfnOAR54h.',
    '1143': 'wby3YVQa/IAD2jrX0Yod/ExrMxS6p42',
    '1145': '9/FRwGJ7UqA0sPc28l3fQeDpgQz8Vc.',
    '1146': 'Z6QTP7V5Ymtf0F6R/aCM2wiUFcxuGVi',
    '1147': 'nMLZeKBcIw6fUimQjF.3Y1J7UGnk.Rq',
    '1148': '4oJE5tazmb4k0X6OZDw/NQROuQPbsyC',
    '1149': '3froUSCyFF4TWN2dhI/loLLrkDR/UEC',
    '1150': 'Pd2JAYnM2iD8MrCoNewrXs9JIxAEEz2',
    '1151': '4rpMhb9Iy.neDmCIYcRgNPgseM8kycG',
    '1154': '434YzG5bPSSFJX9p8LZhhz8QpOYiRrm',
    '1156': '1xWcCmIsgxTCKqLAIWjDhsRXvfI43z6',
    '1157': 'hNONXpK1VwVlAlMspAoYDZIJIA4MD2S',
    '1158': '16N91WbbXga4X8hJiv39EEhdhk1ypva',
    '1159': 'll8qnOY6NY9DSa1EvzMtRIEKvOz2T.m',
    '1160': 'CvwuvlznZSwVXQvv6LzxUHjSD1ibdU6',
    '1161': 'gGGfKSMDXR7o8ks23UXrwEJCHZhDrN.',
    '1162': '8y6Zi6X3Ow3d7jBFr0LRLYgxhIsmbCK',
    '1163': 'L0fYWTg20zM2WVGgV6HZzM7Cm9H2Lc.',
    '1164': '4trcn1F4y3o6LoWwQkh3RQaIXDbbFYi',
    '1166': 'jTeHqQTLx6A1tT0aMzIjygPxOQE/xVu',
    '1168': 'ivFfr7HMXQZJRUXjhllO.76V61A5Ybq',
    '1169': 'vACeiuL5.aAceDUotoE02WX6XokweGS',
    '1170': 'AYy9xVRgG0C6U8jNYs70PZTvSv7YkhC',
    '1171': 'HTY6GJfTBpo0W3/qB1o6W2YH1PJ53IG',
    '1174': 'vooro2trmPXgbZPn/BkzwdpxpbrRxca',
    '1175': 'VIQWkcyP2bsigF4xJYby6Nl.Lzr.s4e',
    '1179': 'bE6euDGlNIwwvUeTKZHVG/NbDxlsPt6',
    '1180': 'CVDgplT.4sgo7FpwKLe8OolSvP5zkXa',
    '1181': 'Cx36FRB4cLIrl4zj6UFu0PBrI1yXEPy',
    '1182': 'BP.RP6Vw7tc6rSewpvo7bkdzI/WKa9a',
    '1184': 'jLEl/kuQcCrrH7YUN6J5TRrxzX3XiCy',
    '1186': '6IoRKQoNI1I81yFSZ4OCyTkgY889jiy',
    '1187': 'Y4SlbE53erIReiX9A2VjziL3bGEhA76',
    '1188': 'VvLffGCz/9kEQv9zAsjg76zkBmc6DzS',
    '1189': 'NuMxFK2V3xTJIdhWAGT8NHjyym40k8O',
    '1190': 'hkMfPZwA1OGXaWNXgEQTqIcMss9RD4q',
    '1191': 'CtlHZe9pKYiSXaK6HMeEMIQTXKHM68S',
    '1192': 'NXQiccfJbFIDTLy.dabdYbPOvA7Qt7O',
    '1193': 'H0g8OG3TWtdRU95m1oDwR6sAKSn4s1C',
    '1194': 'fehSIxYsY.BrpQzcSZVU6jkeDX3CPE6',
    '1195': '3xMZOQdLKELgPZi3FXnJWGwyfiVI6Rq',
    '1196': 'WuAfwc.UBb33oY4chbrKwPMCb2OqAYq',
    '1197': 'IB.CBvDycB6BxE8KOoQEtO5neoE.3tu',
    '1198': '42lKwpTrLhjorZ7Efexr1g5T3Y3RNp6',
    '1199': '9unj4FYL5gXkzclFb06q5VMcRO0nKwe',
    '1200': '0CqyxxtO6283QVShRte4Rcmaiuz.PN2',
    '1201': 'pO/Jehu7ts.NdS5gMGj8ldY/je.YpH2',
    '1203': 'DhnRyDVEglgmCgKpOphEgT1UOkuhIJa',
    '1204': 'C/7uVmGBG2pmqkELsk.Ijn5/NnH4scC',
    '1205': 'lUU4Dkr6ZhkUesgdKIlvNt28EGBhofe',
    '1206': 'hTfvGVSod1V1YmiBrnivAfJ/it0GSnG',
    '1207': 'EN2p/Mkhz/uTJwwf5/hsWDjk.fNpv26',
    '1208': 'cdtwgkbQcjdXTxVv5fZqEK.r1wRDTWq',
    '1209': 'SktqRmUgl8EuJdxf42BDFnnaPLlWvz6',
    '1210': 'C.zkAO49bUA.wTw8bhjuwcQSZMvEX6a',
    '1211': 'P4Nc3MfpeEGK6MOhW00qH9B5zLcaOd6',
    '1212': 'PvKTbvOO82V1sqOWyk2CHGW/S59LWWi',
    '1213': '6Hl1t.WtXd5M1buQVJNr3aHpgkWtgw.',
    '1214': 'o5G0ny0KhRKExZidH5AIqf0DL9Sfh.W',
    '1216': 'iRbjQrXGucG1pRaWG2xIqeJBBD2bCUu',
    '1217': '1lciwjM6jXkCAiIwu2tFcKThXyLbBhC',
    '1219': 'EibKqiRUV7v8t9uWwrlS/yNnKfmEOJG',
    '1220': 'CGdVNKlDrTleFBVXnn69r8yGnWcdIFC',
    '890': '5WxUqwR/NyJQfC6fFD1inGuJVrtKkAi',
  },
}

export const checkPastParticipant = (
  slug: string,
  bibNumber: string,
  firstName: string,
  lastName: string,
) => {
  if (!(slug in pastParticipants)) return false

  const pad = Object.keys(pastParticipants[slug])[0].length
  const bib = bibNumber.padStart(pad, '0')
  if (!(bib in pastParticipants[slug])) return false

  return compareHash(
    `${firstName.trim().toUpperCase()}|${lastName.trim().toUpperCase()}`,
    `${prefix}${pastParticipants[slug][bib]}`,
  )
}
