/* eslint-disable react/prop-types */
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CircularProgress from '@mui/material/CircularProgress'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Storage } from 'aws-amplify'
import React, { useEffect, useState, forwardRef } from 'react'

import {
  ListContainer,
  ProgressContainer,
  Tag,
  Text,
} from './elements/EventListCardComponents'
import { S3Object as S3ObjectApi } from '../API'

type S3Object = Omit<Exclude<S3ObjectApi, null>, '__typename'>

interface Event {
  id: string
  name: string
  slug: string
  description?: string
  other?: string
  buttonText?: string
  cover?: S3Object | null | undefined
  isVirtual: boolean
  isPast?: boolean
}
interface Props {
  event: Event
  baseUrl: string
}

// eslint-disable-next-line react/display-name
const EventListCard = forwardRef<HTMLDivElement, Props>(
  ({ event, baseUrl }, ref) => {
    const [presignedUrl, setPresignedUrl] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
      const fetch = async () => {
        if (event?.cover?.thumbnailKey) {
          setLoading(true)
          Storage.get(event?.cover?.thumbnailKey)
            .then((presigned) => {
              setPresignedUrl(presigned)
              setLoading(false)
            })
            .catch((e) => {
              console.log(e)
              setLoading(false)
            })
        }
      }
      if (event?.cover?.thumbnailKey) fetch()
      else {
        setPresignedUrl(`${process.env.PUBLIC_URL}/images/megatechph_icon.png`)
        setLoading(false)
      }
    }, [event])

    return (
      <Card sx={{ width: '100%' }} ref={ref as React.RefObject<HTMLDivElement>}>
        {event.isVirtual && (
          <Tag>
            <Typography style={{ fontWeight: 'bold' }}>VIRTUAL</Typography>
          </Tag>
        )}
        <ListContainer>
          {loading ? (
            <ProgressContainer>
              <CircularProgress
                sx={{ display: 'table-cell', verticalAlign: 'middle' }}
              />
            </ProgressContainer>
          ) : (
            <CardMedia
              sx={{
                height: '100%',
                backgroundSize: 'cover',
              }}
              image={presignedUrl}
            />
          )}
        </ListContainer>
        <CardContent>
          <Text variant="body1" color="textSecondary">
            {`${event.name}`}
          </Text>
          <Text variant="h6">{`${event.description}`}&nbsp;</Text>
          <Typography variant="caption">{`${event.other}`}&nbsp;</Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button size="small">
            <Link
              href={`${baseUrl}/${event.slug}`}
              sx={{ textDecoration: 'none' }}
            >
              {event.buttonText || 'View'}
            </Link>
          </Button>
        </CardActions>
      </Card>
    )
  },
)
export default EventListCard
