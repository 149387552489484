/* eslint-disable react/react-in-jsx-scope */
import { JERSEY_SHIRT_SIZES } from '../../../constants/models'
import TextWithIcon from '../../elements/TextWithIcon'
import FormSelectInput from '../elements/FormSelectInput'

const IndividualUserFormContent = ({ values, formDisabled }) => [
  {
    id: 'jersey-form-details',
    name: 'Jersey Selection',
    inputs: [
      {
        id: 'eventName',
        size: 6,
        field: <TextWithIcon title="Event" text={values.eventName} />,
      },
      {
        id: 'categoryName',
        size: 6,
        field: <TextWithIcon title="Category" text={values.categoryName} />,
      },
      {
        id: 'firstName',
        size: 6,
        field: <TextWithIcon title="First Name" text={values.firstName} />,
      },
      {
        id: 'lastName',
        size: 6,
        field: <TextWithIcon title="Last Name" text={values.lastName} />,
      },
      {
        id: 'jerseySize',
        size: 12,
        field: (
          <FormSelectInput
            name="jerseySize"
            label="Jersey size"
            autoComplete="new-jerseySize"
            placeholder="Select Jersey Size"
            disabled={formDisabled}
            options={Object.entries(JERSEY_SHIRT_SIZES).map(([key, value]) => ({
              value: key,
              text: value,
            }))}
            required
          />
        ),
      },
    ],
  },
]
export default IndividualUserFormContent
