/* eslint-disable react/react-in-jsx-scope */
import FormTextInput from '../elements/FormTextInput'

const RepeaterFormContent = ({ formDisabled }) => [
  {
    id: 'repeater-form-details',
    name: 'Are you a repeater?',
    inputs: [
      {
        id: 'firstName',
        size: { sm: 12, md: 6 },
        field: (
          <FormTextInput
            name="firstName"
            label="First Name"
            required
            autoComplete="new-firstName"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'lastName',
        size: { sm: 12, md: 6 },
        field: (
          <FormTextInput
            name="lastName"
            label="Last Name"
            required
            autoComplete="new-lastName"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'bibNumber',
        size: { sm: 12, md: 6 },
        field: (
          <FormTextInput
            name="bibNumber"
            label="Previous Bib Number"
            required
            autoComplete="new-bibNumber"
            disabled={formDisabled}
          />
        ),
      },
    ],
  },
]

export default RepeaterFormContent
