/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
import * as APITypes from '../API'
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType
  __generatedMutationOutput: OutputType
}

export const createFullCategory = /* GraphQL */ `
  mutation CreateFullCategory($categories: [CreateCategoryInput]) {
    createFullCategory(categories: $categories) {
      categories {
        id
        name
        description
        fee
        distance
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        eventId
        event {
          id
          name
          slug
          isComingSoon
          location
          startDateTime
          endDateTime
          registrationStartDateTime
          registrationCutOff
          collectionLocation
          createdAt
          updatedAt
          owner
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      categoryCounts {
        id
        categoryId
        count
        createdAt
        updatedAt
        owner
      }
    }
  }
`
export const createFullOrder = /* GraphQL */ `
  mutation CreateFullOrder(
    $order: CreateOrderInput
    $participants: [CreateParticipantInput]
  ) {
    createFullOrder(order: $order, participants: $participants) {
      order {
        id
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        paymentMode
        transactionId
        status
        total
        participants {
          nextToken
        }
        shippingPreference
        createdAt
        updatedAt
        owner
      }
      participants {
        id
        bibName
        bibNumber
        firstName
        lastName
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        orderId
        order {
          id
          userId
          paymentMode
          transactionId
          status
          total
          shippingPreference
          createdAt
          updatedAt
          owner
        }
        eventId
        event {
          id
          name
          slug
          isComingSoon
          location
          startDateTime
          endDateTime
          registrationStartDateTime
          registrationCutOff
          collectionLocation
          createdAt
          updatedAt
          owner
        }
        categoryId
        category {
          id
          name
          description
          fee
          distance
          eventId
          createdAt
          updatedAt
          owner
        }
        fee
        shirtSize
        createdAt
        updatedAt
        owner
      }
    }
  }
`
export const createPendingOrder = /* GraphQL */ `
  mutation CreatePendingOrder(
    $order: CreateOrderInput
    $participants: [CreateParticipantInput]
  ) {
    createPendingOrder(order: $order, participants: $participants) {
      order {
        id
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        paymentMode
        transactionId
        status
        total
        participants {
          nextToken
        }
        shippingPreference
        createdAt
        updatedAt
        owner
      }
      participants {
        id
        bibName
        bibNumber
        firstName
        lastName
        emergencyContactName
        emergencyContactPhone
        teamName
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        orderId
        order {
          id
          userId
          paymentMode
          transactionId
          status
          total
          shippingPreference
          createdAt
          updatedAt
          owner
        }
        eventId
        event {
          id
          name
          overview
          slug
          isComingSoon
          priority
          location
          startDateTime
          endDateTime
          registrationStartDateTime
          registrationCutOff
          fullCoverStyle
          raceKitCollectionLocation
          collectionLocation
          emailAdditionalText
          emailContactText
          createdAt
          updatedAt
          owner
        }
        categoryId
        category {
          id
          name
          description
          fee
          adminFee
          distance
          priority
          isIndividual
          maxParticipants
          hasJersey
          maxJerseys
          jerseyEmailText
          eventId
          categoryCountId
          createdAt
          updatedAt
          owner
        }
        fee
        adminFee
        shirtSize
        jerseySize
        birthdate
        gender
        phoneNumber
        address
        createdAt
        updatedAt
        owner
        registeredBy
      }
    }
  }
`
export const createS3Object = /* GraphQL */ `
  mutation CreateS3Object(
    $input: CreateS3ObjectInput!
    $condition: ModelS3ObjectConditionInput
  ) {
    createS3Object(input: $input, condition: $condition) {
      id
      objectId
      objectType
      bucket
      key
      region
      contentType
      thumbnailKey
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateS3Object = /* GraphQL */ `
  mutation UpdateS3Object(
    $input: UpdateS3ObjectInput!
    $condition: ModelS3ObjectConditionInput
  ) {
    updateS3Object(input: $input, condition: $condition) {
      id
      objectId
      objectType
      bucket
      key
      region
      contentType
      thumbnailKey
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteS3Object = /* GraphQL */ `
  mutation DeleteS3Object(
    $input: DeleteS3ObjectInput!
    $condition: ModelS3ObjectConditionInput
  ) {
    deleteS3Object(input: $input, condition: $condition) {
      id
      objectId
      objectType
      bucket
      key
      region
      contentType
      thumbnailKey
      owner
    }
  }
`
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      slug
      isComingSoon
      location
      startDateTime
      endDateTime
      registrationStartDateTime
      registrationCutOff
      categories {
        items {
          id
          name
          description
          fee
          distance
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      entitlements {
        items {
          id
          name
          description
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      cover {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      bibImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      certImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      collectionLocation
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      slug
      isComingSoon
      location
      startDateTime
      endDateTime
      registrationStartDateTime
      registrationCutOff
      categories {
        items {
          id
          name
          description
          fee
          distance
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      entitlements {
        items {
          id
          name
          description
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      cover {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      bibImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      certImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      collectionLocation
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      slug
      isComingSoon
      location
      startDateTime
      endDateTime
      registrationStartDateTime
      registrationCutOff
      categories {
        items {
          id
          name
          description
          fee
          distance
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      entitlements {
        items {
          id
          name
          description
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      photos {
        items {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      cover {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      bibImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      certImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      collectionLocation
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      description
      fee
      distance
      bibImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      certImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      eventId
      event {
        id
        name
        slug
        isComingSoon
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        categories {
          nextToken
        }
        entitlements {
          nextToken
        }
        photos {
          nextToken
        }
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        collectionLocation
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      description
      fee
      distance
      bibImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      certImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      eventId
      event {
        id
        name
        slug
        isComingSoon
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        categories {
          nextToken
        }
        entitlements {
          nextToken
        }
        photos {
          nextToken
        }
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        collectionLocation
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      description
      fee
      distance
      bibImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      certImage {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      eventId
      event {
        id
        name
        slug
        isComingSoon
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        categories {
          nextToken
        }
        entitlements {
          nextToken
        }
        photos {
          nextToken
        }
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        collectionLocation
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const createEntitlement = /* GraphQL */ `
  mutation CreateEntitlement(
    $input: CreateEntitlementInput!
    $condition: ModelEntitlementConditionInput
  ) {
    createEntitlement(input: $input, condition: $condition) {
      id
      name
      description
      photo {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      eventId
      event {
        id
        name
        slug
        isComingSoon
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        categories {
          nextToken
        }
        entitlements {
          nextToken
        }
        photos {
          nextToken
        }
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        collectionLocation
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateEntitlement = /* GraphQL */ `
  mutation UpdateEntitlement(
    $input: UpdateEntitlementInput!
    $condition: ModelEntitlementConditionInput
  ) {
    updateEntitlement(input: $input, condition: $condition) {
      id
      name
      description
      photo {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      eventId
      event {
        id
        name
        slug
        isComingSoon
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        categories {
          nextToken
        }
        entitlements {
          nextToken
        }
        photos {
          nextToken
        }
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        collectionLocation
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteEntitlement = /* GraphQL */ `
  mutation DeleteEntitlement(
    $input: DeleteEntitlementInput!
    $condition: ModelEntitlementConditionInput
  ) {
    deleteEntitlement(input: $input, condition: $condition) {
      id
      name
      description
      photo {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
        createdAt
        updatedAt
        owner
      }
      eventId
      event {
        id
        name
        slug
        isComingSoon
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        categories {
          nextToken
        }
        entitlements {
          nextToken
        }
        photos {
          nextToken
        }
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        collectionLocation
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      addressLine1
      addressLine2
      adminArea2
      adminArea1
      postalCode
      countryCode
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      addressLine1
      addressLine2
      adminArea2
      adminArea1
      postalCode
      countryCode
      orders {
        items {
          id
          userId
          transactionId
          status
          total
          shippingPreference
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        addressLine1
        addressLine2
        adminArea2
        adminArea1
        postalCode
        countryCode
        orders {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      transactionId
      status
      total
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shippingPreference
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        addressLine1
        addressLine2
        adminArea2
        adminArea1
        postalCode
        countryCode
        orders {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      transactionId
      status
      total
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shippingPreference
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        addressLine1
        addressLine2
        adminArea2
        adminArea1
        postalCode
        countryCode
        orders {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      transactionId
      status
      total
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shippingPreference
      createdAt
      updatedAt
      owner
    }
  }
`
export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant(
    $input: CreateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    createParticipant(input: $input, condition: $condition) {
      id
      bibName
      bibNumber
      firstName
      lastName
      userId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        addressLine1
        addressLine2
        adminArea2
        adminArea1
        postalCode
        countryCode
        orders {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      orderId
      order {
        id
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        transactionId
        status
        total
        participants {
          nextToken
        }
        shippingPreference
        createdAt
        updatedAt
        owner
      }
      eventId
      event {
        id
        name
        slug
        isComingSoon
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        categories {
          nextToken
        }
        entitlements {
          nextToken
        }
        photos {
          nextToken
        }
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        collectionLocation
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      categoryId
      category {
        id
        name
        description
        fee
        distance
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        eventId
        event {
          id
          name
          slug
          isComingSoon
          location
          startDateTime
          endDateTime
          registrationStartDateTime
          registrationCutOff
          collectionLocation
          createdAt
          updatedAt
          owner
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fee
      shirtSize
      nationality
      email
      registeredBy
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant(
    $input: UpdateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    updateParticipant(input: $input, condition: $condition) {
      id
      bibName
      bibNumber
      firstName
      lastName
      shirtSize
      jerseySize
      virtualProgress
    }
  }
`
export const deleteParticipant = /* GraphQL */ `
  mutation DeleteParticipant(
    $input: DeleteParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    deleteParticipant(input: $input, condition: $condition) {
      id
      bibName
      bibNumber
      firstName
      lastName
      userId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        addressLine1
        addressLine2
        adminArea2
        adminArea1
        postalCode
        countryCode
        orders {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      orderId
      order {
        id
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        transactionId
        status
        total
        participants {
          nextToken
        }
        shippingPreference
        createdAt
        updatedAt
        owner
      }
      eventId
      event {
        id
        name
        slug
        isComingSoon
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        categories {
          nextToken
        }
        entitlements {
          nextToken
        }
        photos {
          nextToken
        }
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        collectionLocation
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      categoryId
      category {
        id
        name
        description
        fee
        distance
        bibImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        certImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
          createdAt
          updatedAt
          owner
        }
        eventId
        event {
          id
          name
          slug
          isComingSoon
          location
          startDateTime
          endDateTime
          registrationStartDateTime
          registrationCutOff
          collectionLocation
          createdAt
          updatedAt
          owner
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fee
      shirtSize
      createdAt
      updatedAt
      owner
    }
  }
`
export const createCategoryCount = /* GraphQL */ `
  mutation CreateCategoryCount(
    $input: CreateCategoryCountInput!
    $condition: ModelCategoryCountConditionInput
  ) {
    createCategoryCount(input: $input, condition: $condition) {
      id
      categoryId
      count
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateCategoryCount = /* GraphQL */ `
  mutation UpdateCategoryCount(
    $input: UpdateCategoryCountInput!
    $condition: ModelCategoryCountConditionInput
  ) {
    updateCategoryCount(input: $input, condition: $condition) {
      id
      categoryId
      count
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteCategoryCount = /* GraphQL */ `
  mutation DeleteCategoryCount(
    $input: DeleteCategoryCountInput!
    $condition: ModelCategoryCountConditionInput
  ) {
    deleteCategoryCount(input: $input, condition: $condition) {
      id
      categoryId
      count
      createdAt
      updatedAt
      owner
    }
  }
`

export const createFormParticipant = /* GraphQL */ `
  mutation CreateFormParticipant(
    $input: CreateFormParticipantInput!
    $condition: ModelFormParticipantConditionInput
  ) {
    createFormParticipant(input: $input, condition: $condition) {
      id
    }
  }
`
export const createOnsiteRegistration =
  /* GraphQL */ `mutation CreateOnsiteRegistration($participants: [CreateParticipantInput]) {
  createOnsiteRegistration(participants: $participants) {
    partipants {
      id
    }
  }
}
` as GeneratedMutation<
    APITypes.CreateOnsiteRegistrationMutationVariables,
    APITypes.CreateOnsiteRegistrationMutation
  >

export const createFreeRegistration =
  /* GraphQL */ `mutation CreateFreeRegistration($participants: [CreateParticipantInput]) {
  createFreeRegistration(participants: $participants) {
    partipants {
      id
    }
  }
}
` as GeneratedMutation<
    APITypes.CreateFreeRegistrationMutationVariables,
    APITypes.CreateFreeRegistrationMutation
  >
