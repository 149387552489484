/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react'

import { CartReducer, sumItems } from './CartReducer'

export const CartContext = createContext(null)

const storageUser = localStorage.getItem('vse-cartUser')
  ? JSON.parse(localStorage.getItem('vse-cartUser'))
  : {}
const storageItems = localStorage.getItem('vse-cartItems')
  ? JSON.parse(localStorage.getItem('vse-cartItems'))
  : []

const storageFPUser = localStorage.getItem('vse-fp-cartUser')
  ? JSON.parse(localStorage.getItem('vse-fp-cartUser'))
  : {}

const initialState = {
  cartUser: storageUser,
  cartItems: storageItems,
  cartFPUser: storageFPUser,
  ...sumItems(storageItems),
  checkout: false,
  startedAt: null,
}

interface User {
  email: string
  firstName: string
  lastName: string
  phoneNumber?: string
}

interface Participant {
  id: string
  firstName: string
  lastName: string
  eventId: string
  eventName: string
  categoryId: string
  categoryName: string
  fee: number
  adminFee: number
  shirtSize: string
  teamName?: string
  birthdate?: string
  gender?: string
  nationality?: string
  email?: string
  phoneNumber?: string
  address?: string
  emergencyContactName?: string
  emergencyContactPhone?: string
  tandemName?: string
  tandemBirthdate?: string
  additionalId?: string
  isTriathlon?: boolean
  relayEvent?: string[]
  teamType?: string
  firstParticipantInfo?: string
  subCategory?: string
}

interface FormParticipantUser {
  id: string
  firstName: string
  lastName: string
  workSite: string
}

const CartContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [state, dispatch] = useReducer(CartReducer, initialState)

  const increase = (payload) => {
    dispatch({ type: 'INCREASE', payload })
  }

  const decrease = (payload) => {
    dispatch({ type: 'DECREASE', payload })
  }

  // Payload properties of type User (in schema):
  // { email, firstName, lastName }
  const addUser = (payload: User) => {
    dispatch({ type: 'ADD_USER', payload })
  }

  const removeUser = (payload: User) => {
    dispatch({ type: 'REMOVE_USER', payload })
  }

  const updateUser = (payload: User) => {
    dispatch({ type: 'UPDATE_USER', payload })
  }

  const addFPUser = (payload: FormParticipantUser) => {
    dispatch({ type: 'ADD_FP_USER', payload })
  }

  const removeFPUser = (payload: FormParticipantUser) => {
    dispatch({ type: 'REMOVE_FP_USER', payload })
  }

  const updateFPUser = (payload: FormParticipantUser) => {
    dispatch({ type: 'UPDATE_FP_USER', payload })
  }

  // Payload properties of type Participant (in schema):
  // { id, bibName, firstName, lastName, eventId, categoryId, fee, adminFee, shirtSize }
  const addProduct = (payload: Participant) => {
    dispatch({ type: 'ADD_ITEM', payload })
  }

  const removeProduct = (payload: Partial<Participant>) => {
    dispatch({ type: 'REMOVE_ITEM', payload })
  }

  const updateProduct = (payload: Participant) => {
    dispatch({ type: 'UPDATE_ITEM', payload })
  }

  const updateProductFee = (payload: Participant) => {
    dispatch({ type: 'UPDATE_ITEM_FEE', payload })
  }

  const clearCart = () => {
    dispatch({ type: 'CLEAR' })
  }

  const handleCheckout = () => {
    dispatch({ type: 'CHECKOUT' })
  }

  const contextValues = {
    addUser,
    removeUser,
    updateUser,
    addFPUser,
    removeFPUser,
    updateFPUser,
    removeProduct,
    addProduct,
    updateProduct,
    updateProductFee,
    increase,
    decrease,
    clearCart,
    handleCheckout,
    ...state,
  }

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  )
}

export default CartContextProvider
