/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { useRollbar } from '@rollbar/react'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { omit, uniqBy } from 'lodash'
import { useSnackbar } from 'notistack'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { v4 as uuidv4 } from 'uuid'

import Page from './elements/Page'
import EmptyState from './EmptyState'
import PaymongoCheckoutForm from './PaymongoCheckoutForm'
// import { PendingOrder as PendingOrderApi, User as UserApi } from '../API'
import RegistrationSummary from './RegistrationSummary'
import { User as UserApi } from '../API'
import {
  createUser,
  createPendingOrder,
  updateUser,
  createFreeRegistration,
} from '../api/mutations'
import { userByEmail, listCategoryFees } from '../api/queries'
import { DEFAULT_ENTRIES_PER_PAGE } from '../constants/api'
import { formatDate } from '../constants/formats'
import { PAYMENT_MODES, TEAM_TYPES } from '../constants/models'
import { CartContext } from '../contexts/CartContext'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import useCategoryFees, { CategoryFeeResponse } from '../hooks/useCategoryFees'
import {
  AttachPaymentIntentRequest,
  AttachPaymentIntentResponse,
  PaymentIntentResponse,
} from '../models/Paymongo/PaymentIntent'
import {
  CreatePaymentMethodRequest,
  PaymentMethodResponse,
} from '../models/Paymongo/PaymentMethod'
import { generateCartItems, getItemFee } from '../utils/ApiHelpers'
import { doMutation, fetchQuery, retry } from '../utils/ApiUtils'
import { generateOptions } from '../utils/PaymongoUtils'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)

type User = Omit<Exclude<UserApi, null>, '__typename'>
// type PendingOrder = Omit<Exclude<PendingOrderApi, null>, '__typename'>

interface Props {
  show?: boolean
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  isVirtualEvent?: boolean
  isFree?: boolean
}

interface OngoingPaymentNoticeProps {
  isFree?: boolean
}
const OngoingPaymentNotice: React.FC<OngoingPaymentNoticeProps> = (isFree) => (
  <div
    style={{
      textAlign: 'center',
      padding: '19px',
      marginBottom: '30px',
      border: '2px solid red',
      color: 'black',
    }}>
    Please <b>DO NOT refresh</b> the page and wait while we are processing your
    {isFree ? ' submission' : ' payment'}.
  </div>
)

const createPaymentIntent = async (
  eventName: string,
  amount: number,
  metadata: { [key: string]: string },
) => {
  return new Promise((resolve, reject) => {
    const apiName = 'api85b3bd57Payment'
    const path = '/paymongo'
    const myInit = {
      headers: {}, // OPTIONAL
      body: {
        amount,
        payment_method_allowed: ['card', 'paymaya', 'gcash', 'grab_pay'],
        payment_method_options: { card: { request_three_d_secure: 'any' } },
        currency: 'PHP',
        capture_type: 'automatic',
        description: `MEGATechPH Registration - ${eventName}`,
        metadata,
      },
    }

    API.post(apiName, path, myInit)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const doCreatePaymentMethod = async (
  payload: CreatePaymentMethodRequest,
  // times: number,
) => {
  return new Promise((resolve, reject) => {
    // const sampleResponses = {
    //   1: {},
    //   2: {
    //     errors: [
    //       {
    //         code: 'parameter_invalid',
    //         detail: 'details.exp_month must be between 1 and 12.',
    //         source: {
    //           pointer: 'details.exp_month',
    //           attribute: 'exp_month',
    //         },
    //       },
    //       {
    //         code: 'parameter_invalid',
    //         detail:
    //           'details.exp_year must be at least this year or no later than 50 years from now.',
    //         source: {
    //           pointer: 'details.exp_year',
    //           attribute: 'exp_year',
    //         },
    //       },
    //     ],
    //   },
    //   0: undefined,
    // }
    // // const idx = Math.floor(Math.random() * 1)
    // const randomResult = sampleResponses[times]
    // console.log(times, payload, randomResult)
    // if (randomResult !== undefined) resolve(randomResult)
    // else reject('hahai')
    try {
      fetch(
        `${process.env.REACT_APP_PAYMONGO_API_URL}/payment_methods`,
        generateOptions(payload),
      )
        .then((response) => response.json())
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    } catch (error) {
      reject(error)
    }
  })
}

const createPaymentMethod = async (payload: CreatePaymentMethodRequest) => {
  const maxRetries = 3
  const result = await retry(
    async (retries: number) => {
      const response = await doCreatePaymentMethod(payload)
      if (typeof response === 'object' && 'data' in response) return response
      else {
        if (retries < maxRetries - 1)
          throw Error(
            typeof response === 'object' && 'errors' in response
              ? response.errors[0].detail
              : JSON.stringify(response),
          )
        return response
      }
    },
    () => {
      console.log('onRetry called...')
    },
    maxRetries,
  )
  return result
}

const attachToPaymentIntent = async (
  id: string,
  payload: AttachPaymentIntentRequest,
) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(
        `${process.env.REACT_APP_PAYMONGO_API_URL}/payment_intents/${id}/attach`,
        generateOptions(payload),
      )
        .then((response) => response.json())
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    } catch (error) {
      reject(error)
    }
  })
}

const updateUserPromise = (cartUser: any, id: string) =>
  new Promise((resolve, reject) => {
    doMutation('updateUser', updateUser, {
      input: {
        id,
        ...omit(cartUser, ['email', 'confirmEmail', 'confirmPhoneNumber']),
      },
    })
      .then(() => {
        resolve(id)
      })
      .catch((error) => reject(error))
  })

const createUserPromise = (cartUser: any) =>
  new Promise((resolve, reject) => {
    doMutation('createUser', createUser, {
      input: {
        ...omit(cartUser, ['confirmEmail', 'confirmPhoneNumber']),
      },
    })
      .then(({ data }) => {
        const newData = data as unknown as User
        resolve(newData.id)
      })
      .catch((error) => reject(error))
  })

const doCreatePendingOrder = async (
  cartUser: any,
  cartItems: any[],
  transactionId: string,
  total: number,
  status: string,
  paymentMode: string,
  shippingPreference: string,
) => {
  const createPendingOrderPromise = (currentUserId: string) =>
    new Promise((resolve, reject) => {
      // save fullorder
      const orderId = uuidv4()
      const order = {
        id: orderId,
        userId: currentUserId,
        transactionId,
        total,
        status,
        paymentMode,
        shippingPreference,
        createdAt: dayjs().tz('Asia/Manila').format(),
      }

      const participants = cartItems.map((cartItem) => ({
        orderId,
        userId: currentUserId,
        ...omit(cartItem, [
          'quantity',
          'eventName',
          'categoryName',
          'confirmPhoneNumber',
          'confirmEmergencyContactPhone',
          'isTandem',
          'tandemAgeRange',
          'eventEndDateTime',
          'isBonus',
          'isTriathlon',
          'relayEvent-SWIM',
          'relayEvent-BIKE',
          'relayEvent-RUN',
          'relayEvent-RUN1',
          'relayEvent-RUN2',
          'teamType',
          'firstParticipants',
          'isOnsiteRegistration',
          'subCategories',
        ]),
        ...('relayEvent' in cartItem && {
          relayEvent: cartItem.relayEvent.join(','),
        }),
        birthdate: formatDate(cartItem.birthdate, 'YYYY-MM-DD'),
        ...('tandemBirthdate' in cartItem && {
          tandemBirthdate: formatDate(cartItem.tandemBirthdate, 'YYYY-MM-DD'),
        }),
        createdAt: dayjs().tz('Asia/Manila').format(),
      }))

      const pendingOrderInput = {
        order,
        participants,
      }

      doMutation('createPendingOrder', createPendingOrder, pendingOrderInput)
        .then(() => {
          resolve(currentUserId)
        })
        .catch((error) => reject(error))
    })

  return new Promise((resolve, reject) => {
    try {
      fetchQuery('userByEmail', userByEmail, {
        email: cartUser.email,
      })
        .then((userByEmailData) => {
          const { items } = userByEmailData as unknown as { items: User[] }
          if (items.length > 0) return updateUserPromise(cartUser, items[0].id)

          // save user if it does not exist yet
          return createUserPromise(cartUser)
        })
        .then((currentUserId: string) => {
          return createPendingOrderPromise(currentUserId)
        })
        .then((currentUserId: string) => resolve(currentUserId))
    } catch (error) {
      reject(error)
    }
  })
}

const doCreateFreeRegistration = async (cartUser: any, cartItems: any[]) => {
  const createFreeRegistrationPromise = (currentUserId: string) =>
    new Promise((resolve, reject) => {
      const participants = cartItems.map((cartItem) => ({
        userId: currentUserId,
        ...omit(cartItem, [
          'quantity',
          'eventName',
          'categoryName',
          'confirmPhoneNumber',
          'confirmEmergencyContactPhone',
          'isTandem',
          'tandemAgeRange',
          'eventEndDateTime',
          'isBonus',
          'isTriathlon',
          'relayEvent-SWIM',
          'relayEvent-BIKE',
          'relayEvent-RUN',
          'relayEvent-RUN1',
          'relayEvent-RUN2',
          'teamType',
          'firstParticipants',
          'isOnsiteRegistration',
          'subCategories',
        ]),
        ...('relayEvent' in cartItem && {
          relayEvent: cartItem.relayEvent.join(','),
        }),
        birthdate: formatDate(cartItem.birthdate, 'YYYY-MM-DD'),
        ...('tandemBirthdate' in cartItem && {
          tandemBirthdate: formatDate(cartItem.tandemBirthdate, 'YYYY-MM-DD'),
        }),
        createdAt: dayjs().tz('Asia/Manila').format(),
      }))

      const freeRegistrationInput = {
        participants,
      }

      doMutation(
        'createFreeRegistration',
        createFreeRegistration,
        freeRegistrationInput,
      )
        .then(() => {
          resolve(currentUserId)
        })
        .catch((error) => reject(error))
    })

  return new Promise((resolve, reject) => {
    try {
      fetchQuery('userByEmail', userByEmail, {
        email: cartUser.email,
      })
        .then((userByEmailData) => {
          const { items } = userByEmailData as unknown as { items: User[] }
          if (items.length > 0) return updateUserPromise(cartUser, items[0].id)

          // save user if it does not exist yet
          return createUserPromise(cartUser)
        })
        .then((currentUserId: string) => {
          return createFreeRegistrationPromise(currentUserId)
        })
        .then((currentUserId: string) => resolve(currentUserId))
    } catch (error) {
      reject(error)
    }
  })
}

const fetchCategoryFees = async (categoryIds) => {
  let allItems = []
  let currentNextToken = null
  const filter = {}
  const newCategories = []

  const fetchData = async () => {
    filter['or'] = categoryIds.map((id) => ({ id: { eq: id } }))
    try {
      const { items, nextToken: newNextToken } = (await fetchQuery(
        'listCategories',
        listCategoryFees,
        {
          filter,
          limit: DEFAULT_ENTRIES_PER_PAGE,
          ...(currentNextToken && { nextToken: currentNextToken }),
        },
      )) as unknown as { items: CategoryFeeResponse[]; nextToken }
      // setCategories(items)

      allItems = uniqBy([...allItems, ...items], 'id')

      if (newNextToken) {
        currentNextToken = newNextToken
        if (allItems.length < DEFAULT_ENTRIES_PER_PAGE) {
          await fetchData()
        }
        return
      }

      allItems.forEach((i) => {
        if ('event' in i && i.event !== null) {
          const { adminFees, fee } = getItemFee(i)

          newCategories[i.id] = {
            id: i.id,
            fee,
            adminFee: i.adminFee,
            adminFees,
            registrationCutOff: i.event.registrationCutOff,
            bonusParticipants: i.bonusParticipants || 0,
            teamType: i.teamType,
          }
        }
      })
      allItems = []
      currentNextToken = null
      return newCategories
    } catch (e) {
      console.log(e)
      return []
    }
  }

  if (categoryIds !== null && categoryIds.length > 0) {
    await fetchData()
    return newCategories
  }
  return []
  // if (categoryIds !== null && categoryIds.length > 0) {
  //   const filter = {}
  //   filter['or'] = categoryIds.map((id) => ({ id: { eq: id } }))

  //   const { items } = (await fetchQuery('listCategories', listCategoryFees, {
  //     filter,
  //   })) as unknown as { items: CategoryFeeResponse[] }

  //   const newCategories = []
  //   items.forEach((i) => {
  //     if ('event' in i && i.event !== null) {
  //       const { adminFees, fee } = getItemFee(i)
  //       console.log({ i, adminFees, fee })
  //       newCategories[i.id] = {
  //         id: i.id,
  //         fee,
  //         adminFee: i.adminFee,
  //         adminFees,
  //         registrationCutOff: i.event.registrationCutOff,
  //         bonusParticipants: i.bonusParticipants || 0,
  //         teamType: i.teamType,
  //       }
  //     }
  //   })
  //   return newCategories
  // }
  // return []
}

const PaymongoCheckout: React.FC<Props> = ({
  isVirtualEvent = false,
  isFree = false,
  show = true,
  onCancel,
}) => {
  const rollbar = useRollbar()

  const { enqueueSnackbar } = useSnackbar()

  const [checkWaiver, setCheckWaiver] = useState(false)
  const [checkAgreeVerify, setCheckAgreeVerify] = useState(isVirtualEvent)
  const { setLoading: setPageLoading, pageLoading } =
    useContext(PageLoaderContext)

  const [loading, setLoading] = useState(false)
  // const [shippingPreference, setShippingPreference] = useState('CLAIM')
  const shippingPreference = 'CLAIM'

  const [shoppingCartItems, setShoppingCartItems] = useState([])
  const [shoppingCartTotal, setShoppingCartTotal] = useState(0)

  const { cartItems, cartUser } = useContext(CartContext)

  const [paymentMode, setPaymentMode] = useState(PAYMENT_MODES.CARD.key)

  const categoryIds = useMemo(
    () => cartItems.map((item) => item.categoryId),
    [cartItems],
  )

  const displayError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 })
  }

  const { categories, apiLoading } = useCategoryFees({
    categoryIds,
    // onError: displayError,
  })

  const updateShoppingItems = useCallback(() => {
    const { items, total } = generateCartItems(
      cartItems,
      categories,
      paymentMode,
    )
    setShoppingCartItems(items)
    setShoppingCartTotal(total)
  }, [categories, cartItems, paymentMode])

  useEffect(() => {
    updateShoppingItems()
    setPageLoading(apiLoading)

    return () => {
      setPageLoading(false)
    }
  }, [updateShoppingItems, setPageLoading, apiLoading])

  const handleCheck = (e) => {
    if (e.target.name === 'waiverCB') setCheckWaiver(e.target.checked)
    if (e.target.name === 'agreeVerify') setCheckAgreeVerify(e.target.checked)
  }

  const errorCallback = (error: any) => {
    rollbar.critical(error)
    if (typeof error === 'object' && 'message' in error)
      displayError(error.message)
    else displayError(error)
  }

  const handleFreeSubmit = async () => {
    rollbar.info({
      cartUser,
      cartItems,
    })

    setLoading(true)
    setPageLoading(true)
    try {
      await doCreateFreeRegistration(cartUser, cartItems)
      setLoading(false)
      setPageLoading(false)
      window.location.href = `${window.location.origin}${process.env.REACT_APP_PAYMONGO_RETURN_URL}?free_registration`
    } catch (e) {
      setLoading(false)
      setPageLoading(false)
      errorCallback(e)
    }
  }

  const renderCheckout = () => {
    return (
      <>
        <PaymongoCheckoutForm
          shoppingCartTotal={shoppingCartTotal}
          setPaymentMode={setPaymentMode}
          initialValues={{
            paymentType: paymentMode,
            country: 'PH',
            privacyPolicy: false,
          }}
          displayError={(values) => displayError(`${values.message}`)}
          updateData={async (values) => {
            rollbar.info({
              cartUser,
              cartItems,
            })

            // server: call paymongo api to Create a PaymentIntent. This should return clientId
            try {
              let currentCategoryFees = await fetchCategoryFees(categoryIds)
              if (currentCategoryFees.length) currentCategoryFees = categories

              const { items: newCartItems, total: newShoppingCartTotal } =
                generateCartItems(cartItems, currentCategoryFees, paymentMode)

              // check registrationCutOff
              if (
                dayjs().isSameOrAfter(
                  dayjs(
                    currentCategoryFees[newCartItems[0].categoryId]
                      .registrationCutOff,
                  ),
                )
              )
                return [
                  null,
                  {
                    title: 'Event unavailable',
                    message: 'Sorry. Event registration has ended.',
                  },
                ]

              const metadata = {}
              newCartItems.forEach((item, idx) => {
                metadata[
                  `itemName${idx + 1}`
                ] = `${item.eventName} [${item.categoryName}] - ${item.shirtSize}`
                metadata[`itemAmount${idx + 1}`] =
                  (item.isTriathlon ||
                    Object.keys(TEAM_TYPES).includes(item.teamType)) &&
                  idx > 0
                    ? ''
                    : item.isBonus
                    ? 'FREE'
                    : `PHP ${item.fee.toFixed(2)}`
                metadata[`itemTransactionFee${idx + 1}`] =
                  (item.isTriathlon ||
                    Object.keys(TEAM_TYPES).includes(item.teamType)) &&
                  idx > 0
                    ? ''
                    : item.isBonus
                    ? 'FREE'
                    : `PHP ${item.adminFee.toFixed(2)}`
              })

              const piRes: PaymentIntentResponse = await createPaymentIntent(
                newCartItems[0].eventName,
                parseInt((newShoppingCartTotal * 100).toFixed()),
                metadata,
              )

              // client: call paymongo PaymentMethod - client
              const pmRes: PaymentMethodResponse = await createPaymentMethod({
                type: values.paymentType.toLowerCase(),
                billing: {
                  address: {
                    line1: values.addressLine1,
                    city: values.city,
                    state: values.state,
                    postal_code: values.postalCode,
                    country: values.country,
                  },
                  name: values.name,
                  email: values.email,
                  phone: values.phone,
                },
                ...(values.paymentType === PAYMENT_MODES.CARD.key && {
                  details: {
                    card_number: values.cardNumber.toString(),
                    exp_month: parseInt(values.expMonth.toString()),
                    exp_year: parseInt(values.expYear.toString()),
                    cvc: values.cvc.toString(),
                  },
                }),
              })
              if ('errors' in pmRes) {
                setLoading(false)
                setPageLoading(false)
                return [
                  null,
                  { message: `Payment error: ${pmRes.errors[0].detail}` },
                ]
              }

              // save pending order
              await doCreatePendingOrder(
                cartUser,
                newCartItems,
                piRes.data.id,
                newShoppingCartTotal,
                piRes.data.attributes.status,
                values.paymentType,
                shippingPreference,
              )

              // client: call attach to paymentIntent - client
              const aRes: AttachPaymentIntentResponse =
                await attachToPaymentIntent(piRes.data.id, {
                  payment_method: pmRes.data.id,
                  client_key: piRes.data.attributes.client_key,
                  ...([
                    PAYMENT_MODES.GCASH.key,
                    PAYMENT_MODES.PAYMAYA.key,
                    PAYMENT_MODES.GRAB_PAY.key,
                  ].includes(values.paymentType) && {
                    return_url: `${window.location.origin}${process.env.REACT_APP_PAYMONGO_RETURN_URL}`,
                  }),
                })

              if ('errors' in aRes)
                return [
                  null,
                  {
                    title: 'Error encountered',
                    message:
                      'Sorry. Error encountered while processing request. Please contact admin@megatechph.com for assistance ',
                  },
                ]

              if (aRes.data.attributes.next_action === null) {
                // retrieve paymentIntent to get status
                // if status is success, then show success message
                window.location.href = `${window.location.origin}${process.env.REACT_APP_PAYMONGO_RETURN_URL}?payment_intent_id=${aRes.data.id}`
              } else {
                // redirect user to next_action.redirect
                if (
                  aRes.data.attributes.next_action.redirect?.return_url !== null
                )
                  window.location.href =
                    aRes.data.attributes.next_action.redirect.url
              }

              setLoading(false)
              setPageLoading(false)
            } catch (e) {
              errorCallback(e)
              setLoading(false)
              setPageLoading(false)
              return [null, e]
            }
          }}
          setLoading={(a: boolean) => {
            setLoading(a)
            setPageLoading(a)
          }}
        />
      </>
    )
  }

  const renderContent = () => {
    if (cartItems.length === 0)
      return (
        <Page centered>
          <EmptyState
            header={''}
            containerSx={{ textAlign: 'center' }}
            text={'Your shopping cart is empty.'}>
            <Link href="/events" sx={{ textDecoration: 'none' }}>
              <Button color="secondary" variant="contained">
                BACK to Events
              </Button>
            </Link>
          </EmptyState>
        </Page>
      )

    return (
      <>
        <div style={{ height: 48 }} />
        {loading && <OngoingPaymentNotice isFree={isFree} />}

        <Grid container spacing={5}>
          <RegistrationSummary
            shoppingCartItems={shoppingCartItems}
            shoppingCartTotal={shoppingCartTotal}
            loading={loading || pageLoading}
            checkWaiver={checkWaiver}
            checkAgreeVerify={checkAgreeVerify}
            handleCheck={handleCheck}
            isVirtualEvent={isVirtualEvent}
            onCancel={onCancel}
            isFree={isFree}
            handleFreeSubmit={async () => {
              setLoading(true)
              await handleFreeSubmit()
            }}
          />
          {!isFree && (
            <Grid item xs={12} sm={6} sx={{ paddingTop: 0 }}>
              {checkWaiver && checkAgreeVerify && renderCheckout()}
            </Grid>
          )}
        </Grid>
      </>
    )
  }

  return <Container disableGutters>{show && renderContent()}</Container>
}

export default PaymongoCheckout
