import { useAuthenticator } from '@aws-amplify/ui-react'
import MenuIcon from '@mui/icons-material/Menu'
import { useMediaQuery } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MuiLink from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { styled, useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getUserOnsiteEvents } from '../../utils/ApiHelpers'
import HeaderNavMenu from '../HeaderNavMenu'

const drawerWidth = 240

const eventNavItems = [
  { name: 'Upcoming Events', to: '/events' },
  { name: 'Past Events', to: '/pastEvents' },
  {
    name: 'Live Tracker',
    to: 'https://tracx.events/en/events/1251',
    target: '_blank',
    divider: true,
  },
  {
    name: 'Results',
    to: 'http://results.megatechph.com/StartPage.aspx?CId=20402',
    target: '_blank',
  },
]

const navItems = [
  ...eventNavItems,
  {
    name: 'About Us',
    to: '/aboutus',
    divider: true,
  },
  // { name: 'FAQ', to: '/faq' },
]

const getAdminNavItems = (attributes) => {
  const userOnsiteEvents = getUserOnsiteEvents(attributes)

  const adminNavItems = [
    {
      name: 'Onsite Registration',
      to: `/admin/onsiteRegistration${
        userOnsiteEvents.length === 1 ? `/${userOnsiteEvents[0]}` : ''
      }`,
    },
  ]
  return adminNavItems
}

const Link = styled(MuiLink)(
  ({ theme }) => `
  text-transform: uppercase; 
  font-weight: bold;
  padding: 0 8px;
  color: ${theme.palette.primary.main};
  :hover {
    color: ${theme.palette.secondary.main};
  }
`,
)

const LogoLink = styled(MuiLink)`
  padding: 0px;
`

// const ElevationScroll = ({ children }: { children: React.ReactElement }) => {
//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//   })

//   return React.cloneElement(children, {
//     elevation: trigger ? 4 : 0,
//   })
// }

const ToolbarMargin = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}))

interface NavItemProps {
  name: string
  to: string
  target?: string
}

const ItemList: React.FC<NavItemProps> = (item: NavItemProps) => (
  <ListItem disablePadding>
    <ListItemButton href={item.to} sx={{ textAlign: 'center' }}>
      <ListItemText primary={item.name} />
    </ListItemButton>
  </ListItem>
)

const ItemLink: React.FC<NavItemProps> = (item: NavItemProps) => (
  <Link
    key={item.name}
    href={item.to}
    underline="none"
    {...('target' in item && { target: item.target })}>
    {item.name}
  </Link>
)

const VerticalDivider = styled('div')`
  display: inline-block;
  margin: 0 8px;
  height: 10px;
  border: 1px solid grey;
`

const Header: React.FC = () => {
  const navigate = useNavigate()
  const { signOut, user } = useAuthenticator((context) => [context.user])
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const adminNavItems = useMemo(
    () => getAdminNavItems(user?.attributes),
    [user],
  )

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <LogoLink
        href={`/`}
        sx={(theme) => ({
          display: { xs: 'block' },
          marginY: theme.spacing(1),
        })}
        underline="none">
        <img
          height={65}
          src={window.location.origin + '/images/megatechph_icon.png'}
          alt="MEGATechPH"
        />
      </LogoLink>
      <Divider />
      <List>
        {navItems.map((item) => (
          <>
            {item.divider && <Divider />}
            <ItemList key={item.name} {...item} />
          </>
        ))}
        {user && (
          <>
            <Divider />
            {adminNavItems.map((item) => (
              <ItemList key={item.name} {...item} />
            ))}
            <ListItem>
              <ListItemButton
                onClick={() => {
                  signOut()
                  navigate('/', { replace: true })
                }}
                sx={{ textAlign: 'center' }}>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  )

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <AppBar component="nav">
        <Container disableGutters={!mobile}>
          <Toolbar>
            <LogoLink
              href={`/`}
              sx={(theme) => ({
                flexGrow: 1,
                display: { xs: 'block' },
                marginY: theme.spacing(1),
              })}
              underline="none">
              <img
                height={65}
                src={window.location.origin + '/images/megatechph_icon.png'}
                alt="MEGATechPH"
              />
            </LogoLink>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <HeaderNavMenu
                key="headernavmenu-events"
                name="Events"
                items={eventNavItems}
              />
              <HeaderNavMenu
                key="headernavmenu-about-us"
                name="About Us"
                to="/aboutus"
              />
              {user && (
                <>
                  <VerticalDivider />
                  {getAdminNavItems(user.attributes).map((item) => (
                    <ItemLink key={item.name} {...item} />
                  ))}
                  <Link
                    key="Logout"
                    onClick={() => {
                      signOut()
                      navigate('/', { replace: true })
                    }}
                    underline="none">
                    Logout
                  </Link>
                </>
              )}
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ ml: 2, display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <ToolbarMargin />
      <Box component="nav">
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}>
          {drawer}
        </Drawer>
      </Box>
    </>
  )
}

export default Header
