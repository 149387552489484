import { Typography } from '@mui/material'
import Grid, { GridSize } from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import React from 'react'
// import Grid, { GridSize, RegularBreakpoints } from '@mui/material/Grid'

interface MultipleGridSize {
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
  xl?: GridSize
}
interface Input {
  id: string
  field: JSX.Element
  size: boolean | GridSize | MultipleGridSize
}
interface Content {
  id?: string
  name?: string
  icon?: JSX.Element
  inputs: Input[]
  hasDivider?: boolean
  group?: boolean
}
interface Props {
  content: Content[]
}

const FormGrid: React.FC<Props> = ({ content }) => {
  const theme = useTheme()
  return (
    <Grid
      container
      style={{
        // margin: theme.spacing(0, -3),
        width: `calc(100% + ${theme.spacing(6)}px)`,
      }}
    >
      {content.map((section: Content, idx: number) => (
        <Grid
          container
          item
          sx={[
            {
              ...(section.hasDivider
                ? {
                    '&:not(:last-child)': {
                      marginBottom: 12,
                    },
                  }
                : ''),
            },
          ]}
          xs={12}
          key={`${section.id}-${idx.toString()}`}
        >
          {section.name && (
            <Grid
              item
              xs={12}
              style={{
                padding: theme.spacing(0, 3),
                position: 'relative',
                ...(idx > 0 && {
                  marginTop: theme.spacing(3),
                }),
              }}
            >
              <Typography
                variant="overline"
                style={{
                  width: '100%',
                  display: 'inline-block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {section.name}
              </Typography>
            </Grid>
          )}
          {section.inputs && (
            <Grid
              container
              item
              xs={12}
              key={`${section.id}-inputs`}
              style={{
                ...(section.group && {
                  margin: '0px 12px',
                  padding: '16px 16px 0px 16px',
                  borderRadius: 8,
                  backgroundColor: '#F9F9F9',
                }),
              }}
            >
              {section.inputs.map(
                (input: Input) =>
                  Object.keys(input).length > 0 && (
                    <Grid
                      item
                      xs={12}
                      {...(typeof input.size === 'object'
                        ? input.size
                        : {
                            sm: input.size,
                            md: input.size,
                          })}
                      sx={[
                        {
                          padding: theme.spacing(1, 3),
                        },
                      ]}
                      key={`${section.id}-${input.id}`}
                    >
                      {input.field}
                    </Grid>
                  ),
              )}
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  )
}
export default FormGrid
