/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
import { useCallback, useState } from 'react'

import useScrollPosition from './useScrollPosition'

/*
 * This hook provides pagination utility.
 *
 * @param {function}  onLoadMore  The function to invoke to load more items when the trigger element is triggered.
 *
 * @return  {object}  The return object contains the following properties:
 * - setTriggerElement - Pass the trigger element ref to this function. Eg ref={setTriggerElement(additionalTrigger)}
 *    where `additionalTrigger` is a potential additional trigger (see below for use cases)
 *
 * Use the hook like:
 * const { setTriggerElement } = usePagination(onLoadMore);
 */

const usePagination = (onLoadMore: any) => {
  const [triggerRef, setTriggerRef] = useState<any>()
  const callbackToSetTriggerRef = useCallback(
    (additionalTrigger: any) => (node: any) => {
      /*
       * additionalTrigger/triggerRef.prevAdditionalTrigger is used for when new entries fetched are inserted above the previous
       * trigger elment, causing the node not to change on insertion of new entries. This can happen due to two (or more?) reasons:
       * - Fetched entries are not in the currently sorted order (as of 16/10/2019 API is not supporting sorting, so this applies)
       * - When the API provides sorting, the user could still experience this if they change sorting locally while fetching.
       * This is used from the table to pass the number of rows, as an example.
       */
      if (
        node &&
        (!triggerRef ||
          (triggerRef &&
            (('current' in triggerRef && node !== triggerRef.current) ||
              ('prevAdditionalTrigger' in triggerRef &&
                additionalTrigger !== triggerRef.prevAdditionalTrigger))))
        // (triggerRef &&
        //   'current' in triggerRef &&
        //   node !== triggerRef.current) ||
        // (triggerRef &&
        //   'prevAdditionalTrigger' in triggerRef &&
        //   additionalTrigger !== triggerRef.prevAdditionalTrigger))
      ) {
        setTriggerRef({
          current: node,
          prevAdditionalTrigger: additionalTrigger,
          hasBeenVisible: false,
        })
        const position = node.getBoundingClientRect()

        const currPos = { x: position.left, y: position.top }
        if (
          !node.hasBeenVisible &&
          currPos.y >= 0 &&
          currPos.y <= window.innerHeight // 43 is the pixel height of a basic row
        ) {
          setTriggerRef((r: any) => ({ ...r, hasBeenVisible: true }))
          if (typeof onLoadMore === 'function') onLoadMore()
        }
      }
    },
    [onLoadMore, triggerRef],
  )

  useScrollPosition(
    ({ prevPos, currPos }: { prevPos: any; currPos: any }) => {
      // Add condition to check if it is in view
      if (
        triggerRef &&
        triggerRef.hasOwnProperty('hasBeenVisible') &&
        !triggerRef.hasBeenVisible &&
        currPos.y < prevPos.y &&
        currPos.y <= window.innerHeight
      ) {
        setTriggerRef((r: any) => ({ ...r, hasBeenVisible: true }))
        onLoadMore()
      }
    },
    [triggerRef, onLoadMore],
    triggerRef,
  )

  return {
    setTriggerElement: callbackToSetTriggerRef,
  }
}

export default usePagination
