import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import React from 'react'

import Dialog from './elements/Dialog'

interface Props {
  open: boolean
  onDialogClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onButtonClose: React.MouseEventHandler<HTMLButtonElement>
}
const SizeChartDialog: React.FC<Props> = ({
  open,
  onDialogClose,
  onButtonClose,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      onClose={onDialogClose}
      title={'Size Chart'}
      content={
        <Grid
          item
          lg={12}
          sx={(theme) => ({
            height: '100vh',
            overflow: 'hidden',
            [theme.breakpoints.up('md')]: {
              width: 'calc(100vw/2)',
              height: 'calc(100vh/2)',
            },
          })}>
          <Box
            sx={(theme) => ({
              height: '100%',
              overflow: 'scroll',
              [theme.breakpoints.up('md')]: {
                overflow: 'unset',
              },
            })}>
            <CardMedia
              sx={(theme) => ({
                width: '1000px',
                height: '650px',
                backgroundSize: 'cover',
                [theme.breakpoints.up('md')]: {
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'contain',
                },
              })}
              image={`${process.env.PUBLIC_URL}/images/size_chart_v2.jpg`}
            />
          </Box>
        </Grid>
      }
      buttons={
        <>
          <Button variant="text" onClick={onButtonClose}>
            Close
          </Button>
        </>
      }
    />
  )
}
export default SizeChartDialog
