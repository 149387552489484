/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-named-as-default-member */
import { TextFieldProps } from '@mui/material/TextField'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Field, FieldProps } from 'formik'
import React from 'react'

import { formatDate } from '../../../constants/formats'
import { getProperty } from '../../../utils/ObjectUtil'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(customParseFormat)

interface FormDatePickerProps {
  // onModified?: React.ChangeEventHandler<HTMLInputElement>
  onModified?: (value: Dayjs) => void
  disableFuture?: boolean
  disablePast?: boolean
}

const FormDatePicker: React.FC<
  FormDatePickerProps & Partial<TextFieldProps> & Partial<FieldProps>
> = ({
  // onModified,
  field: { name, value }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  required,
  // autoComplete,
  disabled,
  placeholder,
  disableFuture = true,
  disablePast,
  // children
}) => {
  // const defaultValue = value || dayjs().subtract(20, 'year')

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        disableFuture={disableFuture}
        disablePast={disablePast}
        openTo="day"
        views={['year', 'month', 'day']}
        // value={defaultValue}
        value={value}
        label={label}
        timezone="Asia/Manila"
        format={'YYYY-MM-DD'}
        disabled={disabled}
        slotProps={{
          field: {
            fullWidth: true,
            required,
            placeholder,
            error: !!(getProperty(touched, name) && getProperty(errors, name)),
          } as any,
          textField: {
            variant: 'filled',
            helperText: getProperty(touched, name) && getProperty(errors, name),
          },
        }}
        onChange={(newValue: any) => {
          if (
            newValue !== undefined &&
            // dayjs(newValue as unknown as string).format('YYYY-MM-DD')
            formatDate(newValue, 'YYYY-MM-DD')
          ) {
            setFieldValue(name, newValue)
            setFieldTouched(name, true)
          }
        }}
      />
    </LocalizationProvider>
  )
}

interface Props {
  name: string
}
const FormikFieldWrapper: React.FC<
  Props &
    Partial<TextFieldProps> &
    Partial<FieldProps> &
    Partial<FormDatePickerProps>
> = ({ name, ...rest }) => {
  return <Field name={name} component={FormDatePicker} {...rest} />
}

export default FormikFieldWrapper
