/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextFieldProps } from '@mui/material/TextField'
import { Field, FieldProps } from 'formik'
import React from 'react'

import { getProperty } from '../../../utils/ObjectUtil'
import Input from '../../elements/Input'

export interface FormInputProps {
  onModified?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const FormInput: React.FC<
  FormInputProps & Partial<TextFieldProps> & Partial<FieldProps>
> = ({
  type,
  onModified,
  inputProps,
  field: { name, value, ...fieldRest }, // { name, value, onChange, onBlur }
  form: { touched, errors, handleChange, setFieldValue }, // also errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => {
  return (
    <Input
      type={type}
      name={name}
      value={value !== undefined ? value : ''}
      inputProps={{ ...inputProps, style: { textTransform: 'uppercase' } }}
      {...fieldRest}
      error={getProperty(touched, name) && getProperty(errors, name)}
      {...rest}
      onChange={(e) => {
        handleChange(e)
        setFieldValue(name, e.target.value.toUpperCase())
        if (typeof onModified === 'function') onModified(e)
      }}
    />
  )
}

interface Props {
  name: string
}
const FormikFieldWrapper: React.FC<Props & Partial<FieldProps>> = ({
  name,
  ...rest
}) => {
  return <Field name={name} component={FormInput} {...rest} />
}

export default FormikFieldWrapper
