/* eslint-disable import/no-anonymous-default-export */
/*
 * These hooks provide utilities for using breakpoints (as defined in src/constants/themes/mainTheme.js).
 * It wraps Material UI helper functions, see https://material-ui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
 *
 * useDown(breakpoint) returns true for any window that is the specified breakpoint or smaller.
 * Eg. the following matches the 'sm' (small) breakpoint and smaller
 * useMatchesBreakpoint.useDown('sm');
 *
 * useUp(breakpoint) returns true for any window that is the specified breakpoint or larger.
 * Eg. the following matches the 'sm' (small) breakpoint and larger
 * useMatchesBreakpoint.useUp('sm');
 */

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

function useDown(breakpoint) {
  const theme = useTheme()
  const matchesBreakpoint = useMediaQuery(theme.breakpoints.down(breakpoint), {
    noSsr: true,
  })

  return matchesBreakpoint
}

function useUp(breakpoint) {
  const theme = useTheme()
  const matchesBreakpoint = useMediaQuery(theme.breakpoints.up(breakpoint), {
    noSsr: true,
  })

  return matchesBreakpoint
}

export default {
  down: useDown,
  up: useUp,
}
