/* eslint-disable react/react-in-jsx-scope */
import { GENDERS } from '../../../constants/models'
import TextWithIcon from '../../elements/TextWithIcon'
import FormDatePicker from '../elements/FormDatePicker'
import FormSelectInput from '../elements/FormSelectInput'
import FormTextInput from '../elements/FormTextInput'

const IndividualUserFormContent = ({ values, formDisabled }) => [
  {
    id: 'participant-more-info-form-details',
    name: 'Participant Details',
    inputs: [
      {
        id: 'eventName',
        size: 6,
        field: <TextWithIcon title="Event" text={values.eventName} />,
      },
      {
        id: 'categoryName',
        size: 6,
        field: <TextWithIcon title="Category" text={values.categoryName} />,
      },
      {
        id: 'firstName',
        size: 6,
        field: <TextWithIcon title="First Name" text={values.firstName} />,
      },
      {
        id: 'lastName',
        size: 6,
        field: <TextWithIcon title="Last Name" text={values.lastName} />,
      },
      {
        id: 'birthdate',
        size: { sm: 12, md: 6 },
        field: (
          <FormDatePicker
            name="birthdate"
            label="Birthdate"
            required
            autoComplete="new-birthdate"
            placeholder="YYYY-MM-DD"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'gender',
        size: { sm: 12, md: 6 },
        field: (
          <FormSelectInput
            name="gender"
            label="Gender"
            autoComplete="new-gender"
            placeholder="Select Gender"
            disabled={formDisabled}
            options={Object.entries(GENDERS).map(([key, value]) => ({
              value: key,
              text: value,
            }))}
            required
          />
        ),
      },
      {
        id: 'address',
        size: 12,
        field: (
          <FormTextInput
            name="address"
            label="Home Address"
            required
            autoComplete="new-address"
            disabled={formDisabled}
          />
        ),
      },
    ],
  },
]
export default IndividualUserFormContent
