/* eslint-disable @typescript-eslint/no-explicit-any */
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { useState } from 'react'

interface Option {
  text: string
  onClick?: React.MouseEventHandler<HTMLLIElement>
}
interface Props {
  options: Option[]
}

const InlineMenu: React.FC<Props> = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: any) => {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }
    setAnchorEl(null)
  }

  const handleOption = (onClick) => (event) => {
    event.stopPropagation()
    event.preventDefault()
    onClick()
    handleClose(null)
  }

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem onClick={handleOption(option.onClick)} key={option.text}>
            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default InlineMenu
