import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import dayjs from 'dayjs'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import RegistrationForm from './forms/RegistrationForm'
import PageTitle from './Page/Title'
// import PaymongoCheckout from './PaymongoCheckout'
import PaymongoCheckout from './PaymongoCheckout'
import StepParticipant from './StepParticipant'
import { Event as EventApi } from '../API'
import { CartContext } from '../contexts/CartContext'
import { PageLoaderContext } from '../contexts/PageLoaderContext'

const STEP_USER = 0
const STEP_PARTICIPANT = 1
const STEP_CHECKOUT = 2

const steps = ['User details', 'Add Participants', 'Checkout']

type Event = Omit<Exclude<EventApi, null>, '__typename'>

interface Props {
  event: Event
  // categoryId provided for teamType categories
  categoryId?: string
}
const GroupRegistration: React.FC<Props> = ({ event, categoryId }) => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(STEP_USER)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const { startedAt, cartUser, addUser, clearCart } = useContext(CartContext)
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)

  const processStartedAt = useCallback(() => {
    clearCart()
  }, [clearCart])

  useEffect(() => {
    if (
      (startedAt === null && Object.keys(cartUser).length > 0) ||
      (startedAt !== null && dayjs().diff(dayjs(startedAt), 'm', true) >= 15)
    )
      processStartedAt()
  }, [startedAt, cartUser, processStartedAt])

  return (
    <>
      <PageTitle title={`Register to ${event?.name}`} />
      <div style={{ height: 48 }} />
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <div style={{ height: 32 }} />

      {/* STEP 1 */}
      <RegistrationForm
        show={activeStep === STEP_USER}
        user={cartUser}
        editing={!!cartUser}
        setLoading={setPageLoading}
        updateData={(user) => {
          addUser(user)
          setPageLoading(false)
          handleNext()
        }}
        onCancel={() => {
          clearCart()
          navigate(-1)
        }}
      />

      {/* STEP 2 */}
      <StepParticipant
        show={activeStep === STEP_PARTICIPANT}
        event={event}
        categoryId={categoryId}
        handleSecondaryAction={() => handleBack()}
        handlePrimaryAction={() => handleNext()}
      />

      {/* {activeStep === STEP_CHECKOUT && checkoutContent()} */}
      {/* STEP 3 */}
      <PaymongoCheckout
        show={activeStep === STEP_CHECKOUT}
        onCancel={handleBack}
      />
    </>
  )
}
export default GroupRegistration
