/* eslint-disable import/no-named-as-default-member */
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'

import { Category as CategoryApi } from '../API'
import { formatCurrency } from '../constants/formats'
import { TEAM_TYPES } from '../constants/models'
import { getItemFee, isCategorySoldOut } from '../utils/ApiHelpers'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

type Category = Omit<Exclude<CategoryApi, null>, '__typename'>
interface Props {
  eventId?: string
  isOnsiteOnly: boolean
  isFree: boolean
  slug: string
  categories: Category[]
  registrationCutOff?: string
  registrationStartDateTime?: string
}

const EventDetailsCategories: React.FC<Props> = ({
  // eventId,
  isOnsiteOnly,
  isFree,
  slug,
  categories,
  registrationCutOff,
  registrationStartDateTime,
}) => {
  return (
    <Grid container item spacing={2} id="categories">
      {/* Categories */}
      <div style={{ height: 16 }} />
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Typography
          variant="h5"
          sx={(theme) => ({ marginTop: theme.spacing(6) })}>
          Select Category
        </Typography>
      </Grid>
      {categories && (
        <Grid
          item
          xs={12}
          container
          spacing={4}
          justifyContent="center"
          alignItems="flex-start">
          {categories
            .sort((a: Category, b: Category) => {
              if (!!a.priority && !b.priority) return -1
              if (!!b.priority && !a.priority) return 1
              return a.priority - b.priority
            })
            .map((category) => {
              const soldOut = isCategorySoldOut(
                category.categoryCount?.count,
                category.maxParticipants,
              )

              const { fee } = getItemFee(category)
              return (
                <Grid xs={12} sm={6} md={3} item key={`category${category.id}`}>
                  <Paper>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center">
                      <Grid
                        item
                        xs={12}
                        sx={(theme) => ({
                          padding: theme.spacing(2),
                          backgroundColor: theme.palette.grey[100],
                        })}>
                        <Typography variant="body1">{category.name}</Typography>
                        <Typography variant="caption">
                          {category.description}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={(theme) => ({
                          padding: theme.spacing(2),
                        })}>
                        <Typography variant="h5">{`${
                          isFree ? 'FREE' : formatCurrency(fee)
                        }`}</Typography>
                        {category.earlyBirdUntil &&
                          category.earlyBirdFee &&
                          dayjs().isSameOrBefore(
                            dayjs(category.earlyBirdUntil),
                          ) && (
                            <Typography
                              variant="caption"
                              sx={(theme) => ({
                                color: theme.palette.primary.dark,
                                textDecoration: 'line-through',
                              })}>{`${formatCurrency(
                              category.fee,
                            )}`}</Typography>
                          )}
                      </Grid>
                    </Grid>
                    {(registrationStartDateTime
                      ? dayjs(registrationStartDateTime).isSameOrBefore(
                          dayjs(),
                        ) && dayjs(registrationCutOff).isSameOrAfter(dayjs())
                      : dayjs(registrationCutOff).isSameOrAfter(dayjs())) && (
                      <Grid
                        item
                        container
                        xs={12}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={(theme) => ({
                          padding: theme.spacing(2),
                        })}>
                        {!soldOut ? (
                          <Link
                            {...(!isOnsiteOnly && {
                              href: `/register/${slug}${
                                category.isIndividual ||
                                [
                                  TEAM_TYPES.DOUBLES,
                                  TEAM_TYPES.DUATHLON,
                                ].includes(category.teamType)
                                  ? `/${category.id}`
                                  : ''
                              }`,
                            })}
                            sx={{ width: '100%' }}
                            underline="none">
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ color: 'white', width: '100%' }}
                              disabled={isOnsiteOnly}>
                              {`Register${isOnsiteOnly ? ' Onsite' : ''}`}
                            </Button>
                          </Link>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            disabled
                            sx={{ width: '100%' }}>
                            Sold out
                          </Button>
                        )}
                      </Grid>
                    )}
                  </Paper>
                </Grid>
              )
            })}
        </Grid>
      )}
    </Grid>
  )
}
export default EventDetailsCategories
