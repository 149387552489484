import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import React from 'react'

const LoadingSpinner = () => {
  const theme = useTheme()

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress
        style={{
          margin: theme.spacing(2),
        }}
      />
    </div>
  )
}

export default LoadingSpinner
