import { AttachPaymentIntentRequest } from '../models/Paymongo/PaymentIntent'
import { CreatePaymentMethodRequest } from '../models/Paymongo/PaymentMethod'

export const generateOptions = (
  payload: AttachPaymentIntentRequest | CreatePaymentMethodRequest,
) => {
  const base64 = btoa(`${process.env.REACT_APP_PAYMONGO_PK}:`)

  return {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Basic ${base64}`,
    },
    body: JSON.stringify({
      data: {
        attributes: payload,
      },
    }),
  }
}
