import Stack from '@mui/material/Stack'
import React from 'react'

import PageTitle from '../components/Page/Title'
import Section from '../components/Section'

const Waiver: React.FC = () => {
  return (
    <Section>
      <PageTitle title="Waiver" />
      <Stack>
        <ul>
          <li>
            Entry fees are non-refundable for non-starters and non-finishers.
          </li>
          <li>
            I acknowledge that running and walking involves the real risk of
            injury or death from various causes, which may include but are not
            limited to: over-exertion; dehydration; heart problems;
            disorientation; accidents with spectators, volunteers or road users;
            equipment failure; unforeseen events; the course; or the weather
            conditions.
          </li>
          <li>
            I understand that I should not join in an event unless I have
            trained appropriately and a medical practitioner has verified my
            physical condition.{' '}
          </li>
          <li>
            By joining, I accept all risks necessarily flowing from my
            participation that could result in injury or death. Accordingly, I
            release all people associated with the conduct of the event from,
            and will indemnify them against, all liability (including liability
            for their negligence) for any and all injury, loss or damage arising
            out of or connected with my participation in this event. For
            clarification, the people released include event organisers,
            sponsors, managers, government and public authorities, contractors,
            volunteers, and event medical and paramedical personnel. This
            release and indemnity is perpetual and binds my heirs, executors,
            personal representatives, and assigns.{' '}
          </li>
          <li>
            I understand that the event organiser does not provide health
            insurance for me, and I will bear all financial responsibility for
            all and any medical treatment arising from my participation in this
            event; including but not limited to ambulance, doctor, and hospital
            fees. I understand that I should have my own personal accident and
            ambulance cover.{' '}
          </li>
          <li>
            I am fully responsible for the security of my personal possessions
            at the event.{' '}
          </li>
          <li>
            I agree to abide by all race rules and directions issued by the
            event organiser.{' '}
          </li>
          <li>
            By entering, you consent to your personal information being provided
            to MEGATechPH for the purpose of registering. You further consent to
            receiving information (including via email) about the MEGATechPH
            program including details of other events and special offers or
            promotions.{' '}
          </li>
          <li>
            Entry information is to be collected into a database which is and
            shall remain the property of MEGATechPH{' '}
          </li>
          <li>
            The database will be used in the management of the race and for the
            purpose of the MEGATechPH{' '}
          </li>
          <li>The database will be managed by the MEGATechPH </li>
          <li>
            I acknowledge that the information given on this form or otherwise
            provided by me (or my parent/s or guardian/s) to MEGATechPH is true
            and correct{' '}
          </li>
          <li>
            I acknowledge that photos may be taken throughout the MEGATechPH and
            consent to the use of my image in any form of media without payment
            or compensation.{' '}
          </li>
          <li>
            The organisers reserve the right to delay, alter, or cancel any race
            in the event of dangerous weather conditions. No refunds will be
            provided in the event of cancellation due to weather conditions.{' '}
          </li>
          <li>
            The organisers cannot be held responsible for lost or stolen
            valuables.{' '}
          </li>
          <li>
            The organisers reserve the right to make additions, changes, or
            clarifications of these terms and conditions without prior notice to
            participants. The organisers will endeavour to advise participants
            of any rule change prior to the race, but any such change shall be
            considered effective for all participants whether or not the
            participant has received notice of the change prior to the race.
          </li>
        </ul>
      </Stack>
    </Section>
  )
}

export default Waiver
