/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlProps } from '@mui/material/FormControl'
import { SelectChangeEvent, SelectProps } from '@mui/material/Select'
import { Field, FieldProps } from 'formik'
import React from 'react'

import { getProperty } from '../../../utils/ObjectUtil'
import SelectInput from '../../elements/SelectInput'

interface Option {
  text: string
  value: string
}
interface FormSelectInputProps {
  onModified: React.FormEventHandler<HTMLDivElement> &
    ((event: SelectChangeEvent<unknown>, child: React.ReactNode) => void)
}

const FormSelectInput: React.FC<
  FormSelectInputProps &
    Partial<FormControlProps> &
    Partial<SelectProps> &
    Partial<FieldProps>
> = ({
  onModified,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, handleChange }, // also errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => (
  <SelectInput
    error={getProperty(touched, field.name) && getProperty(errors, field.name)}
    {...field}
    {...rest}
    onChange={(e) => {
      handleChange(e)
      if (typeof onModified === 'function') onModified(e)
    }}
  />
)

interface Props {
  name: string
  options: Option[]
}
const FormikFieldWrapper: React.FC<
  Props &
    Partial<SelectProps> &
    Partial<FieldProps> &
    Partial<FormSelectInputProps>
> = ({ name, ...rest }) => {
  return <Field name={name} component={FormSelectInput} {...rest} />
}

export default FormikFieldWrapper
