// import { useTheme } from '@mui/material/styles'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import React from 'react'
// import { FieldProps } from 'formik'

// interface Props {
//   type: string
//   label: string
//   error?: string
// }

// const Input: React.FC<Props & TextFieldProps & FieldProps> = ({
const Input: React.FC<Partial<TextFieldProps>> = ({
  label,
  type,
  error,
  ...rest
}) => {
  // const theme = useTheme()

  return (
    <TextField
      variant="filled"
      label={label}
      type={type}
      error={!!error}
      helperText={error || ''}
      fullWidth
      // sx={[
      //   {
      //     '& > div': {
      //       borderTopLeftRadius: 8,
      //       borderTopRightRadius: 8,
      //     },
      //     '& > div::before, & > div:hover::before': {
      //       borderBottom: 0,
      //     },
      //     '& > div::after': {
      //       borderBottomColor: theme.palette.primary.main,
      //     },
      //     '& > div > input, & > div > textarea': {
      //       color: theme.palette.text.secondary,
      //     },
      //     '&:focus-within > label': {
      //       color: theme.palette.primary.main,
      //     },
      //   },
      // ]}
      {...rest}
    />
  )
}
export default Input
