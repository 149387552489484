import dayjs from 'dayjs'

import { TEAM_TYPES } from '../constants/models'
import { isCategoryMultisports } from '../utils/ApiHelpers'

const StorageUser = (cartUser) => {
  localStorage.setItem('vse-cartUser', JSON.stringify(cartUser || {}))
}

const setUser = (cartUser) => {
  StorageUser(cartUser)
  return { cartUser }
}

const StorageItems = (cartItems) => {
  localStorage.setItem(
    'vse-cartItems',
    JSON.stringify(cartItems.length > 0 ? cartItems : []),
  )
}

export const sumItems = (cartItems) => {
  StorageItems(cartItems)

  let itemCount = 0
  let total = '0.00'

  const catMultisports = cartItems.filter((cartItem) =>
    isCategoryMultisports(cartItem),
  )
  const catTeam = cartItems.filter((cartItem) =>
    // Object.keys(TEAM_TYPES).includes(cartItem.teamType),
    [TEAM_TYPES.DOUBLES].includes(cartItem.teamType),
  )
  if (catMultisports.length > 0) {
    itemCount = cartItems.reduce(
      (total, product) => total + product.relayEvent.length,
      0,
    )
    total = (catMultisports[0].fee + catMultisports[0].adminFee).toFixed(2)
  } else if (catTeam.length > 0) {
    itemCount = cartItems.reduce(
      (total, product) => total + product.quantity,
      0,
    )

    total = (catTeam[0].fee + catTeam[0].adminFee).toFixed(2)
  } else {
    itemCount = cartItems.reduce(
      (total, product) => total + product.quantity,
      0,
    )
    total = cartItems
      .reduce(
        (total, product) =>
          total + (product.fee + product.adminFee) * product.quantity,
        0,
      )
      .toFixed(2)
  }

  return { itemCount, total }
}

const StorageFPUser = (cartUser) => {
  localStorage.setItem('vse-fp-cartUser', JSON.stringify(cartUser || {}))
}

const setFPUser = (cartUser) => {
  StorageFPUser(cartUser)
  return { cartUser }
}

export const CartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_USER':
      return {
        ...state,
        ...setUser(action.payload),
        startedAt: dayjs(),
      }
    case 'UPDATE_USER':
      return {
        ...state,
        ...setUser(action.payload),
      }
    case 'REMOVE_USER':
      return {
        ...state,
        ...setUser(action.payload),
      }
    case 'ADD_FP_USER':
      return {
        ...state,
        ...setFPUser(action.payload),
      }
    case 'UPDATE_FP_USER':
      return {
        ...state,
        ...setFPUser(action.payload),
      }
    case 'REMOVE_FP_USER':
      return {
        ...state,
        ...setFPUser(action.payload),
      }

    case 'ADD_ITEM':
      if (
        action.payload.id &&
        !state.cartItems.find((item) => item.id === action.payload.id)
      ) {
        state.cartItems.push({
          ...action.payload,
          quantity: 1,
        })
      }

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      }
    case 'REMOVE_ITEM':
      return {
        ...state,
        ...sumItems(
          state.cartItems.filter((item) => item.id !== action.payload.id),
        ),
        cartItems: [
          ...state.cartItems.filter((item) => item.id !== action.payload.id),
        ],
      }
    case 'UPDATE_ITEM':
      if (action.payload.id) {
        const currentItem = state.cartItems.find(
          (item) => item.id === action.payload.id,
        )

        if (currentItem) {
          const currentItemIndex = state.cartItems.findIndex(
            (item) => item.id === action.payload.id,
          )

          state.cartItems[currentItemIndex] = action.payload
        }
      }

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      }

    case 'UPDATE_ITEM_FEE':
      if (
        action.payload.id &&
        state.cartItems.find((item) => item.id === action.payload.id)
      ) {
        const currentItemIndex = state.cartItems.findIndex(
          (item) => item.id === action.payload.id,
        )
        state.cartItems[currentItemIndex].fee = action.payload.fee
        state.cartItems[currentItemIndex].adminFee = action.payload.adminFee
      }
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      }
    case 'INCREASE':
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].quantity++
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      }
    case 'DECREASE':
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].quantity--
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      }
    case 'CHECKOUT':
      return {
        cartItems: [],
        cartUser: {},
        cartFPUser: {},
        checkout: true,
        ...sumItems([]),
        ...setUser({}),
        ...setFPUser({}),
      }
    case 'CLEAR':
      return {
        cartItems: [],
        cartUser: {},
        cartFPUser: {},
        ...sumItems([]),
        ...setUser({}),
        ...setFPUser({}),
        startedAt: null,
      }
    default:
      return state
  }
}
