/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { SortDirection } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'

import InlineMenu from './InlineMenu'
import LoadingSpinner from './LoadingSpinner'
import useMatchesBreakpoint from '../../hooks/useMatchesBreakpoint'
import usePagination from '../../hooks/usePagination'
import { getProperty } from '../../utils/ObjectUtil'

const ROW_BUFFER_BEFORE_RELOAD = 20

interface HeaderFormatParserProps {
  to?: string
  toPrefix?: string
  text: string
  includeRow?: boolean
  ariaLabel?: string
}
interface HeaderFormatProps {
  type: string // oneOf(["raw", "link", "menu", "deleteIcon", "editIcon"])
  parser?: (value: any) => any | HeaderFormatParserProps
  onClick?: (value: any) => any
  ariaLabel?: string
}
export interface HeaderProps {
  key: string
  name: string
  format?: HeaderFormatProps
  hideOnMobile?: boolean
  disableSort?: boolean
}
interface Props {
  headers: HeaderProps[]
  rows: any[]
  namedRows?: boolean
  sorting?: boolean
  defaultSortColumn?: string
  defaultSortOrder?: SortDirection
  idKey?: string
  customClasses?: any
  onRowClick?: (value: any, cb: any) => any
  // // Found a simpler way to highlight a row. Keeping this for reference. This
  // // might come in handy for `allowSelectMultiple`
  // selectableRows,
  // allowSelectMultiple,
  highlightRow?: string
  onLoadMore?: (value: any) => any
  loading?: boolean
  stickyHeaders?: boolean
}

/*
 * Sorts two elements based on locale
 */
const sortDesc = (a, b, orderBy) =>
  `${getProperty(a, orderBy)}`.localeCompare(
    `${getProperty(b, orderBy)}`,
    // navigator.language || navigator.userLanguage,
    navigator.language,
    {
      sensitivity: 'base',
      numeric: true,
    },
  )

/*
 * Sorts an array and keeps previous sorting when two items are equal for current sort
 */
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

/*
 * Get sorting based on set sort order (desc/asc)
 */
const getSorting = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => sortDesc(a, b, orderBy)
    : (a, b) => -sortDesc(a, b, orderBy)

/*
 * Parsing for special data types, such as dates, links or options menus
 */
const parseTableValue = (value, format, row) => {
  if (!format) return value
  switch (format.type) {
    case 'raw':
      return format.parser(value)
    case 'link':
      return (
        <Link
          to={
            format.parser.includeState
              ? {
                  pathname:
                    format.parser.to || `${format.parser.toPrefix}${value}`,
                  state: row,
                }
              : format.parser.to || `${format.parser.toPrefix}${value}`
          }
          aria-label={format.parser.ariaLabel}
        >
          {format.parser.text}
        </Link>
      )
    case 'menu':
      return (
        <InlineMenu
          options={format.options.map((option) => ({
            text: option.text,
            onClick: () => option.onClick(row),
          }))}
        />
      )
    case 'deleteIcon':
      return (
        <IconButton
          edge="start"
          color="inherit"
          aria-label={format.ariaLabel}
          onClick={() => format.onClick(row)}
        >
          <RemoveCircleIcon />
        </IconButton>
        // <InlineMenu
        //   options={format.options.map((option) => ({
        //     text: option.text,
        //     onClick: () => option.onClick(row),
        //   }))}
        // />
      )
    case 'editIcon':
      return (
        <IconButton
          edge="start"
          color="inherit"
          aria-label={format.ariaLabel}
          onClick={() => format.onClick(row)}
        >
          <EditIcon />
        </IconButton>
        // <InlineMenu
        //   options={format.options.map((option) => ({
        //     text: option.text,
        //     onClick: () => option.onClick(row),
        //   }))}
        // />
      )
    default:
      return value
  }
}

const filterHeaders = (headers, matchesMobileScreen) =>
  headers.filter((header) => !header.hideOnMobile || !matchesMobileScreen)

const CustomTable: React.FC<Props> = ({
  headers,
  rows,
  namedRows,
  sorting,
  defaultSortColumn,
  defaultSortOrder,
  idKey,
  customClasses,
  onRowClick,
  // // Found a simpler way to highlight a row. Keeping this for reference. This
  // // might come in handy for `allowSelectMultiple`
  // selectableRows,
  // allowSelectMultiple,
  highlightRow,
  onLoadMore,
  loading,
  stickyHeaders,
}) => {
  const theme = useTheme()
  const [order, setOrder] = useState<SortDirection>(defaultSortOrder || 'desc')
  const [orderBy, setOrderBy] = useState(
    defaultSortColumn || (headers.length > 0 ? headers[0].key : ''),
  )

  // We will save the sort values per column
  const [columnSorts, setColumnSorts] = useState({})

  // // Found a simpler way to highlight a row. Keeping this for reference. This
  // // might come in handy for `allowSelectMultiple`
  // const [selected, setSelected] = React.useState([]);

  const matchesMobileScreen = useMatchesBreakpoint.down('sm')

  const { setTriggerElement } = usePagination(onLoadMore)

  function handleRequestSort(property, currentSort) {
    let isDesc = true
    if (orderBy === property) {
      isDesc = order === 'desc'
    } else if (property in columnSorts) {
      isDesc = columnSorts[property] === 'desc'
    } else isDesc = currentSort === 'desc'

    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)

    setColumnSorts(
      Object.assign({}, columnSorts, { [property]: isDesc ? 'asc' : 'desc' }),
    )
  }

  function handleClick(event, row) {
    onRowClick(row, event)
  }

  const displayedHeaders = filterHeaders(headers, matchesMobileScreen)

  const cssCell = {
    padding: theme.spacing(1.5, 3),
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  }
  const cssHeaderCell = {
    backgroundColor: theme.palette.grey[100],
    whiteSpace: 'nowrap',
    zIndex: 1,
    height: 56,
    fontWeight: 'bold',
    color: '#494949',
    fontSize: 14,
    boxShadow: 'inset 0 -2px 4px -4px rgba(0,0,0,0.6)',
  }
  const cssCollapsedCell = { width: '1%' }
  const cssStickyHeaders = {
    position: 'sticky',
    top: 86,
  }
  const cssStickySidePadding = {
    '&:first-child': {
      paddingLeft: 80,
    },
    '&:last-child': {
      paddingRight: 80,
    },
  }
  const cssMobileStickyHeaders = {
    top: 55,
  }
  const cssClickableRow = {
    cursor: 'pointer',
  }
  const cssInactiveRow = {
    opacity: 0.4,
  }

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        overflowX: 'auto',
      }}
    >
      <Table
        sx={[
          {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.16)',
            ...customClasses,
          },
        ]}
      >
        <TableHead>
          <TableRow
            key="tableheadrow"
            sx={[
              {
                '&$selected': {
                  backgroundColor: 'rgba(226,239,244,0.55)',
                },
                '&$hover:hover': {
                  backgroundColor: 'rgba(226,239,244,0.55)',
                },
              },
            ]}
          >
            {displayedHeaders.map((header) => {
              return sorting && header.name && !header.disableSort ? (
                <TableCell
                  key={`${header.key}-${header.name}`}
                  id={`table-header-${header.key}`}
                  sx={[
                    { ...cssCell },
                    { ...cssHeaderCell },
                    {
                      ...(header.collapsed && { ...cssCollapsedCell }),
                    },
                    {
                      ...(stickyHeaders && {
                        ...cssStickyHeaders,
                      }),
                    },
                    {
                      ...(header.stickySidePadding &&
                        !matchesMobileScreen && {
                          ...cssStickySidePadding,
                        }),
                    },
                    {
                      ...(stickyHeaders &&
                        matchesMobileScreen && {
                          ...cssMobileStickyHeaders,
                        }),
                    },
                  ]}
                  sortDirection={orderBy === header.key ? order : false}
                >
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active
                    // direction={orderBy === header.key ? order : 'desc'}
                    direction={
                      header.key in columnSorts
                        ? columnSorts[header.key]
                        : 'desc'
                    }
                    onClick={() =>
                      handleRequestSort(
                        header.key,
                        header.key in columnSorts
                          ? columnSorts[header.key]
                          : 'desc',
                      )
                    }
                    aria-label={`${header.name}${
                      sorting && orderBy === header.key
                        ? `, sorted ${
                            order === 'desc' ? 'descending' : 'ascending'
                          }`
                        : ''
                    }${sorting ? ', click to change sort direction' : ''}`}
                  >
                    {header.name}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell
                  key={`${header.key}-${header.name}`}
                  id={`table-header-${header.key}`}
                  sx={[
                    { ...cssCell },
                    { ...cssHeaderCell },
                    {
                      ...(header.collapsed && { ...cssCollapsedCell }),
                    },
                    {
                      ...(stickyHeaders && {
                        ...cssStickyHeaders,
                      }),
                    },
                    {
                      ...(header.stickySidePadding &&
                        !matchesMobileScreen && {
                          ...cssStickySidePadding,
                        }),
                    },
                    {
                      ...(stickyHeaders &&
                        matchesMobileScreen && {
                          ...cssMobileStickyHeaders,
                        }),
                    },
                  ]}
                >
                  {header.name}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {(sorting ? stableSort(rows, getSorting(order, orderBy)) : rows).map(
            (row, rowIndex) => (
              <TableRow
                hover
                // key={row[idKey]}
                key={`table-body-row-${rowIndex}`}
                // // Found a simpler way to highlight a row. Keeping this for reference. This
                // // might come in handy for `allowSelectMultiple`
                // className={clsx(classes.tableRow, {
                //   [classes.highlighted]: highlightRow === row[idKey],
                // })}
                // selected={isSelected(row[idKey])}
                selected={highlightRow === row[idKey]}
                ref={
                  rowIndex ===
                  rows.length -
                    (rows.length > ROW_BUFFER_BEFORE_RELOAD
                      ? ROW_BUFFER_BEFORE_RELOAD
                      : 1)
                    ? setTriggerElement(rows.length)
                    : null
                }
              >
                {displayedHeaders.map((header, index) => (
                  <TableCell
                    component={namedRows && index === 0 ? 'th' : 'td'}
                    {...(namedRows && index === 0 && { scope: 'row' })}
                    key={`${header.key}-${header.name}-${row[idKey]}`}
                    id={`table-cell-${header.key}-${row[idKey]}`}
                    sx={[
                      { ...cssCell },
                      { ...(!!onRowClick && { ...cssClickableRow }) },
                      {
                        ...(header.collapsed && {
                          ...cssCollapsedCell,
                        }),
                      },
                      {
                        ...(header.stickySidePadding &&
                          !matchesMobileScreen && {
                            ...cssStickySidePadding,
                          }),
                      },
                      {
                        ...(row.inactiveRow && { ...cssInactiveRow }),
                      },
                    ]}
                    onClick={
                      onRowClick ? (event) => handleClick(event, row) : () => {}
                    }
                  >
                    {parseTableValue(
                      getProperty(row, header.key),
                      header.format,
                      row,
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
      {loading && (
        <div
          style={{
            position: 'absolute',
            bottom: 20,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}
export default memo(CustomTable)
