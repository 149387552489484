import { Link } from '@aws-amplify/ui-react'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import CardMedia from '@mui/material/CardMedia'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Storage } from 'aws-amplify'
import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { Category as CategoryApi, Entitlement as EntitlementApi } from '../API'
import ComingSoon from '../components/ComingSoon'
import Dialog from '../components/elements/Dialog'
import EmptyState from '../components/EmptyState'
import EventDetailsCategories from '../components/EventDetailsCategories'
import EventDetailsEntitlements from '../components/EventDetailsEntitlements'
import EventDetailsInfo from '../components/EventDetailsInfo'
import Section from '../components/Section'
import { ACCESS_TYPES, COMING_SOON } from '../constants/models'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import useEventDetails from '../hooks/useEventDetails'

type Category = Omit<Exclude<CategoryApi, null>, '__typename'>
type Entitlement = Omit<Exclude<EntitlementApi, null>, '__typename'>

const BreadcrumbLink = styled(MuiLink)(
  ({ theme }) => `
  z-index: 1;
  position: relative;
  text-transform: uppercase; 
  font-weight: bold;
  color: ${theme.palette.primary.main};
  :hover {
    color: ${theme.palette.secondary.main};
  }
`,
)

const EventDetails: React.FC = () => {
  const theme = useTheme()
  const { slug } = useParams()
  const {
    event,
    apiLoading: loading,
    apiError,
  } = useEventDetails({
    slug,
  })
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)

  const [showDialog, setShowDialog] = useState(false)
  const [locationImagePresignedUrl, setLocationImagePresignedUrl] =
    useState<string>()

  useEffect(() => {
    const fetch = async () => {
      if (
        !!event.locationImage &&
        event.locationImage.key &&
        event.locationImage.contentType
      ) {
        const locationImage = await Storage.get(event.locationImage.key)
        setLocationImagePresignedUrl(locationImage)
      }
    }

    if (event?.locationImage?.key) fetch()
  }, [event])

  useEffect(() => {
    let didCancel = false

    if (didCancel) return

    setPageLoading(loading)
    return () => {
      didCancel = true
    }
  }, [loading, setPageLoading])

  const styleSection = {
    marginBottom: theme.spacing(10),
  }

  return (
    <Section>
      <Container disableGutters sx={{ ...(event && { ...styleSection }) }}>
        {apiError && (
          <EmptyState header={apiError.title} text={apiError.message}>
            <Link href="/events">
              <Button variant="contained" color="secondary">
                BACK TO EVENTS
              </Button>
            </Link>
          </EmptyState>
        )}

        {!loading && !event && (
          <EmptyState
            header="Hmm… We cannot find the event you are looking for"
            text=""
            // text="Click the button in the top right corner to add your first merchant"
          />
        )}

        {!loading && event && (
          <>
            <Breadcrumbs
              separator=">"
              aria-label="breadcrumb"
              sx={{ paddingBottom: 3 }}>
              <BreadcrumbLink underline="none" color="inherit" href="/">
                Home
              </BreadcrumbLink>
              <BreadcrumbLink underline="none" color="inherit" href="/events">
                Events
              </BreadcrumbLink>
              <Typography color="text.primary">{event.name}</Typography>
            </Breadcrumbs>
            {event.isComingSoon === COMING_SOON ? (
              <ComingSoon event={event} />
            ) : (
              <>
                <Helmet>
                  <meta
                    property="og:title"
                    content={`MEGATechPH - ${event.name}`}
                  />
                  <meta
                    property="og:description"
                    content={`${
                      event.description ||
                      'MEGATechPH supports athletes, racers and events in creating their ultimate sports experience.'
                    }`}
                  />
                </Helmet>
                <EventDetailsInfo
                  event={event}
                  handleLocationClick={setShowDialog}
                />
                {!!event?.entitlements?.items.length && (
                  <EventDetailsEntitlements
                    entitlements={event?.entitlements?.items as Entitlement[]}
                    style={{
                      ...styleSection,
                    }}
                  />
                )}
                {!!event?.categories?.items.length &&
                  [ACCESS_TYPES.PUBLIC].includes(event.accessType) && (
                    <EventDetailsCategories
                      categories={event?.categories?.items as Category[]}
                      isOnsiteOnly={
                        'isOnsiteOnly' in event && event.isOnsiteOnly === 1
                      }
                      isFree={'isFree' in event && event.isFree === 1}
                      eventId={event.id}
                      slug={event.slug}
                      registrationCutOff={event.registrationCutOff || ''}
                      registrationStartDateTime={
                        event.registrationStartDateTime || ''
                      }
                    />
                  )}
                {event.locationImage && (
                  <Dialog
                    open={showDialog}
                    maxWidth={'lg'}
                    onClose={() => {
                      setShowDialog(false)
                    }}
                    content={
                      <Grid
                        item
                        lg={12}
                        sx={(theme) => ({
                          overflow: 'scroll',
                          [theme.breakpoints.up('md')]: {
                            overflow: 'unset',
                          },
                        })}>
                        <Box>
                          <CardMedia
                            sx={() => ({
                              width: '1000px',
                              height: '1000px',
                              backgroundSize: 'contain',
                            })}
                            image={
                              locationImagePresignedUrl
                                ? `${locationImagePresignedUrl}`
                                : `${process.env.PUBLIC_URL}/images/megatechph_icon.png`
                            }
                          />
                        </Box>
                      </Grid>
                    }
                    buttons={
                      <>
                        <Button
                          variant="text"
                          onClick={() => {
                            setShowDialog(false)
                          }}>
                          Close
                        </Button>
                      </>
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </Container>
    </Section>
  )
}

export default EventDetails
