/* eslint-disable @typescript-eslint/no-explicit-any */
// import logo from './logo.svg'
import './App.css'

import UpdateIcon from '@mui/icons-material/Update'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
// import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Provider as RollbarProvider } from '@rollbar/react'
import { SnackbarProvider } from 'notistack'
import React, { useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { IdleTimerProvider } from 'react-idle-timer'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import Dialog from './components/elements/Dialog'
import PageFooter from './components/Page/Footer'
import PageHeader from './components/Page/Header'
import CartContextProvider from './contexts/CartContext'
import { PageLoaderContextProvider } from './contexts/PageLoaderContext'

interface ReloadProps {
  open: boolean
  onClose: (event: any) => void
}

const IconContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.info.main,
  textAlign: 'center',
  marginBottom: theme.spacing(3),
  '& svg': {
    fontSize: '80px',
    display: 'block',
    margin: 'auto',
  },
  '& h4': {
    color: theme.palette.warning.main,
    marginLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
}))

const ReloadDialog: React.FC<ReloadProps> = ({ open, onClose }) => (
  <Dialog
    open={open}
    maxWidth={'lg'}
    onClose={onClose}
    content={
      <>
        <IconContainer>
          <UpdateIcon />
          <Typography variant="h4">You&apos;re session has expired</Typography>
        </IconContainer>
        <Typography variant="body1">
          Because of inactivity, your session has timed out and is no longer
          active. Please refresh the page.
        </Typography>
      </>
    }
    buttons={
      <Button variant="text" onClick={onClose}>
        Refresh
      </Button>
    }
  />
)

const App: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = useState<boolean>(false)
  const onIdle = () => {
    if (location.pathname !== '/' && !location.pathname.startsWith('/admin'))
      setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
    navigate('/', { replace: true })
  }

  return (
    <HelmetProvider>
      <Box
        sx={{
          display: 'flex',
        }}>
        <IdleTimerProvider timeout={15 * 60 * 1000} onIdle={onIdle}>
          <RollbarProvider
            config={{
              accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
              environment: process.env.REACT_APP_ROLLBAR_ENV,
            }}>
            <CartContextProvider>
              <PageHeader />
              <PageLoaderContextProvider>
                <Box
                  component="main"
                  sx={{ py: 3, minHeight: 'calc(100vh - 151px)' }}>
                  <Toolbar sx={{ paddingTop: '20px' }} />
                  <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                    <Outlet />
                  </SnackbarProvider>
                </Box>
              </PageLoaderContextProvider>
            </CartContextProvider>
          </RollbarProvider>
          <ReloadDialog open={open} onClose={onClose} />
        </IdleTimerProvider>
      </Box>
      <PageFooter />
    </HelmetProvider>
  )
}

export default App
