import { LinkProps } from '@mui/material/Link'
import { createTheme } from '@mui/material/styles'
import React, { forwardRef } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import type {} from '@mui/x-date-pickers/themeAugmentation'

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
})

LinkBehavior.displayName = 'LinkBehavior'

const theme = {
  palette: {
    primary: {
      main: '#FFA300',
    },
    secondary: {
      main: '#335A9A',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    // MuiDatePicker: {
    //   styleOverrides: {
    //     root: { backgroundColor: 'red' },
    //   },
    // },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
  },
  container: {
    padding: 10,
    smPadding: 5,
    width: '1024px',
  },
}

export default createTheme(theme)
